import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { Dropdown, Image, Stack } from "react-bootstrap";
import {
  GetIdiomaSelecionado,
  SetIdiomaSelecionado,
} from "../../config/localstorageConfig/localstorage";
import T from "../../common/traducao";
import Brasil from "../../assets/brasil.png";
import Espanha from "../../assets/espanha.png";
import LanguageSelector from "../language_selector_comp";
import UserSelector from "../ui_comp/Dropdowns/Dropdown.Seletor.Usuario";
import { FaUser, FaUserShield, FaSignOutAlt } from "react-icons/fa";
import {
  GetConfiguracoes,
  getUserLogin,
  getAcessoSimulado,
  isAuthenticated,
} from "../../config/localstorageConfig/localstorage";
import { Icone } from "../ui_comp/Icons/Icon";
import * as Styled from "./styles";
import { H2jGlobalContext } from "../../contexts/H2jGlobal-context";

export const Navigation = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAdmin, setAnchorElAdmin] = useState(null);
  const configuracoes = GetConfiguracoes();
  const open = Boolean(anchorEl);
  const openAdmin = Boolean(anchorElAdmin);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const constantes = T();
  const handleClickAdmin = (event) => {
    setAnchorElAdmin(event.currentTarget);
  };

  const { isAuth, userLogin, idiomaAtual, } = useContext(H2jGlobalContext);

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElAdmin(null);
  };

  const vendedorSimulado = getAcessoSimulado();
  const [usarAssistencia, setUsarAssistencia] = useState(process.env.REACT_APP_USAR_ASSISTENCIA === "true");
  const [usarCliente, setUsarCliente] = useState(process.env.REACT_APP_USAR_CLIENTE === "true");

  
  return userLogin ? (
    <>
      
      <Box display="flex" alignItems="center" justifyContent="center" gap={6}>
      {usarCliente &&
        <Box
          display="flex"
          alignItems="center"
          component={Button}
          href="/clientes"
          gap={1}
          textTransform="inherit"
        >
          <Icone icone="people_alt" sx={{ color: "#009180" }} />
          <Typography
            variant="subtitle1"
            sx={{ color: "#009180", textTransform: "none" }}
          >
            {constantes.Clientes}
          </Typography>
        </Box>
        }

        <Box
          component={Button}
          onClick={handleClick}
          display="flex"
          alignItems="center"
          gap={1}
          textTransform="inherit"
        >
          <Icone icone="shopping_cart_outlined" sx={{ color: "#009180" }} />
          <Typography variant="subtitle1" sx={{ color: "#009180" }}>
            {constantes.Vendas}
          </Typography>
          <Icone
            icone="arrow_drop_down_outlined "
            sx={{ color: "#009180" }}
            color="primary"
          />
        </Box>
        
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ color: "#009180" }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleClose("/orcamento-de-vendas")}>
            <Box
              display="flex"
              alignItems="center"
              component={Button}
              href="/orcamentosvenda"
              gap={1}
              textTransform="inherit"
            >
              <Icone icone="receipt_long" sx={{ color: "#009180" }} />
              <Typography variant="subtitle1" sx={{ color: "#009180" }}>
                {constantes.OrcamentoDeVenda}
              </Typography>
            </Box>
          </MenuItem>
          {usarAssistencia &&
            <MenuItem onClick={() => handleClose("/assistencias")}>
              <Box
                display="flex"
                alignItems="center"
                component={Button}
                href="/assistencias"
                gap={1}
                textTransform="inherit"
              >
                <Icone icone="settings" sx={{ color: "#009180" }} />
                <Typography variant="subtitle1" sx={{ color: "#009180" }}>
                  {constantes.Assistencias}
                </Typography>
              </Box>
            </MenuItem>
          }
        </Menu>
      </Box>

      <UserSelector>
        <Toolbar
          sx={{
            display: "flex",
          }}
        >
          <Box
            component={Button}
            onClick={handleClickAdmin}
            display="flex"
            alignItems="center"
            gap={1}
            textTransform="inherit"
          >
            <FaUser color="#009180" />
            <Typography variant="subtitle1" sx={{ color: "#009180" }}>
              {userLogin}
            </Typography>
            <Icone
              icone="arrow_drop_down_outlined"
              sx={{ color: "#009180" }}
              color="primary"
            />
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorElAdmin}
            open={openAdmin}
            onClose={handleClose}
            sx={{
              color: "#009180",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              flexDirection: "column", // Define a direção como coluna para o menu
              gap: 2,
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              sx={{
                color: "#009180",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column", // Define a direção como coluna para o menu
                gap: 2,
              }}
              onClick={() => handleClose("/orcamento-de-vendas")}
            >
              <Box
                display="flex"
                alignItems="center"
                component={Button}
                href="/usuario"
                gap={1}
              >
                <FaUser color="#009180" />
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#009180", textTransform: "lowercase" }}
                >
                  {userLogin}
                </Typography>
              </Box>

              {configuracoes?.Usuario?.PerfilAcesso?.PermitirSimulacaoWeb && (
                <Box
                  display="flex"
                  alignItems="center"
                  component={Button}
                  href="/usuario/simularacesso"
                  gap={1}

                >
                  <FaUserShield color="#009180" />
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#009180", textTransform: "lowercase" }}
                  >
                    {constantes.SimularAcesso}
                  </Typography>
                </Box>
              )}

              <Box
                display="flex"
                alignItems="center"
                component={Button}
                href="/logout"
                gap={1}
              >
                <FaSignOutAlt color="#009180" />
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#009180", textTransform: "lowercase" }}
                >
                  {constantes.Sair}
                </Typography>
              </Box>
            </MenuItem>
          </Menu>
          <LanguageSelector>
            <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
              {idiomaAtual === "pt-br" && (
                <Image
                  style={{
                    width: 30,
                    height: 30,
                  }}
                  src={Brasil}
                  alt="Português"
                ></Image>
              )}
              {idiomaAtual === "es-es" && (
                <Image
                  style={{
                    width: 30,
                    height: 30,
                  }}
                  src={Espanha}
                  alt="Espanhol"
                ></Image>
              )}

              {!idiomaAtual && (
                <Image
                  style={{
                    width: 30,
                    height: 30,
                  }}
                  src={Brasil}
                  alt="Português"
                ></Image>
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu
              onBlur={(e) => {
                SetIdiomaSelecionado(e.target.text);
                window.location.reload(false);
              }}
            >
              <Dropdown.Item value={"es-es"}>
                <Stack direction="horizontal" gap={2}>
                  <Image
                    style={{
                      width: 30,
                      height: 30,
                    }}
                    src={Espanha}
                    alt="es-es"
                  ></Image>
                  <label>es-es</label>
                </Stack>
              </Dropdown.Item>
              <Dropdown.Item value={"pt-br"}>
                <Stack direction="horizontal" gap={2}>
                  <Image
                    style={{
                      width: 30,
                      height: 30,
                    }}
                    src={Brasil}
                    alt="pt-br"
                  ></Image>
                  <label>pt-br</label>
                </Stack>
              </Dropdown.Item>
            </Dropdown.Menu>
          </LanguageSelector>
        </Toolbar>
      </UserSelector>
    </>
  ) : null;
};
