import OrcamentoVenda from "../../components/orcamento_venda";
import OrcamentoVendaContextProvider from "../../contexts/orcamentovenda-context";

const ViewOrcamentoVenda = () => {
    return (
            <OrcamentoVendaContextProvider>
                <OrcamentoVenda />
            </OrcamentoVendaContextProvider>
    )
}

export default ViewOrcamentoVenda;