import axios from "axios";
import { getTenant, getToken, ClearStorageLogout, GetIdiomaSelecionado } from "../localstorageConfig/localstorage";

export const GetIdiomaFiltro = () => {
  const idioma = GetIdiomaSelecionado();
  switch (idioma) {
      case "es-es":
          return "ES"
      default:
          return null

  }
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  
  headers: {
    "Content-type": "application/json",
  }
});


api.interceptors.request.use(
  async (config) => {
    const token = getToken();
    const idioma = GetIdiomaFiltro();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers.TenantId = getTenant();
    }
    if (idioma) {
      config.headers.idioma = idioma
    }
    
    //console.log("envio", config.method, config.url, config.data, config.headers)
    return config;
  },
  /*(error) => {
    console.log("erro retorno 1");
    return error;
  }*/
);


/*
api.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const deslogar = error.code === 'ERR_NETWORK'; 
    if (deslogar) {
      ClearStorageLogout();
    }

    if (error.response?.status) {
      switch (error.response.status) {
        case 500:
          // Handle Unauthenticated here
          console.log("cliente erro ", error.response?.data?.error?.message);
          break;
        case 401:
          // Handle Unauthenticated here
          console.log("Erro 401", error.response?.data?.error?.message);
          break;
        case 403:
          // Handle Unauthorized here
          console.log("Erro 403", error.response?.data?.error?.message);
          break;
          case 404:
            // Handle Unauthorized here
            console.log("Erro 404", error.response?.data?.error?.message);
            break;
          default:
          console.log("nao autorizado 3", error.response.status);
          break;

        // ... And so on
      }
    }

    return error;
  }
);
*/

export default api;


