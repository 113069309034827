import React, { useState } from 'react';
import FormaPagamentoService from '../../services/service.formapagamento';
import NewSearchBarControlFormik from './search-Bar.Control.Formik';

const SearchListFormasPagamento = (props) => {
  const [formaspagamento, setFormasPagamento] = useState(null);
  const [pages, setPages] = useState(1);

  const columns = [
    {
      field: 'Id',
      headerName: 'Codigo',
      width: 90,
      key: true
    },
    {
      field: 'Descricao',
      headerName: 'Descricao',
      width: 150,
    },
    {
      field: 'ExigirItensFiname',
      headerName: 'Exigir Itens Finame',
      width: 150,
      type: 'checkbox'
    }
  ];

  const columnsFilter = [
    {
      field: 'Id',
      headerName: 'Codigo',
      tipo: 'number'
    },
    {
      field: 'Descricao',
      headerName: 'Descricao',
      tipo: 'text'
    }
  ];

  const onSearchTitle = (filter) => {
    props.onLoad(true);
    FormaPagamentoService.getAll(filter)
      .then(response => {
        setFormasPagamento(response.data.value);
        setPages(response.data.pages);
        props.onLoad(false);
      })
      .catch(e => {
        console.error(e);
        props.onError(e);
        props.onLoad(false);
      });
  };

  const onGetItem = (id) => {
    id && props.onLoad(true);
    id && FormaPagamentoService.get(id)
      .then(response => {
        if (response.response?.data.error) {
          props.onError(response.response?.data.error);
        } else {
          response.data && onItemSelected(response.data);
        }
      })
      .catch(e => {
        props.onError(e);
      })
      .finally(() => {
        props.onLoad(false);
      });
  };

  const onItemSelected = (item) => {
    props.onItemSelected(item);
  };

  return (
    <>
      <NewSearchBarControlFormik
        {...props}
        pages={pages}
        onSearchTitle={onSearchTitle}
        onGetItem={onGetItem}
        onItemSelected={onItemSelected}
        data={formaspagamento}
        columns={columns}
        columnsFilter={columnsFilter}
        storagename={"FormaPagamento"}
      />
    </>
  );
}

export default SearchListFormasPagamento;