import React, { useCallback } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MenuLoading from "./components/menu_comp/MenuLoading";
import SignUp from "./pages/SignUp";
import Logout from "./pages/Logout/logout";
import ViewOrcamentovendaList from "./pages/Orcamentos/index";
import NewVencimentos from "./components/vencimento_comp/vencimentos-Vencimentos";
import ExampleList from "./pages/examples/example.list";
import "./App.css";
import SignIn from "./pages/SignIn";
import ExampleModalList from "./pages/examples/examplemodallist";
import ExampleItensList from "./pages/examples/exampleitenslist";
import NewAtributosProdutoControl from "./components/atributos_comp/atributo.ProdutoControl";
import NewOrcamentovendaDelete from "./components/ui_comp/Modals/ModalOrcamentoVendaDelete";
import ExampleItemOrcamento from "./pages/examples/example.itemorcamento";
import UsuarioSimulacaoAcesso from "./components/usuario_comp/usuario-simulacaoacesso";
import Usuario from "./components/usuario_comp";
import ViewOrcamentovenda from "./pages/Orcamentos/view.orcamentovenda";
import T from "./common/traducao";
import Clientes from "./pages/Clientes/index";
import ViewClientesTabs from "./pages/Clientes/view.clientes";
import Home from "./pages/Home";
import Assistencias from "./pages/Assistencias";
import ViewAssistenciasTabs from "./pages/Assistencias/view.assistencias";
import AccessExternal from "./components/access_external_comp/accessExternal";
import { WithRouter } from "./common/with-router";
import ExampleMap from "./pages/examples/example.map";
import { getApiKeyGoogleMaps } from "./config/localstorageConfig/localstorage";
import { APIProvider } from "@vis.gl/react-google-maps";

function App() {
  const constantes = T();

  const apiGoogleMaps = useCallback(() => getApiKeyGoogleMaps());

  return (
    <>
      <APIProvider apiKey={apiGoogleMaps()}>
        <BrowserRouter>
          <Routes>
            <Route path="/sigin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
          </Routes>
          <WithRouter>
            <Routes>
              <Route
                path="/"
                element={<MenuLoading />}
                errorElement={() => <h1>{constantes.Pagenotfound}</h1>}
              />

              <Route
                path="/access/:token/:redirecty"
                element={<AccessExternal />}
              />

              <Route path="/logout" element={<Logout />} />
              <Route path="/home" element={<Home />} />
              <Route
                path="/orcamentosvenda"
                element={<ViewOrcamentovendaList />}
              />
              <Route path="/clientes" element={<Clientes />} />
              <Route path="/assistencias" element={<Assistencias />} />

              <Route
                path="/orcamentosvenda/:id"
                element={<ViewOrcamentovenda />}
              />
              <Route
                path="/orcamentosvenda/new"
                element={<ViewOrcamentovenda />}
              />
              <Route path="/clientes/:id" element={<ViewClientesTabs />} />
              <Route path="/clientes/new" element={<ViewClientesTabs />} />
              <Route
                path="/assistencias/:id"
                element={<ViewAssistenciasTabs />}
              />
              <Route
                path="/assistencias/new"
                element={<ViewAssistenciasTabs />}
              />
              <Route
                path="/assistencias/duplicar/:idorigem"
                element={<ViewAssistenciasTabs />}
              />
              <Route
                path="/orcamentosvenda/duplicar/:idorigem"
                element={<ViewOrcamentovenda />}
              />
              <Route
                path="/orcamentosvenda/delete/:id"
                element={<NewOrcamentovendaDelete />}
              />
              <Route path="/examplemap" element={<ExampleMap />} />
              <Route path="/examplelist" element={<ExampleList />} />
              <Route
                path="/itemorcamentovenda/new"
                element={<ExampleItemOrcamento />}
              />
              <Route
                path="/vencimentos"
                element={
                  <NewVencimentos
                    DataBaseVencimentos={new Date()}
                    valortotal={166.28}
                  />
                }
              />
              <Route path="/examplemodallist" element={<ExampleModalList />} />
              <Route path="/exampleitens" element={<ExampleItensList />} />
              <Route
                path="/atributosproduto"
                element={<NewAtributosProdutoControl />}
              />
              {/*
                <Route path="/exampleatributos" element={<ExampleAtributos />} />
            {/*        <Route
              path="/examplecomposicao"
              element={<NewComposicaoProdutoProblemaControl id={42220} />}
            />
            <Route
              path="/exampleimprimir"
              element={<ExampleImprimirOrcamento />}
            />
            <Route
              path="/examplevencimentos"
              element={<ExampleVencimentos />}
            />
            <Route
              path="/exampleorcamento"
              element={<ExampleOrcamentoVendas />}
            />
            <Route
              path="/examplevalidation"
              element={<ExampleValidation id={1993} />}
            />

            <Route
              path="/exampleorcamento/new"
              element={<ExampleOrcamentoVendas />}
            />
            <Route
              path="/exampleorcamento/:id"
              element={<ExampleOrcamentoVendas />}
            />

            */}

              <Route
                path="/usuario/simularacesso"
                element={<UsuarioSimulacaoAcesso />}
              />
              <Route path="/usuario" element={<Usuario />} />

              <Route
                path="*"
                element={() => <h1>{constantes.Pagenotfound}</h1>}
              />
            </Routes>
          </WithRouter>
        </BrowserRouter>
      </APIProvider>
    </>
  );
}

export default App;
