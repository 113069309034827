import { Formik } from "formik";
import { Form, Accordion } from "react-bootstrap";
import SearchComp from "../../common/searchcomp";
import { Debug } from "../../common/Debug";
import CampoSearchListClientes from "../../components/campos_comp/campo-searchlist-clientes";



const columnsFilter = [
    {
        field: 'Id',
        headerName: 'Codigo',
        tipo: 'number'
    },
    {
        field: 'NomeFantasia',
        headerName: 'Nome',
        tipo: 'text'
    },
    {
        field: 'CPFCNPJ',
        headerName: 'CPF/CNPJ',
        tipo: 'text'
    },
    {
        field: 'UF',
        headerName: 'Municipio',
        tipo: 'text'
    },
]

const ExampleModalList = () => {
    return (
        <>
            <Formik
                initialValues={
                    {
                        OrcamentoVenda: {
                            Cliente: {
                                Id: 0,
                                Nome: ''
                            }
                        }
                    }
                }
                onSubmit={(values) => {
                    alert(values.OrcamentoVenda.Cliente.Id);
                }}
            >
                {({ handleSubmit, setFieldValue, values }) =>
                    <>
                        <SearchComp
                            title={"Luiz"}
                            campos={columnsFilter}
                            onSearch={(values) => {
                                this.onSearchTitle(values.searchValue, values.searchField, values.searchoperator);
                            }}

                        />
                        <Form onSubmit={handleSubmit}>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Identificação </Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group>
                                            <CampoSearchListClientes
                                                nameId="OrcamentoVenda.Cliente.Id"
                                                nameDesc="OrcamentoVenda.Cliente.Nome"
                                                placeholder="Cliente"
                                                onItemSelected={
                                                    (item) => {
                                                        setFieldValue("OrcamentoVenda.Cliente.Id", item.Id);   
                                                        setFieldValue("OrcamentoVenda.Cliente.Nome", item.NomeFantasia ? item.NomeFantasia : item.Nome);   
                                                    }
                                                }
                                            />


                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

<Debug />

                        </Form>

                    </>



                }
            </Formik>
        </>
    )
}

export default ExampleModalList;