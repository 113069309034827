import { useContext } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import OrcamentoVendaDescontosModule from "./orcamentovenda-descontos.module.modal";
import { useFormikContext } from "formik";
import { Button } from "react-bootstrap";
import OrcamentoVendaCondicoesEspeciaisCtx from "./orcamentovenda-condicoes.especiais";

const OrcamentoVendaDescontosCtx = () => {

    const {
        orcamentoVenda,
        somenteLeitura,
        constantes,
        descontosTabela,
        configs,
        updateDesconto1,
        updateDesconto2,
        updateDesconto3,
        updateDesconto4,
        updateDesconto5,
        updateDescontoEspecial,
        updateAcrescimo,
        updateAcrescimoEspecial,
        updateDescontosEAcrescimos,
    } = useContext(OrcamentoVendaContext);


    const {
        values,
        setFieldValue
    } = useFormikContext();

    function AtualizarDesconto(campo, valor) {
        switch (campo) {
            case "OrcamentoVenda.PercentualDesconto1Padrao":
                updateDesconto1(valor, setFieldValue, values);
                break;
            case "OrcamentoVenda.PercentualDesconto2Padrao":
                updateDesconto2(valor, setFieldValue, values);
                break;
            case "OrcamentoVenda.PercentualDesconto3Padrao":
                updateDesconto3(valor, setFieldValue, values);
                break;
            case "OrcamentoVenda.PercentualDesconto4Padrao":
                updateDesconto4(valor, setFieldValue, values);
                break;
            case "OrcamentoVenda.PercentualDesconto5Padrao":
                updateDesconto5(valor, setFieldValue, values);
                break;
            case "OrcamentoVenda.PercentualDescontoEspecialPadrao":
                updateDescontoEspecial(valor, setFieldValue, values);
                break;
            case "OrcamentoVenda.PercentualAcrescimoPadrao":
                updateAcrescimo(valor, setFieldValue, values);
                break;
            case "OrcamentoVenda.PercentualAcrescimoEspecialPadrao":
                updateAcrescimoEspecial(valor, setFieldValue, values);
                break;
            default:
                break;
        }
    }

    function AtualizarDescontosDeTela(descontos) {
        updateDescontosEAcrescimos(descontos, setFieldValue, values);
    }

    return (
        <>
            <OrcamentoVendaDescontosModule
                somenteLeitura={somenteLeitura}
                configs={orcamentoVenda.Configs}
                descontosTabela={descontosTabela}
                constantes={constantes}
                setDescontos={(descontos) => {
                    AtualizarDescontosDeTela(descontos)
                }}
                permiteAplicarNaTela={configs?.QuantidadeMaximaDescontos <= 1}
                // permiteAplicarNaTela={false}
                onAplicarDescontoEmTela={(permiteAplicarNaTela, campo, valor) => {
                    AtualizarDesconto(campo, valor);
                }}
            />
        </>
    )
}

export default OrcamentoVendaDescontosCtx;
