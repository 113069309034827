import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class ClasseEntidadeService extends ServiceBase {

  getAll(filter) {
    //return http.get("/clientes?$top=10");
    return http.get("/ClassesEntidade" + this.getFilter(filter));
  }

  get(id) {
    return http.get(`/ClassesEntidade/${id}`);
  }

}

export default new ClasseEntidadeService();

