import React, { useEffect, useState } from "react";
import { useField, useFormikContext } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import {
  formatarData,
  formatarDataInput,
  formattedInputDate,
  getData,
} from "../../utils/formattedInputDate";

registerLocale("pt-BR", ptBR);

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const [dataFormated, setDataFormated] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value || "";
    const formattedValue = formattedInputDate(value);
    setDataFormated(formattedValue);

    if (formattedValue.length === 10) {
      const [day, month, year] = formattedValue.split("/");
      const date = new Date(year, month - 1, day);
      if (!isNaN(date)) {
        setFieldValue(field.name, formatarData(date));
      }
    } else if (formattedValue.length === 0) {
      setFieldValue(field.name, "");
    }
  };

  useEffect(() => {
    if (field.value) {
      setDataFormated(formatarDataInput(field.value));
    }
  }, [field.value]);

  return (
    <DatePicker
      autoComplete="off"
      
      className="form-control"
      name={field.name}
      autoFocus={props.autoFocus}
      ref={props.ref}
      onBlur={field.onBlur}
      value={dataFormated}
      disabled={props.disabled}
      minDate={props.min && props.min !== "1899-12-30" && new getData(props.min)}
      maxDate={props.max && props.max !== "1899-12-30" && new getData(props.max)}
      showIcon={!props.disabled}
      todayButton="Data Atual"
      dateFormat={"dd/MM/yyyy"}
      selected={(field.value && getData(field.value)) || null}
      onSelect={(val) => {
        const newdate = new Date(val).toISOString().slice(0, 10);
        setDataFormated(formatarDataInput(val));
        setFieldValue(field.name, val ? formatarData(new Date(val)) : null);
      }}
      onChangeRaw={handleInputChange}
      locale={'pt-BR'}
    />
  );
};

export default DatePickerField;
