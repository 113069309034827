import React, { forwardRef, useCallback, useEffect, useState } from "react";
import {
  ControlPosition,
  Map,
  Marker,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { CustomMapControl } from "./map-control";
import MapHandler from "./map-handler";

const GoogleMaps = forwardRef((props, ref) => {
  const { onSelectedMarker, markerAtual, defaultValue } = props;
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markers, setMarkers] = useState();
  const places = useMapsLibrary("places");

  
  useEffect(() => {
    setMarkers({
      lat: selectedPlace?.geometry?.location.lat(),
      lng: selectedPlace?.geometry?.location.lng(),
      time: new Date(),
    });
  }, [selectedPlace]);

  const onMapClick = useCallback((event) => {
    setMarkers({
      lat: event.detail.latLng.lat,
      lng: event.detail.latLng.lng,
      time: new Date(),
    });
  });

  useEffect(() => {
    if (markerAtual) {
      setMarkers(markerAtual);
    }
  }, [markerAtual]);

  const handleSelectedMarker = (e) => {
    onSelectedMarker && onSelectedMarker(markers);
  };

  return (
    <>
      <Map
        style={{
          borderRadius: "0.5rem",
          overflow: "hidden",
          width: "100%",
          height: props.height ?? "80vh",
        }}
        defaultZoom={markerAtual ? 15 : 5}
        defaultCenter={markerAtual ?? { lat: 22.54992, lng: 0 }}
        gestureHandling={"greedy"}
        disableDefaultUI={true}
        onClick={onMapClick}
      >
        {onSelectedMarker && (
          <CustomMapControl
            places={places}
            markers={markers}
            defaultValue={defaultValue}
            controlPosition={ControlPosition.TOP}
            onPlaceSelect={setSelectedPlace}
            handleSelectedMarker={() => {
              handleSelectedMarker();
            }}
          />
        )}

        {onSelectedMarker && <MapHandler place={selectedPlace} />}

        <Marker position={markers} />
      </Map>
    </>
  );
});

export default GoogleMaps;
