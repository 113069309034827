import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import Menu from './components/menu_comp'
import * as serviceWorker from "./serviceWorker";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "react-bootstrap";
import H2jProviderContext from "./contexts/H2jGlobal-context";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <div>
    <ThemeProvider>
      <H2jProviderContext>
        <Menu />
        <App />
        <ToastContainer />
      </H2jProviderContext>
    </ThemeProvider>
  </div>
);

//{isAuthenticated() && <Main /> }

serviceWorker.unregister();