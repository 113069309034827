import { useContext } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import { Alert, Container } from "react-bootstrap";
import { useFormikContext } from "formik";
import {IoAlertCircle} from 'react-icons/io5'
const OrcamentoVendasValidacoesCtx = () => {
  const { errors, isValid, submitCount, setErrors } = useFormikContext();

  const errorsArray = errors?.OrcamentoVenda;
  const validacoes = errors?.Validacoes;

  const { somenteLeitura, constantes, orcamentoVenda } = useContext(
    OrcamentoVendaContext
  );

  return errors && submitCount > 0 && !somenteLeitura && !isValid ? (
    <>
      <hr className="h-50 mb-5" />
      <Container
        style={{ height: "30%", width: "100%", zIndex: 2 }}
        className="gap-1 bottom-0 position-fixed bg-white"
        fluid
      >
        <Alert variant="danger" onClose={() => setErrors({})} dismissible>
          <Alert.Heading><IoAlertCircle style={{marginTop: -5, marginRight: 5}}/>{constantes.Errospendentes}</Alert.Heading>
   
          <ul style={{ maxHeight: "100px", overflowY: "auto" }}>
            {errorsArray?.FormaPagamento && (
              <li>{errorsArray?.FormaPagamento}</li>
            )}

            {errorsArray?.Municipio && <li>{errorsArray?.Municipio}</li>}

            {errorsArray?.TabelaDesconto && (
              <li>{errorsArray?.TabelaDesconto}</li>
            )}

            {errorsArray?.TabelaPreco && <li>{errorsArray?.TabelaPreco}</li>}

            {errorsArray?.TipoFrete && <li>{errorsArray?.TipoFrete}</li>}

            {errorsArray?.TipoContribuinte && (
              <li>{errorsArray?.TipoContribuinte}</li>
            )}

            {errorsArray?.IndicadorIe && <li>{errorsArray?.IndicadorIe}</li>}

            {errorsArray?.TipoOperacao && <li>{errorsArray?.TipoOperacao}</li>}

            {errorsArray?.DataPrevisaoFaturamento && <li>{errorsArray?.DataPrevisaoFaturamento}</li>}

            {errorsArray?.Atributos && errorsArray?.Atributos.map((item) => {return <li>{item?.Valor}</li>;})}
          
            {validacoes &&
              validacoes.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
          </ul>
        </Alert>
        <hr className="h-50 mb-5" />
        <hr className="h-50 mb-5" />
      </Container>
    </>
  ) : null;
};

export default OrcamentoVendasValidacoesCtx;
