import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";
import { GetPermissoes, SetPermissoes } from "../config/localstorageConfig/localstorage";

class LoginService extends ServiceBase {

    refreshToken() {
        return http.post("/Login/refreshtoken");
    }

    simularAcesso(idVendedor) {
        return http.post(`/Login/simularacesso/${idVendedor}`);
    }
}

export default new LoginService();