export function formattedInputDate(value) {
    let formattedValue = value.replace(/\D/g, '');

    if (formattedValue.length > 1) {
        let day = formattedValue.substring(0, 2);
        if (parseInt(day, 10) > 31) {
            day = '31';
        }
        formattedValue = day + (formattedValue.length > 2 ? '/' : '') + formattedValue.substring(2);
    }

    if (formattedValue.length > 4) {
        let month = formattedValue.substring(3, 5);
        if (parseInt(month, 10) > 12) {
            month = '12';
        }
        formattedValue = formattedValue.substring(0, 2) + '/' + month + (formattedValue.length > 5 ? '/' : '') + formattedValue.substring(5, 9);
    }

    return formattedValue;
}

export function formatarData(data) {
    const ano = data.getFullYear();
    let mes = data.getMonth() + 1;
    let dia = data.getDate();
    mes = mes < 10 ? "0" + mes : mes;
    dia = dia < 10 ? "0" + dia : dia;

    return `${ano}-${mes}-${dia}`;
}

export function formatarDataInput(data) {
    if (!data) return '';

    return new Date(data).toLocaleDateString('pt-BR', { timeZone: "UTC" });
}

export function getData(data) {
    const retorno = data && new Date(
        data.substring(0, 4),
        data.substring(5, 7) - 1,
        data.substring(8, 10)
    );

    return retorno && new Intl.DateTimeFormat("pt-BR").format(retorno) === "30/12/1899"
        ? null
        : retorno;
}
