import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { useFormikContext } from "formik";
import { ItemOrcamentoVendaContext } from "../../contexts/itemorcamentovenda-context";

const ItemOrcamentoVendaCondicaoEspecialCtx = (props) => {

    const {
        modalDescontoEspecial,
        setModalDescontoEspecial,
        vendedorSimulado,
        configs,
        orcamentoVenda,
        addCondicaoEspecial,
        constantes,
    } = useContext(ItemOrcamentoVendaContext);

    const {
        setFieldValue,
        values
    } = useFormikContext();

    const refDescontoEspecial = useRef();
    const refJustificativa = useRef();

    return (
        <>
            {/* {vendedorSimulado && */}
            <Modal
                {...props}
                fullscreen={"xl-down"}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalDescontoEspecial}
                onHide={() => {
                    setModalDescontoEspecial(false);
                }}
                backdrop="static"
                keyboard={false}
                className={props.className}>
                <ModalHeader toggle={props.toggle} closeButton></ModalHeader>
                <ModalBody>
                    <Form.Label>
                        {constantes.DescontoEspecialExtra}
                    </Form.Label>
                    <Form.Control
                        type="Number"
                        ref={refDescontoEspecial}
                        onWheel={(e) => e.target.blur()}
                    />
                    <Form.Label>
                        {constantes.Justificativa}
                    </Form.Label>
                    <Form.Control
                        ref={refJustificativa}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {
                        addCondicaoEspecial({
                            PercentualDescontoEspecial: Number(refDescontoEspecial.current.value),
                            Justificativa: refJustificativa.current.value
                        }, setFieldValue, values)
                        setModalDescontoEspecial(false);
                    }}>
                       {constantes.ok}
                    </Button>
                </ModalFooter>
            </Modal>
            {configs?.HabilitarDescontoEspecial &&
                <Button onClick={() => {
                    setModalDescontoEspecial(true);
                }}>
                    <FontAwesomeIcon
                        icon={faAdd}
                    />
                </Button>
            }
        </>
    )
}

export default ItemOrcamentoVendaCondicaoEspecialCtx;