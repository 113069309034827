import { useContext, useState } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import SearchComp from "../../common/searchcomp";
import ListComp2 from "../../common/listcomp2";
import { Button, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import OrcamentoVendaService from "../../services/service.orcamentovenda";
import { toast } from "react-toastify";
import { useFormikContext } from "formik";


const OrcamentoVendaImportarItensCtx = (props) => {

    const columns = [
        {
            field: 'Id',
            headerName: 'Número',
            width: 90,
            key: true
        },
        {
            field: 'DataCadastro',
            headerName: 'Cadastro',
            width: 90,
            type: 'date',
            format: 'dd/MM/yyyyy'
        },
        {
            field: 'DataValidade',
            headerName: 'Validade',
            width: 90,
            type: 'date',
            format: 'dd/MM/yyyy'
        },
        {
            field: 'Situacao',
            headerName: 'Situacao',
            width: 150,
            type: "custom",
            onGetCustom: (item) => {
                const style = getStyle(item);
                return (
                    <Button style={style}>{item}</Button>
                )
            }
        },
        {
            field: 'CodCliente',
            headerName: 'Cliente',
            width: 150,
        },
        {
            field: 'NomeCliente',
            headerName: 'Nome Cliente',
            width: 150,
        },
        {
            field: 'ValorTotal',
            headerName: 'Valor Total',
            width: 150,
            digits: 2,
        }
    ];

    const columnsFilter = [
        {
            field: 'Id',
            headerName: 'Numero',
            tipo: 'number'
        },
        {
            field: 'Cliente.Id',
            headerName: 'Cliente',
            tipo: 'number'
        },
        {
            field: 'NomeCliente',
            headerName: 'Nome Cliente',
            tipo: 'text'
        }

    ]

    const [orcamentos, setOrcamentos] = useState();

    const [filter, setFilter] = useState();
    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1);

    function getStyle(Situacao) {
        if (Situacao === "Rascunho") {
            return { background: "#FFFFB0", color: 'black' }
        } else if (Situacao === "Bloqueado") {
            return { background: "#B1D8D8", color: 'black' }
        } else if (Situacao === "Liberado") {
            return { background: "#fff", color: 'black' }
        } else if (Situacao === "Finalizado") {
            return { background: "#98C4F3", color: 'black' }
        } else if (Situacao === "Cancelado") {
            return { background: "#FFBFBF", color: 'black' }
        }
        return null
    }

    const {
        constantes,
        modalImportarItens,
        setModalImportarItens,
        updateItensImportados,
        setImportandoItens,
        handleError
    } = useContext(OrcamentoVendaContext);


    const {
        setFieldValue,
        values
    } = useFormikContext();

    async function getOrcamento(id) {
        setModalImportarItens(false);
        setImportandoItens(true);
        await OrcamentoVendaService.get(id)
            .then((response) => {
                updateItensImportados(response.data.Itens, setFieldValue, values);
            })
            .catch((error) => {
                handleError(error);
            })
    }

    const onToogle = () => {
        setModalImportarItens(!modalImportarItens);
    }

    function pesquisar(values) {
        const filter = {
            situacao: null,
            campo: values.searchField,
            operacao: values.searchoperator,
            valor: values.searchValue,
            page: values.page
            // campoorder: orderField.current,
            // tipoorder: ordertype.current
        };

        setFilter(filter);
        OrcamentoVendaService.getAll(filter)
            .then((response) => {
                setOrcamentos(response.data.value);
                setPages(response.data.pages);
            })
            .catch(error => {
                toast.error(error.response.data.error.message);

            });
    }

    function onPageSelect(number) {
        pesquisar({
            ...filter,
            page : number
        });
    }

    function setItemSelected(item) {
        getOrcamento(item.Id);
    }

    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalImportarItens}
                onHide={onToogle}
            >
                <ModalHeader toggle={onToogle} closeButton></ModalHeader>
                <ModalBody>
                    <SearchComp
                        title={constantes.ImportarItensDoOrcamentoDeVendas}
                        campos={columnsFilter}
                        onSearch={(values) => {
                            pesquisar(values);
                        }}
                    />

                    <ListComp2
                        data={orcamentos}
                        msgAviso={false}
                        selecionar
                        pages={pages}
                        columns={columns}
                        onPageSelect={(number) => onPageSelect(number)}
                        onItemSelected={(item) => setItemSelected(item)}
                    />
                </ModalBody>
            </Modal>
        </>
    )
}


export default OrcamentoVendaImportarItensCtx;
