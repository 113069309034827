import { useState } from "react";
import SelectControl from "./control-selectcontrol.jsx";
import T from '../../common/traducao.js'

const IndicadorIeControl = (props) => {
    const constantes = T()
// // indicador de IE
// INDICADOR_IE_CONTRIB_ICMS_COM_IE = 1;
// INDICADOR_IE_CONTRIB_ICMS_ISENTO_IE = 2;
// INDICADOR_IE_NAO_CONTRIB_ICMS = 9;
// DESC_INDICADOR_IE_CONTRIB_ICMS_COM_IE = 'Contribuinte ICMS com IE';
// DESC_INDICADOR_IE_CONTRIB_ICMS_ISENTO_IE = 'Contribuinte ICMS isento de IE';
// DESC_INDICADOR_IE_NAO_CONTRIB_ICMS = 'Não contribuinte ICMS';

    const indicadoresIe = 
        [
            {
                Id : 0,
                Valor : "",
                Descricao : ""
            },
            {
                Id : 1,
                Valor : 1,
                Descricao : `${constantes.ContribuinteICMScomIE}`
            },
            {
                Id : 2,
                Valor : 2,
                Descricao : `${constantes.ContribuinteICMSisentodeIE}`
            },
            {
                Id : 9,
                Valor : 9,
                Descricao : `${constantes.NãocontribuinteICMS}`
            }
        ]

    return (
        <SelectControl  {...props} name={props.name} placeholder={constantes.IndicadorIE}
            itens={indicadoresIe} 
        />
    )
}

export default IndicadorIeControl;