import { useContext } from "react";
import { ClienteContext } from "../../contexts/clientes-context";
import SelectControl from "./control-select";

const SelectEmailPorDepartamento = (props) => {

    const {
        constantes,
    } = useContext(ClienteContext);

    const departamentos = [
        {
            Id: "",
            Valor: "",
            Descricao: ""
        },
        {
            Id: "Administrativo",
            Valor: "Administrativo",
            Descricao: constantes.Administrativo
        },
        {
            Id: "Comercial",
            Valor: "Comercial",
            Descricao: constantes.Comercial
        },
        {
            Id: "Compras",
            Valor: "Compras",
            Descricao: constantes.Compras
        },
        {
            Id: "Financeiro",
            Valor: "Financeiro",
            Descricao: constantes.Financeiro
        },
        {
            Id: "Recepção de NF-e",
            Valor: "Recepção de NF-e",
            Descricao: constantes.RecepcaoodeNFe
        },
        {
            Id: "Recepção de CT-e",
            Valor: "Recepção de CT-e",
            Descricao: constantes.RecepcaodeCTe
        },
        {
            Id: "TI",
            Valor: "TI",
            Descricao: constantes.TI
        }
    ]

    return (
        <SelectControl
            {...props}
            hideTitle
            name={props.name}
            placeholder={constantes.TipoPessoa}
            itens={departamentos}
        />
    )
}


export default SelectEmailPorDepartamento;