import { Alert } from "react-bootstrap";
import T from "../../../common/traducao";
import { AlertError } from "./index";
import { Typography } from "@mui/material";

const NewAlertError = (props) => {
    const constantes = T();
    const { error, onClose } = props;

    return error ?
        <AlertError onClose={onClose}>
             <Typography>{constantes.Erro}</Typography>
            <p>
                {error}
            </p>
        </AlertError>
        : null
}

export default NewAlertError;