import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class CausasAssistencia extends ServiceBase {

    getAll(filter) {
        return http.get("/CausasAssistencia" + this.getFilter(filter));
      }
      
    getId(id) {
        return http.get(`/CausasAssistencia/${id}`);
      }     
}  

export default new CausasAssistencia