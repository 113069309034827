import { Button, Col, Container, Form, InputGroup, Row, Stack } from "react-bootstrap";
import CalculatedControl from "../controls_comp/control-calculated";
import { useEffect } from "react";
import T from "../../common/traducao";
import { useFormikContext } from "formik";
import OrcamentoVendaCondicaoEspecialCtx from "./orcamentovenda-condicaoespecial";
import CondicaoEspecialControl from "../controls_comp/control-condicaoespecial";
import OrcamentoVendaCondicoesEspeciaisCtx from "./orcamentovenda-condicoes.especiais";

const OrcamentoVendaDescontos = (props) => {
    const { configs, somenteLeitura, descontosTabela, descontosLancados, onAplicarDescontos, permiteAplicarNaTela, modal, onAplicarDescontoEmTela } = props;

    const {
        setFieldValue,
        values
    } = useFormikContext()

    const constantes = T();

    useEffect(() => {
        setFieldValue("Descontos.PercentualDesconto1Padrao", values.OrcamentoVenda.PercentualDesconto1Padrao);
        setFieldValue("Descontos.PercentualDesconto2Padrao", values.OrcamentoVenda.PercentualDesconto2Padrao);
        setFieldValue("Descontos.PercentualDesconto3Padrao", values.OrcamentoVenda.PercentualDesconto3Padrao);
        setFieldValue("Descontos.PercentualDesconto4Padrao", values.OrcamentoVenda.PercentualDesconto4Padrao);
        setFieldValue("Descontos.PercentualDesconto5Padrao", values.OrcamentoVenda.PercentualDesconto5Padrao);
        setFieldValue("Descontos.PercentualDescontoEspecialPadrao", values.OrcamentoVenda.PercentualDescontoEspecialPadrao);
        setFieldValue("Descontos.PercentualAcrescimoPadrao", values.OrcamentoVenda.PercentualAcrescimoPadrao);
        setFieldValue("Descontos.PercentualAcrescimoEspecialPadrao", values.OrcamentoVenda.PercentualAcrescimoEspecialPadrao);
    }, [
        values.OrcamentoVenda.PercentualDesconto1Padrao,
        values.OrcamentoVenda.PercentualDesconto2Padrao,
        values.OrcamentoVenda.PercentualDesconto3Padrao,
        values.OrcamentoVenda.PercentualDesconto4Padrao,
        values.OrcamentoVenda.PercentualDesconto5Padrao,
        values.OrcamentoVenda.PercentualDescontoEspecialPadrao,
        values.OrcamentoVenda.PercentualAcrescimoPadrao,
        values.OrcamentoVenda.PercentualAcrescimoEspecialPadrao])

    const handleBlur = (e, campo, valor) => {
        permiteAplicarNaTela && setFieldValue(campo, valor);
        onAplicarDescontoEmTela && onAplicarDescontoEmTela(permiteAplicarNaTela, campo, valor);
    }

    return (
        <>
            <Row>
                {configs?.QuantidadeMaximaDescontos > 0 &&
                    configs.PermitirAlterarDescontosVenda && (
                        <Col sm >
                            <CalculatedControl
                                disabled={somenteLeitura}
                                name="Descontos.PercentualDesconto1Padrao"
                                valuecalculated={
                                    descontosTabela?.PercentualDesconto1Tabela
                                }
                                placeholder={constantes.PercentualDesconto1}
                                onBlur={(e) => {
                                    Number(values.Descontos.PercentualDesconto1Padrao ?? 0) != Number(values.OrcamentoVenda.PercentualDesconto1Padrao ?? 0)
                                        &&
                                        handleBlur(e, "OrcamentoVenda.PercentualDesconto1Padrao", Number(values.Descontos.PercentualDesconto1Padrao ?? 0));
                                }}
                            />
                        </Col>
                    )}
                {configs?.QuantidadeMaximaDescontos > 1 &&
                    configs.PermitirAlterarDescontosVenda && (
                        <Col sm>
                            <CalculatedControl
                                disabled={somenteLeitura}
                                name="Descontos.PercentualDesconto2Padrao"
                                valuecalculated={
                                    descontosTabela?.PercentualDesconto2Tabela
                                }
                                placeholder={constantes.PercentualDesconto2}
                                onBlur={(e) => {
                                    Number(values.Descontos.PercentualDesconto2Padrao ?? 0) != Number(values.OrcamentoVenda.PercentualDesconto2Padrao ?? 0)
                                        &&
                                        handleBlur(e, "OrcamentoVenda.PercentualDesconto2Padrao", Number(values.Descontos.PercentualDesconto2Padrao ?? 0));
                                }}
                            />
                        </Col>
                    )}
                {configs?.QuantidadeMaximaDescontos > 2 &&
                    configs.PermitirAlterarDescontosVenda && (
                        <Col sm>
                            <CalculatedControl
                                disabled={somenteLeitura}
                                name="Descontos.PercentualDesconto3Padrao"
                                valuecalculated={
                                    descontosTabela?.PercentualDesconto3Tabela
                                }
                                placeholder={constantes.PercentualDesconto3}
                                onBlur={(e) => {
                                    Number(values.Descontos.PercentualDesconto3Padrao ?? 0) != Number(values.OrcamentoVenda.PercentualDesconto3Padrao ?? 0)
                                        &&
                                        handleBlur(e, "OrcamentoVenda.PercentualDesconto3Padrao", Number(values.Descontos.PercentualDesconto3Padrao ?? 0));
                                }}
                            />
                        </Col>
                    )}
                {configs?.QuantidadeMaximaDescontos > 3 &&
                    configs.PermitirAlterarDescontosVenda && (
                        <Col sm>
                            <CalculatedControl
                                disabled={somenteLeitura}
                                name="Descontos.PercentualDesconto4Padrao"
                                valuecalculated={
                                    descontosTabela?.PercentualDesconto4Tabela
                                }
                                placeholder={constantes.PercentualDesconto4}
                                onBlur={(e) => {
                                    Number(values.Descontos.PercentualDesconto4Padrao ?? 0) != Number(values.OrcamentoVenda.PercentualDesconto4Padrao ?? 0)
                                        &&
                                        handleBlur(e, "OrcamentoVenda.PercentualDesconto4Padrao", Number(values.Descontos.PercentualDesconto4Padrao ?? 0));
                                }}

                            />
                        </Col>
                    )}
                {configs?.QuantidadeMaximaDescontos > 4 &&
                    configs.PermitirAlterarDescontosVenda && (
                        <Col sm>
                            <CalculatedControl
                                disabled={somenteLeitura}
                                name="Descontos.PercentualDesconto5Padrao"
                                valuecalculated={
                                    descontosTabela?.PercentualDesconto5Tabela
                                }
                                placeholder={constantes.PercentualDesconto5}
                                onBlur={(e) => {
                                    Number(values.Descontos.PercentualDesconto5Padrao ?? 0) != Number(values.OrcamentoVenda.PercentualDesconto5Padrao ?? 0)
                                        &&
                                        handleBlur(e, "OrcamentoVenda.PercentualDesconto5Padrao", Number(values.Descontos.PercentualDesconto5Padrao ?? 0));
                                }}

                            />
                        </Col>
                    )}
                {configs?.HabilitarDescontoEspecial &&
                    configs.PermitirAlterarDescontosVenda && (
                        <Col sm>
                            <CondicaoEspecialControl
                                // disabled={somenteLeitura}
                                disabled={true}
                                name="Descontos.PercentualDescontoEspecialPadrao"
                                // valuecalculated={
                                //     descontosTabela?.PercentualDescontoEspecialTabela
                                // }
                                placeholder={constantes.PercentualDescontoEspecial}
                                onBlur={(e) => {
                                    Number(values.Descontos.PercentualDescontoEspecialPadrao ?? 0) != Number(values.OrcamentoVenda.PercentualDescontoEspecialPadrao ?? 0)
                                        &&
                                        handleBlur(e, "OrcamentoVenda.PercentualDescontoEspecialPadrao", Number(values.Descontos.PercentualDescontoEspecialPadrao ?? 0));
                                }}

                            />


                        </Col>
                    )}
                {configs.PermitirAlterarAcrescimoVenda && (
                    <Col sm>
                        <CalculatedControl
                            disabled={somenteLeitura}
                            name="Descontos.PercentualAcrescimoPadrao"
                            valuecalculated={
                                descontosTabela?.PercentualAcrescimoTabela
                            }
                            placeholder={constantes.PercentualAcrescimo}
                            onBlur={(e) => {
                                Number(values.Descontos.PercentualAcrescimoPadrao ?? 0) != Number(values.OrcamentoVenda.PercentualAcrescimoPadrao ?? 0)
                                    &&
                                    handleBlur(e, "OrcamentoVenda.PercentualAcrescimoPadrao", Number(values.Descontos.PercentualAcrescimoPadrao ?? 0));
                            }}

                        />
                    </Col>
                )}
                {configs.PermitirAlterarAcrescimoVenda && (
                    <Col sm>
                        <CalculatedControl
                            disabled={somenteLeitura}
                            name="Descontos.PercentualAcrescimoEspecialPadrao"
                            valuecalculated={
                                descontosTabela?.PercentualAcrescimoEspecialTabela
                            }
                            placeholder={constantes.PercentualAcrescimoEspecial}
                            onBlur={(e) => {
                                Number(values.Descontos.PercentualAcrescimoEspecialPadrao ?? 0) != Number(values.OrcamentoVenda.PercentualAcrescimoEspecialPadrao ?? 0)
                                    &&
                                    handleBlur(e, "OrcamentoVenda.PercentualAcrescimoEspecialPadrao", Number(values.Descontos.PercentualAcrescimoEspecialPadrao ?? 0));
                            }}

                        />
                    </Col>
                )}

                {values.OrcamentoVenda?.CondicoesEspeciais?.length > 0 && configs?.HabilitarDescontoEspecial &&
                    <Col>
                        <OrcamentoVendaCondicoesEspeciaisCtx />
                    </Col>
                }

            </Row>




            {!permiteAplicarNaTela && modal &&
                <Container
                    className="p-3 mt-5 d-flex justify-content-center align-items-center gap-2 bottom-0 bg-white"
                    fluid
                >
                    <Col md={{ span: 10, offset: 8 }}>
                        <Button
                            style={{backgroundColor:"#009180",color:"white", border: "1px solid rgba(76,77,76,0.1550595238095238)"}}
                            onClick={(e) => {

                                // values.Descontos.PercentualDesconto1Padrao = Number(values.Descontos.PercentualDesconto1Padrao ?? 0);
                                // values.Descontos.PercentualDesconto2Padrao = Number(values.Descontos.PercentualDesconto2Padrao ?? 0);
                                // values.Descontos.PercentualDesconto3Padrao = Number(values.Descontos.PercentualDesconto3Padrao ?? 0);
                                // values.Descontos.PercentualDesconto4Padrao = Number(values.Descontos.PercentualDesconto4Padrao ?? 0);
                                // values.Descontos.PercentualDesconto5Padrao = Number(values.Descontos.PercentualDesconto5Padrao ?? 0);
                                // values.Descontos.PercentualAcrescimoPadrao = Number(values.Descontos.PercentualAcrescimoPadrao ?? 0);
                                // values.Descontos.PercentualAcrescimoEspecialPadrao = Number(values.Descontos.PercentualAcrescimoEspecialPadrao ?? 0);
                                // values.Descontos.PercentualDescontoEspecialPadrao = Number(values.Descontos.PercentualDescontoEspecialPadrao ?? 0);

                                setFieldValue("OrcamentoVenda.PercentualDesconto1Padrao", Number(values.Descontos.PercentualDesconto1Padrao ?? 0));
                                setFieldValue("OrcamentoVenda.PercentualDesconto2Padrao", Number(values.Descontos.PercentualDesconto2Padrao ?? 0));
                                setFieldValue("OrcamentoVenda.PercentualDesconto3Padrao", Number(values.Descontos.PercentualDesconto3Padrao ?? 0));
                                setFieldValue("OrcamentoVenda.PercentualDesconto4Padrao", Number(values.Descontos.PercentualDesconto4Padrao ?? 0));
                                setFieldValue("OrcamentoVenda.PercentualDesconto5Padrao", Number(values.Descontos.PercentualDesconto5Padrao ?? 0));
                                setFieldValue("OrcamentoVenda.PercentualDescontoEspecialPadrao", Number(values.Descontos.PercentualDescontoEspecialPadrao ?? 0));
                                setFieldValue("OrcamentoVenda.PercentualAcrescimoPadrao", Number(values.Descontos.PercentualAcrescimoPadrao ?? 0));
                                setFieldValue("OrcamentoVenda.PercentualAcrescimoEspecialPadrao", Number(values.Descontos.PercentualAcrescimoEspecialPadrao ?? 0));

                                onAplicarDescontos(values.Descontos);
                                //setFieldValue("OrcamentoVenda.PercentualDesconto1Padrao", descontos.PercentualDesconto1Padrao);
                            }}
                        >
                           {constantes.AplicarDescontoseAcrescimos}
                        </Button>
                    </Col>
                </Container>
            }
        </>
    )

}

export default OrcamentoVendaDescontos;
