import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { AssistenciasContext } from "../../contexts/assistencias-context";
import { Add, Delete, RemoveRedEye } from "@mui/icons-material";
import { useFormikContext } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import '@react-pdf-viewer/core/lib/styles/index.css';
import AssistenciaComentarioModal from "./assistencia-comentario-modal";
import ModalPreviewAnexos from "./assistencia-modal-preview-anexos";


function AssistenciaComentarios() {
  const {
    constantes,
    abrirModalComentarios,
    abrirModalAnexosComentarios,
    removerComentario,
    getAnexosdoComentario,
    configPermissoesAssistencia,
    assistenciaEdicao,

    somenteLeitura
  } = useContext(AssistenciasContext);

  const { values, setFieldValue } = useFormikContext();
  const [indexPreview, setIndexPreview] = useState(false)
  const handleRemoverComentario = (comentario) => {
    removerComentario(comentario, setFieldValue, values);
  };


  const handleVisualizarAnexosComentario = (index) => {
    setIndexPreview(index)
    getAnexosdoComentario(values.Assistencia.Id, values.Assistencia?.Comentarios[index]?.Id)
    abrirModalAnexosComentarios()
  };

  const DataFormatada = (item) => {
    const resultadoFormatado = new Date(item).toLocaleDateString(
      "pt-BR",
      { timeZone: "UTC" }
    )

    return resultadoFormatado
  }

  function GetField(args) {
    const separador = args.caption ? ":" : "";
    return (
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: "bold",
          color: "#636363",
          "@media (max-width: 900px)": {
            width: "100%",
            fontSize: 10,
          },
          "@media (max-width: 550px)": {
            fontSize: 10,
          }
        }}
      >
        {`${args.caption} ${separador} ${args.value}`}
      </Typography>
    )
  }

  return (
    <>
      <Grid container display={"flex"} flexDirection={"row"} spacing={2} p={3}>
        <Grid item xs={12} sm={12} md={12} lg={15}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            margin={"10px 0"}
            key={0}
          >
            <InputLabel>{constantes.Comentarios}</InputLabel>
            {configPermissoesAssistencia?.ResponsavelSolicitanteId === assistenciaEdicao?.Responsavel?.Id &&
              <Button
                disabled={false}
                style={{
                  background: "#0D9B86",
                  color: "white",
                  padding: "2px 4px",
                  border: "none",
                  "&:hover": {
                    background: "#0D8A7A",
                  },
                  "&:active": {
                    background: "#0D7261",
                  },
                }}
                onClick={() => {
                  abrirModalComentarios();
                }}
              >
                <Add />
              </Button>
            }
          </Box>

          <hr />

          {values.Assistencia?.Comentarios?.length !== 0 ? (
            <Grid
              container
              display={"flex"}
              flexDirection={"column"}
              sx={{
                width: "100%",
                background: "#eff2f1",
                padding: 2,
                borderRadius: 1,
              }}
            >
              {values.Assistencia?.Comentarios?.map((item, index) => (
                <>
                  <Grid
                    item
                    display={"flex"}
                    flexDirection={"row"}
                    margin={"5px 0"}

                  >
                    <Box
                      display={"flex"}
                      sx={{
                        width: "15px",
                        height: "auto",
                        borderRadius: 0.5,
                        background: "#098c78",
                        margin: "0px",
                        padding: "0px",
                      }}
                    />
                    <Box
                      display={"flex"}
                      width={"100%"}

                      alignItems={"center"}
                      justifyContent={"center"}
                      margin={"0"}
                      padding={2}
                      sx={{
                        background: "white",
                        "@media (max-width: 550px)": {
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "center"
                        }
                      }}
                    >
                      <Grid item xs={12} sm={4} md={2.5} lg={2}>
                        <GetField
                          caption={""}
                          value={DataFormatada(item.DataHoraInclusao)}
                        />
                        <GetField
                          caption={""}
                          value={item.UsuLoginInclusao}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sm={15}
                        md={8}

                        lg={7.2}
                        xl={7.2}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",

                        }}
                      >
                        <GetField
                          caption={""}
                          value={item.Texto}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={2.5} lg={2}>
                        {item.Responsavel &&
                          <GetField
                            caption={constantes.Responsavel}
                            value={item.Responsavel?.Nome}
                          />
                        }

                        {item.PrazoResposta &&
                          <GetField
                            caption={constantes.PrazoResposta}
                            value={DataFormatada(item.PrazoResposta)}
                          />
                        }
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={2}
                        md={1}
                        lg={1}
                        xl={0.5}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          height: "40px",
                          margin: 0,
                          padding: 0,
                          width: "100%"
                        }}
                      >

                        <IconButton onClick={() => handleVisualizarAnexosComentario(index)}>
                          <RemoveRedEye />
                        </IconButton>

                        <IconButton disabled={somenteLeitura} onClick={() => handleRemoverComentario(item)}>
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Box>
                  </Grid>
                </>
              ))}
            </Grid>
          ) : (

            <Box>
              <Typography variant="h6" sx={{ color: "#6d6d6d" }}>
                {constantes.Nenhumcomentarioadicionado}...
              </Typography>
            </Box>
          )}

          <AssistenciaComentarioModal />
          <ModalPreviewAnexos indexPreview={indexPreview} />

        </Grid>
      </Grid>
    </>
  );
}

export default AssistenciaComentarios;