import { ErrorMessage, useField, useFormikContext } from "formik";
import { forwardRef } from "react";
import { Form, Badge } from "react-bootstrap";

const EditControl = forwardRef((props, ref) => {
  const { name, placeholder, hideTitle, value, onChange } = props;

  const { handleChange, validateOnMount } = useFormikContext();
  const [fieldId, metaId, handleId] = useField(name);

  return (
    <Form.Group>
      {!hideTitle && <Form.Label className="mb-0">{placeholder}</Form.Label>}

      <Form.Control
        //isInvalid={!isValid}
        {...props}
        ref={ref}
        name={name}
        value={value ? value : metaId.value}
        onChange={(e) => {
          handleChange(e)
          onChange && onChange(e);
        }}
        onWheel={(e) => e.target.blur()}
      />

      {validateOnMount && metaId.error && <Badge bg="danger">{metaId.error}</Badge>}

      {!validateOnMount && <ErrorMessage name={props.name}>
        {(msg) => <Badge bg="danger">{msg}</Badge>}
      </ErrorMessage>}

    </Form.Group>
  );
});

export default EditControl;
