import { useEffect, useState } from "react";
import OrcamentoVendaService from "../../services/service.orcamentovenda";
import { Alert, Button, ProgressBar, Spinner } from "react-bootstrap";
import T from "../../common/traducao";
import { LinearProgress, Typography } from "@mui/material";
import { toast } from "react-toastify";

const GerarPedido = ({id}) => {

    const [retornoPedidoGerado, setRetornoPedidoGerado] = useState('');
    const [retornoErroGeracaoPedido, setRetornoErroGeracaoPedido] = useState('');
    const [loading, setLoading] = useState(false);

    const constantes = T();

    async function GerarPedido() {

        setLoading(true);
        await OrcamentoVendaService.GerarPedido(id)
            .then((response) => {
                setRetornoPedidoGerado(response.data.message);
                toast.success(`${constantes.PedidoGerado} ${response.data.id}`);
            })
            .catch((error) => {
                const _error =
                    error.code === "ERR_NETWORK"
                        ? "Server OffLine"
                        : error.response && error.response.data && error.response.data.error
                            ? error.response.data.error.message
                            : error.message;
                setRetornoErroGeracaoPedido(_error);
   
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        GerarPedido();
    }, [])


    return (
        <>
            <Typography sx={{fontSize: 18}}>{constantes.GerandoPedidoParaOrcamento} {id}</Typography>

            {retornoErroGeracaoPedido &&

                <Alert variant="danger">
                    {retornoErroGeracaoPedido}
                </Alert>}

            {retornoPedidoGerado &&
                <Alert variant="success">
                    {retornoPedidoGerado}
                </Alert>}

            {' '}
            {loading && <LinearProgress color="inherit"  />}

        </>
    )
}

export default GerarPedido;