import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Fade,
  Typography,
  Divider,
} from "@mui/material";
import { ClienteContext } from "../../contexts/clientes-context";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";

export default function NewAcoesCadastro() {
  const {
    openModal,
    setOpenModal,
    abrirModalSolicitar,
    confirmarSolicitacao,
    cancelarSolicitacao,
    solicitarCliente,
    detalhesClientes,
    constantes,
  
  } = useContext(ClienteContext);
  
  const navigate = useNavigate();
  const { values,setFieldValue, handleChange,handleSubmit } = useFormikContext();

 
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        padding: 2,
        gap: "15px",
        backgroundColor: "white",
        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {solicitarCliente ? (
        <>
          <Button
           
            onClick={abrirModalSolicitar}
            sx={{ background: "#f0ad4e", color: "white" }}
          >
            {constantes.Solicitar}
          </Button>
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            closeAfterTransition
          >
            <Fade in={openModal}>
              <Box
                sx={{
                  backgroundColor: "white",
                  boxShadow: 24,
                  p: 3,
                  width: "80%", // Default width
                  margin: "auto",
                  marginTop: "80%",
                  "@media (min-width: 600px)": {
                    width: "60%",
                    marginTop: "35%",
                  },
                  "@media (min-width: 960px)": {
                    width: "40%",
                    display:"flex",
                    marginTop: "28%",
                    flexDirection:"column"
                  },
                  "@media (min-width: 1110px)": {
                    width: "40%",
                    display:"flex",
                    marginTop: "15%",
                    flexDirection:"column"
                  },
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {constantes.SolicitarCliente}
                </Typography>
                <Divider sx={{ background: "black" }} />
                <Box>
                  <textarea
                    name="solicitarAlteracoesClientes"
                    value={values.solicitarAlteracoesClientes}
                    onChange={handleChange}
                    style={{width: "100%", margin: "1rem 0", borderRadius: 10, border:' 1px solid grey', padding: "0.5rem"}}
                    rows={3}
                    placeholder="Faça sua solicitação..."
                  />
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    type="submit"
                    onClick={cancelarSolicitacao}
                    sx={{ background: "crimson", color: "white" }}
                  >
                    {constantes.Cancelar}
                  </Button>
                  <Button
                    
                    onClick={() => {
                      setFieldValue("solicitarAlteracoesClientes", values.solicitarAlteracoesClientes) 
                      confirmarSolicitacao(detalhesClientes.Id, values.solicitarAlteracoesClientes)
                    }}
                    sx={{ background: "#0D9B86", color: "white" }}
                  >
                    {constantes.Confirmar}
                  </Button>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </>
      ) : (
        <>
          <Button type="submit" sx={{ background: "#0D9B86", color: "white" }}>
            {constantes.Cadastrar}
          </Button>
        </>
      )}
      <Button
        onClick={() => {
          navigate(-1);
        }}
        sx={{ background: "crimson", color: "white" }}
      >
        {constantes.Cancelar}
      </Button>
    </Box>
  );
}
