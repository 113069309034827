import { Dropdown, Stack } from "react-bootstrap";
import { FaCross, FaSignOutAlt, FaUser, FaUserShield } from "react-icons/fa";
import T from "../../../common/traducao";
import {
  Box,
  Drawer,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { BotaoIcone } from "../Buttons/BotaoIcone";

import { Icone } from "../Icons/Icon";

import Logo from "../../../assets/logo.png";
import * as Styled from "../../../styles/styledDropdownSeletorUsuario";

const UserSelector = ({children}) => {

  return (
    <Dropdown>
    
        {children}
    </Dropdown>
  );
};

export default UserSelector;