import { type } from "@testing-library/user-event/dist/type";
import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import { Component } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const SelectControl = (props) => {

    const {
        handleChange
    } = useFormikContext();

    const [fieldId, metaId, handleId] = useField(props.name);

    return (
        <>
            <Form.Group>
                {!props.hideTitle && <Form.Label className="mb-0">{props.placeholder}</Form.Label>}

                <Form.Select
                    {...props}
                    value={metaId.value}
                    onChange={(e) => {
                        handleChange(e);
                        props.onChange && props.onChange(e);
                    }}
                    onBlur={(e) => props.onBlur ? props.onBlur(e) : e.target.blur()}
                >

                    {props.nulloption && (
                        <option key={0} value={0}>
                            {null}
                        </option>
                    )}
                    {props.itens?.map((item, i) => {
                        const index = props.nulloption ? i + 1 : i;
                        return (
                            <option key={index} item={item} value={item.Valor}>
                                {item.Descricao ? item.Descricao : item.Valor}
                            </option>
                        );
                    })}
                </Form.Select>
                <ErrorMessage name={props.name}>
                    {(msg) => (
                        <Badge bg="danger">{msg}</Badge>
                    )}
                </ErrorMessage>
            </Form.Group>
        </>
    )
}

export default SelectControl;
