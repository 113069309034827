import React, { useState } from 'react';
import ClassesEntidadeService from '../../services/service.classeentidade';
import SearchBarControlFormik from './search-Bar.Control.Formik';

const SearchListClasseEntidades = (props) => {
  const [classeEntidade, setClasseEntidade] = useState(null);
  const [pages, setPages] = useState(1);


  const columns = [
    {
      field: 'Id',
      headerName: 'Codigo',
      width: 90,
      key: true
    },
    {
      field: 'Nome',
      headerName: 'Nome',
      width: 150,
    },
    {
      field: 'FornecedorEfetivo',
      headerName: 'Fornecedor Efetivo',
      type: "checkbox",
      width: 150,
    },
  ]

  const columnsFilter = [
    {
      field: 'Id',
      headerName: 'Codigo',
      tipo: 'number'
    },
    {
      field: 'Nome',
      headerName: 'Nome',
      tipo: 'text'
    }
  ];

  const onLoad = (loading) => {
    props.onLoad && props.onLoad(loading);
  };

  const onError = (error) => {
    props.onError && props.onError(error);
  };

  const onSearchTitle = (filter) => {
    onLoad(true);
    ClassesEntidadeService.getAll(filter)
      .then(response => {
        onLoad(false);
        setClasseEntidade(response.data.value);
        setPages(response.data.pages);
      })
      .catch(e => {
        onLoad(false);
        onError(e);
      });
  };

  const onGetItem = (id) => {
    id && onLoad(true);
    id && ClassesEntidadeService.get(id)
      .then(response => {
        if (response.response?.data.error) {
          onError(response.response?.data.error);
        } else {
          response.data && onItemSelected(response.data);
        }
      })
      .catch(e => {
        onError(e);
      })
      .finally(() => {
        onLoad(false);
      });
    !id && onItemSelected(null);
  };

  const onItemSelected = (item) => {
    props.onItemSelected && props.onItemSelected(item);
  };

  return (
    <>
      <SearchBarControlFormik
        {...props}
        pages={pages}
        onSearchTitle={onSearchTitle}
        onGetItem={onGetItem}
        onItemSelected={onItemSelected}
        data={classeEntidade}
        columns={columns}
        columnsFilter={columnsFilter}
        storagename={"ClasseEntidade"}
      />
    </>
  );
};

export default SearchListClasseEntidades;

