import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import { useFormikContext } from "formik";
import T from "../../../common/traducao";

const ModalComposicaoProdutoControl = ({children, ...props}) => {

  const constantes = T()

  const [modal, setModdal] = useState(false);
  const { closeModal,titleModal,apenasVisualizar, className, maxWidthModal} = props;

  const formik = useFormikContext();
  const { values, setFieldValue, handleChange } = formik;

  

  useEffect(() => {
    setModdal(props.modal);
  }, [props.modal]);

 
  return (
    <Dialog
      maxWidth={maxWidthModal ?? "lg"}
      fullWidth
      open={modal}
      onClose={closeModal}
      className={className}
    >
      <DialogTitle>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <DisplaySettingsIcon sx={{ display: "flex" }} />{" "}
          <Typography
            sx={{ color: "#404044", fontSize: "18px", fontWeight: "bold" }}
          >
            {titleModal}{" "}
          </Typography>
        </Box>
      </DialogTitle>
      <Divider sx={{background:"#000"}}/>
      <DialogContent>
         {children}
   
      </DialogContent>
      <Divider sx={{background:"#000"}}/>
      <DialogActions sx={{ gap: 1 }}>
        {/*
        !apenasVisualizar && (
            <Button
                onClick={() => handleConfirmItemSelected()}
                style={{ background: "#009984", width: "95px", border: "none" }}
            >
             {constantes.Confirmar}
           </Button>
        ) 
        
        */}
       
        <Button
          onClick={closeModal}
          style={{ background: "crimson", width: "95px", border: "none" }}
        >
          {constantes.Sair}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalComposicaoProdutoControl;
