import { useContext } from "react";
import {
  Box,
  Paper,
  Typography,  
} from "@mui/material";
import { BsHouseGearFill } from "react-icons/bs";
import { AssistenciasContext } from "../../contexts/assistencias-context";
import "react-datepicker/dist/react-datepicker.css";
import VisitaTecnica from "./assistencia-visita-tecnica";
import { MdOutlineSupportAgent } from "react-icons/md";


export default function AssistenciaTabVisitaTecnica() {

  const {
    constantes,
  } = useContext(AssistenciasContext);

  return (
    <Box component={Paper}>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        height={50}
        sx={{ backgroundColor: "rgba(59, 134, 124, 0.08)" }}
      >
        <Typography variant="h1" fontSize={22} fontWeight="sembold">
          <MdOutlineSupportAgent size={26} style={{ color: "black",marginBottom: "5px", marginRight: "8px" }} />
          {constantes.AssistenciaTecnica}
        </Typography>
      </Box>
      <VisitaTecnica/>
  </Box>
  );
}
