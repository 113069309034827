import { useEffect, useState } from "react";
import OrcamentoVendaService from "../../services/service.orcamentovenda";
import T from "../../common/traducao";

const DuplicarOrcamento = (props) => {

    const constantes = T();
    const { id } = props;

    async function DuplicarOrcamento() {
        
        /*await OrcamentoVendaService.ImprimirRelatorio(link)
            .then((response) => {
               
                
            })
            .catch((error) => {

            })
            .finally(() => {

            })
            */
            
    }

    useEffect(() => {
        DuplicarOrcamento();
    }, [])


    return (
        <>
            <h1>{constantes.MsgDuplicarOrcamento}...</h1>
        </>
    )
}

export default DuplicarOrcamento;