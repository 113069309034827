import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class ClienteService extends ServiceBase {

  getAll(filter) {
    return http.get("/clientes" + this.getFilter(filter));
  }

  getAllAtivos(filter) {
    return http.get("/clientes/ativos" + this.getFilter(filter));
  }

  get(id) {
    return http.get(`/clientes/${id}`);
  }

  getAtivos(id) {
    return http.get(`/clientes/ativos/${id}`);
  }

  post(data){
    return http.post(`/clientes`, data)
  }

  solicitarMudanca(id, data) {
    return http.post(`/clientes/${id}/solicitaralteracao`, data)
  }  
  put(id){
    return http.put(`/clientes/${id}`)
  }

  pedidos(id, filter){
    return http.get(`/clientes/${id}/pedidos${this.getFilter(filter)}`)
  }

  notas(id, filter){
    return http.get(`/clientes/${id}/notas${this.getFilter(filter)}`)
  }

}

export default new ClienteService();