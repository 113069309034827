import React, { createContext, useState, useEffect } from "react";
import T from "../common/traducao";
import serviceCliente from "../services/service.cliente";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { GetAtributosClientes, GetConfiguracoes } from "../config/localstorageConfig/localstorage";
import AtributoService from "../services/service.atributo";

export const ClienteContext = createContext({
  cliente: {},
  AdicionarCliente: () => { },
  updateClasseEntidade: (classeEntidade) => { },
  updateAtributos: (atributos) => { }
});

const validationSchema = Yup.object().shape({

  Entidade: Yup.object().shape({
    CpfCnpj: Yup.string().required("CPF/CNPJ obrigatório"),
    Nome: Yup.string().required("Campo Nome obrigatório"),
    DataNascimentoFundacao: Yup.date()
      .nullable()
      .required("Data Nascimento obrigatória"),
    Cep: Yup.string().required("Campo Cep obrigatório"),
    Endereco: Yup.string().required("Campo Endereço obrigatório"),
    Municipio: Yup.object().required("Campo municipio obrigatório"),
    Email: Yup.string().required("Campo Email Contato obrigatório"),
    Emails: Yup.array().of(
      Yup.object().shape({
        Destino: Yup.string().required("Destino obrigatório"),
        Email: Yup.string().email("E-mail inválido").required("Email obrigatório"),
      })
    )
  }),
  Geral: Yup.string().when(["Entidade.CpfCnpj", "Entidade.DataNascimentoFundacao", "Entidade.Nome"], {
    is: (CpfCnpj, DataNascimentoFundacao, Nome) => { return !CpfCnpj || !DataNascimentoFundacao || !Nome },
    then: (schema) => schema.required("Validações"),
  }),
  EnderecoEntrega: Yup.string().when(["MunicipioEntrega"], {
    is: (MunicipioEntrega) => { return MunicipioEntrega?.Id > 0 },
    then: (schema) => schema.required("Endereço Entrega Obrigatório"),
  }),
  // MunicipioEntrega: Yup.object().when(["EnderecoEntrega"], {
  //   is: (EnderecoEntrega) => { return EnderecoEntrega },
  //   then: (schema) => schema.required("Município Entrega Obrigatório"),
  // }),
  EnderecoCobranca: Yup.string().when(["MunicipioCobranca"], {
    is: (MunicipioCobranca) => { return MunicipioCobranca?.Id > 0 },
    then: (schema) => schema.required("Endereço Entrega Obrigatório"),
  }),
  // MunicipioCobranca: Yup.object().when(["CepEntrega"], {
  //   is: (CepEntrega) => { return CepEntrega },
  //   then: (schema) => schema.required("Município Cobrança Obrigatório"),
  // }),
  EnderecoPrincipal: Yup.string().when(["Entidade.Endereco"], {
    is: (Endereco) => { return !Endereco },
    then: (schema) => schema.required("Validações"),
  }),
  Emails: Yup.string().when("Entidade.Email", {
    is: (Email) => !Email, // Apenas obrigatório se "Entidade.Email" estiver vazio
    then: (schema) => schema.required("Por favor, insira um e-mail alternativo"),
}),
  Atributos: Yup.array().of(
    Yup.object().shape(
      {
        Atributo: Yup.object({
          Obrigatorio: Yup.boolean(),
        }),
        Valor: Yup.string().nullable().trim().when(["Atributo"], {
          is: (Atributo) => { 
            return Atributo?.Obrigatorio 
          },
          then: (schema) => {return schema.required((value, context) => {return `Atributo Obrigatório`})},
        })
      }
    ),
  ),
  DadosAdicionais: Yup.string().when(["Atributos"], {
    is: (Atributos) => { 
      const temNaoPreenchido = Atributos?.find((item) => {
        return item.Atributo.Obrigatorio && !item.Valor
      })
      return temNaoPreenchido
    },
    then: (schema) => {
      return schema.required("Validações")
    },
  }),

});

const ClientesProvider = ({ children }) => {
  const [modalValidacoes, setModalValidacoes] = useState(false);
  const [validacoes, setValidacoes] = useState();
  const [menu, setMenu] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [solicitarCliente, setSolicitaCliente] = useState(false);
  const [somenteLeitura, setSomentoLeitura] = useState(false);
  const [detalhesClientes, setDetalhesClientes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTab] = useState(0);
  const [load, setLoad] = useState(false);
  const constantes = T();
  const navigate = useNavigate();
  const configGeral = GetConfiguracoes();
  const [camposDesabilitados, setCamposDesabilitados] = useState({});
  const [emailsObrigatorios, setEmailsObrigatorios] = useState([]);
  const [erros, setErros] = useState(null);
  const [loadingError, setLoadingError] = useState();
  const camposPrivados = configGeral.ConfiguracaoWeb;
  const [configAtributosCliente, setConfigAtributosCliente] = useState();


  function handleError(error) {
    const _error =
      error.code === "ERR_NETWORK"
        ? constantes.ServerOffLine
        : error.response && error.response.data && error.response.data.error
          ? error.response.data.error.message
          : error.message;
    return setErros(_error);
  }

  const addEmailDepartamento = (setValues) => {
    setValues((prevValues) => ({
      ...prevValues,
      Entidade: {
        ...prevValues.Entidade,
        Emails: [
          ...(prevValues.Entidade.Emails || []),
          { Destino: "", Email: "" },
        ],
      },
    }));
  };

  const removerEmailDepartamento = (index, setValues) => {
    setValues((prevValues) => {
      const updatedEmailsDepartamento = [...(prevValues.Entidade?.Emails || [])];
      const emailToRemove = updatedEmailsDepartamento[index];

      if (!emailToRemove.obrigatorio) {
        updatedEmailsDepartamento.splice(index, 1);
      } else {
        console.warn('Este email é obrigatório e não pode ser removido.');
      }

      return {
        ...prevValues,
        Entidade: {
          ...prevValues?.Entidade,
          Emails: updatedEmailsDepartamento,
        },
      };
    });
  };


  const updateClasseEntidade = (classeEntidade, setValues) => {
    setValues((prevValues) => (
      {
        ...prevValues,
        ClasseEntidade: classeEntidade,
      }));
  }

  const updateAtributos = (atributos, setValues, values, setFieldValue) => {
    // setAtributos(atributos, setValues)
    
    setValues((prevValues) => (
      {
        ...prevValues,
        Atributos: atributos,
      }));
  }

  
  async function AdicionarCliente(data) {

    data.Entidade.IndicadorIe = Number(data.Entidade.IndicadorIe);

    serviceCliente
      .post(data)
      .then((response) => {
        toast.success(`Cliente cadastrado com sucesso com Código ${response.data.Id}`);
        navigate("/clientes");
      })
      .catch((error) => {
        handleError(error);
      })
  }

  const handleLoad = (loading) => {
    return setLoad(loading);
  };

  const fecharModalValidacoes = () => {
    setModalValidacoes(false);
  };
  const abrirModalSolicitar = () => {
    setOpenModal(true);
  };
  const confirmarSolicitacao = (id, solicitaralteracao) => {
    enviarSolicitacao(id, solicitaralteracao);
    setOpenModal(false);
  };
  const cancelarSolicitacao = () => {
    setOpenModal(false);
  };
  const abrirMenu = () => {
    setMenu(true);
  };
  const fecharMenu = () => {
    setMenu(false);
  };
  const dadosDetalhesClientes = async (id) => {
    setLoading(true);
    setLoadingError(null);
    await serviceCliente
      .get(id)
      .then((response) => {
        setDetalhesClientes(response.data);
        setDisabled(true);
        setSomentoLeitura(true);
        setSolicitaCliente(true);
      })
      .catch((err) => {
        handleError(err)
        setLoadingError(err);
      })
      .finally(() => {
        setLoading(false);

      });
  };

  const enviarSolicitacao = async (id, solicitaralteracao) => {
    setLoading(true);
    const motivo = { Motivo: solicitaralteracao };
    await serviceCliente
      .solicitarMudanca(id, motivo)
      .then((response) => {
        toast.success("Solicitação de mudança enviada com sucesso !");
        navigate("/clientes");
      })
      .catch((err) => {
        toast.warning(err.response.data.error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validacaoInputsNumber = (event) => {
    const keyPressed = String.fromCharCode(event.keyCode);
    const isNumber = /^[0-9]$/.test(keyPressed) || event.key === "Backspace";

    const isNumpadNumber =
      /^[0-9]$/.test(event.key) &&
      (event.code.startsWith("Numpad") || event.code.startsWith("Digit"));
    const isSpecialKey = ["Backspace", "Tab", "Space", " "].includes(event.key);

    if (!isNumber && !isNumpadNumber && !isSpecialKey) {
      event.preventDefault();
    }
  };

  const initialValues = {
    Codigo: 0,
    Entidade: {
      Cep: "",
      Nome: "",
      ApelidoFantasia: "",
      Bairro: "",
      DataNascimentoFundacao: null,
      RgIe: "",
      Email: "",
      FoneCelular: "",
      FoneFax: "",
      TipoPessoa: "Jurídica",
      Rg: "",
      Emails: [{ Destino: "", Email: "" }],
      IndicadorIe: 0,
      Endereco: "",
      CpfCnpj: "",
      NomeContato: "",
      Municipio: null,
      FoneResidencial: "",
      NumeroEndereco: "",
      FoneComercial: "",
      InscricaoMunicipal: "",
      ComplementoEndereco: "",
      IdentidadeEstrageiro: "",
    },
    Situacao: "",
    Documento: "",
    IdMunicipio: 0,
    inscricaoEstadualProdutor: "", // tem que rever esse campo
    NomeRecebEntrega: "",
    CpfCnpjEntrega: "",
    TelefoneEntrega: "",
    EmailEntrega: "",
    InscricaoEstadualEntrega: "",
    EnderecoEntrega: "",
    NumeroEnderecoEntrega: "",
    ComplementoEnderecoEntrega: "",
    BairroEntrega: "",
    CepEntrega: "",
    MunicipioEntrega: null,
    ObservacaoEntrega: "",
    EnderecoCobranca: "",
    NumeroEnderecoCobranca: "",
    ComplementoEnderecoCobranca: "",
    BairroCobranca: "",
    CepCobranca: "",
    MunicipioCobranca: null,
    DestinoOperacao: "",
    NotasGerais: "",
    solicitarAlteracoesClientes: "",
  };

  const CamposVisibilidadePrivada = async () => {
    const apiString = camposPrivados.CamposDesabilitadosCadCliente;
    const camposDaApi = apiString.split(";");

    const camposDesabilitadosObj = {
      Codigo: false,
      EnderecodeCobranca: false,
      EnderecodeEntrega: false,
      ApelidoFantasia: false,
      Email: false,
      FoneCelular: false,
      FoneFax: false,
      FoneResidencial: false,
      FoneCelular: false,
      Rg: false,
      InscricaoMunicipal: false,
      IdentidadeEstrageiro: false,
      ObservacaoEntrega: false
    };

    camposDaApi.forEach((campo) => {
      switch (campo) {
        case "cle_id":
          camposDesabilitadosObj.Codigo = true;
          break;
        case "cli_observacao_entrega":
          camposDesabilitadosObj.ObservacaoEntrega = true;
          break;
        case "endereco_cobranca":
          camposDesabilitadosObj.EnderecodeCobranca = true;
          break;
        case "endereco_entrega":
          camposDesabilitadosObj.EnderecodeEntrega = true;
          break;
        case "ent_apelido_fantasia":
          camposDesabilitadosObj.ApelidoFantasia = true;
          break;
        case "ent_email":
          camposDesabilitadosObj.Email = true;
          break;
        case "ent_fone_celular":
          camposDesabilitadosObj.FoneCelular = true;
          break;
        case "ent_fone_fax":
          camposDesabilitadosObj.FoneFax = true;
          break;
        case "ent_fone_residencial":
          camposDesabilitadosObj.FoneResidencial = true;
          break;
        case "ent_rg":
          camposDesabilitadosObj.Rg = true;
          break;
        case "ent_inscricao_municipal":
          camposDesabilitadosObj.InscricaoMunicipal = true;
          break;
        case "ent_ident_estrangeiro":
          camposDesabilitadosObj.IdentidadeEstrageiro = true;
          break;
        default:
          camposDesabilitadosObj[campo] = null;
          break;
      }
    });

    setCamposDesabilitados(camposDesabilitadosObj);
  };

  const CamposEmailsObrigatorios = async () => {
    const emailsObrigatorios = camposPrivados.DestinosEmailObrigatorios;
    const camposDaApi = emailsObrigatorios.split(";");
    setEmailsObrigatorios(camposDaApi.map((item, index) => (
      { Destino: item, Email: "", obrigatorio: true }
    )))
  }

  const SetAtributosService = async () => {
    AtributoService.getValoresCliente()
      .then((response) => {
        const valores = response.data.value;
        setConfigAtributosCliente(valores);
      })
      .catch((error) => console.log("atributos", error));
  }


  const setAtributos = (atributos, setValues) => {
    const valores = (atributos ?? configAtributosCliente)?.map((item, index) => {
      const item_atual = atributos?.find((item_atr, index_atr) => {
        return item_atr.Atributo?.Id === item.Atributo?.Id;
      });
      const novo_item = {
        Id: item_atual ? item_atual.Id : 0,
        Atributo: item.Atributo,
        ValorAtributo: item_atual
          ? item_atual.ValorAtributo
          : { Id: null },
        Valor: item_atual ? item_atual.Valor : item.Atributo.ValorPadrao,
        Valores: item.Valores,
        Grupo: item.Grupo,
      };
      return novo_item;
    })
    updateAtributos(valores, setValues);
  }

  useEffect(() => {
    CamposVisibilidadePrivada();
    CamposEmailsObrigatorios();
    SetAtributosService();
  }, []);

  const options_toast = {
    onClose: (props) => setErros(null),
  };

  useEffect(() => {
    erros && toast.error(`Erro : ${erros}`, options_toast);
  }, [erros]);


  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          AdicionarCliente(values);
        }}
      >
        {() => (
          <>
            <Form>
              <ClienteContext.Provider
                value={{
                  AdicionarCliente,
                  handleLoad,
                  constantes,
                  validacoes,
                  modalValidacoes,
                  somenteLeitura,
                  openModal,
                  solicitarCliente,
                  disabled,
                  somenteLeitura,
                  menu,
                  loading,
                  emailsObrigatorios,
                  camposDesabilitados,
                  setLoading,
                  dadosDetalhesClientes,
                  detalhesClientes,
                  camposPrivados,
                  setDetalhesClientes,
                  abrirMenu,
                  fecharMenu,
                  setDisabled,
                  setOpenModal,
                  setModalValidacoes,
                  setSolicitaCliente,
                  abrirModalSolicitar,
                  confirmarSolicitacao,
                  cancelarSolicitacao,
                  fecharModalValidacoes,
                  tabValue,
                  loadingError,
                  configAtributosCliente,
                  setTab,
                  validacaoInputsNumber,
                  addEmailDepartamento,
                  removerEmailDepartamento,
                  updateClasseEntidade,
                  updateAtributos,
                  setAtributos
                }}
              >
                {children}
              </ClienteContext.Provider>
            </Form>
          </>
        )}

      </Formik>
    </>
  );
};

export default ClientesProvider;
