
import React, { useContext, useEffect, useState } from "react";
import ModalComposicaoProdutoControl from "../ui_comp/Modals/ModalComposicaoProduto";
import { Box, InputLabel, TextField } from "@mui/material";
import { Button, Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import SearchIcon from "@mui/icons-material/Search";
import { AssistenciasContext } from "../../contexts/assistencias-context";
import ProdutoService from "../../services/service.produto";
import { toast } from "react-toastify";
import NewComposicaoProdutoProblemaControl from "../composicao_comp/composicao-ProdutoProblema";
import "../../styles/botoesBuscar.css"
export default function CampoSearchlistProdutoProblema() {
  const {
    constantes,
    somenteLeitura,
    modalSelecionarProblema,
    fecharModalSelecionarProblema,
    abrirModalSelecionarProblema,
    updateProdutoProblema,
    configPermissoesAssistencia,
  } = useContext(AssistenciasContext);

  const formik = useFormikContext();
  const { values, setFieldValue, handleChange } = formik;
  const [composicaoId, setComposicaoId] = useState();

  useEffect(() => {
    if (values.Assistencia.ProdutoProblema?.Id) {
      GetComposicao(
        values.Assistencia.ProdutoVendido?.Id,
        values.Assistencia.ProdutoProblema?.Id
      );
    }
  }, [values.Assistencia?.ProdutoProblema?.Id]);

  async function GetComposicao(id, idBusca) {
    try {
      let buscaId = Number(idBusca);

      const response = await ProdutoService.getComposicao(id)  //PedidoService.getComposicaoItem(id);
      const filtro = response.data.value.filter(
        (item) => buscaId === item.ProdutoId
      );
      if (filtro.length > 0) {
        setComposicaoId(filtro[0].ProdutoId);

      } else {
        setComposicaoId(null);

      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);

    }
  }

  async function GetProduto(id, idBusca) {

    if ((configPermissoesAssistencia.ApenasCompProdProblema) && (id != idBusca)) {
      await ProdutoService.getProdutoComposicaoAssistencia(id, idBusca)
        .then((response) => {
          const produto = {
            Id: response.data?.ProdutoId,
            Nome: response.data?.ProdutoNomeTraduzido,
            FormulaParaQuantidadeId: response.data?.FormulaParaQuantidadeId
          };

          updateProdutoProblema(produto, setFieldValue, values);
        })
        .catch((error) => {
          console.log(error);
          toast.warn("É permitido selecionar apenas o item ou seus componentes!");
          toast.error(error.message);
        });
    } else {
      await ProdutoService.get(idBusca)
        .then((response) => {
          const produto = {
            Id: response.data?.Id,
            Nome: response.data?.Nome,
            FormulaParaQuantidadeId: response.data?.FormulaParaQuantidadeId
          };

          updateProdutoProblema(produto, setFieldValue, values);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.message);
        });
    }
  }

  const handleConfirmItemCompleto = () => {
    fecharModalSelecionarProblema();
    updateProdutoProblema(
      values.Assistencia?.ProdutoVendido,
      setFieldValue,
      values
    );
  };

  const handleItemSelected = (item) => {
    fecharModalSelecionarProblema();
    const data = {
      Id: item?.ProdutoId,
      Nome: item?.ProdutoNome,
      FormulaParaQuantidadeId: item.FormulaParaQuantidadeId
    };
    updateProdutoProblema(data, setFieldValue, values);
  };

  return (
    <>
      <ModalComposicaoProdutoControl
        modal={modalSelecionarProblema}
        closeModal={fecharModalSelecionarProblema}
        apenasVisualizar={false}
        titleModal={constantes.ProdutoProblema}
      >
        <NewComposicaoProdutoProblemaControl
          id={values.Assistencia?.ProdutoVendido?.Id}
          idBusca={values.Assistencia?.ProdutoProblema?.Id}
          idItem={values.Assistencia?.ItemPedido?.Id}
          idItemBusca={values.Assistencia?.ItemPedido?.Id}
          onItemSelected={handleItemSelected}
          confirmItem={handleConfirmItemCompleto}

        />
      </ModalComposicaoProdutoControl>

      <InputLabel sx={{ color: "#3d3d3d" }}>
        {constantes?.ProdutoProblema}
      </InputLabel>

      <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5 }}>
        <TextField
          name="Assistencia.ProdutoProblema.Id"
          defaultValue={values.Assistencia.ProdutoProblema?.Id}
          disabled={somenteLeitura}
          value={values.Assistencia.ProdutoProblema?.Id}
          onChange={(e) => {
            if (e.target.value === "") {
              setFieldValue('Assistencia.ProdutoProblema.Id', " ")
              setFieldValue('Assistencia.ProdutoProblema.Nome', " ")
            }
            handleChange(e)
          }}
          onBlur={() => {
            values.Assistencia.ProdutoProblema && values.Assistencia.ProdutoProblema.Id !== ''
              ?
              GetProduto(
                values?.Assistencia?.ProdutoVendido?.Id,
                values?.Assistencia?.ProdutoProblema?.Id
              )
              : updateProdutoProblema(null, setFieldValue, values);
          }}
          size="small"
          sx={{
            width: "5.9%",
            "@media (max-width: 1550px)": {
              width: "8.2%",
            },
            "@media (max-width: 1366px)": {
              width: "8.8%",
            },
            "@media (max-width: 1086px)": {
              width: "12.5%",
            },
            "@media (max-width: 850px)": {
              width: "17.8%",
            },

            "@media (max-width: 550px)": {
              width: "48%",
            },
            "@media (max-width: 375px)": {
              width: "62%",
            },
            "@media (max-width: 320px)": {
              width: "95%",
            },
          }}
        />
        <Button
          bsPrefix="LupabuscarComponenteProduto"
          onClick={() => {
            abrirModalSelecionarProblema();
          }}
          disabled={somenteLeitura}
        >
          <SearchIcon sx={{ margin: "0px", padding: "0px" }} />
        </Button>
        <div className="vr" />
        <Form.Control
          name="Assistencia.ProdutoProblema.Nome"
          className="me-auto"
          value={values.Assistencia.ProdutoProblema?.Nome}
          onChange={handleChange}
          type="text"
          // placeholder={placeholder}
          disabled={true}
        />
      </Box>
    </>
  );
}
