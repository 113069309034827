import { ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { Badge, Form } from "react-bootstrap";

const AtributoItemDecimalControl = (props) => {

    const { atributoItemEntrada, onItemChange, tipo, index, name } = props;


    const [valueSelected, setValueSelected] = useState(atributoItemEntrada?.Valor?.replace(",", "."));
    const [isValid, setIsValid] = useState(false);
    const [valueText, setValueText] = useState();

    useEffect(() => {
        const atributosaida = {
            Valor: valueText,
            Id: atributoItemEntrada?.Id,
            ValorAtributoId: null,
            Atributo: atributoItemEntrada?.Atributo,
            ValorAtributo: null
        }
        valueText && valueSelected && onItemChange && valueSelected && onItemChange(
            atributosaida,
            index,
            tipo
        );
    }, [valueSelected, valueText])


    /*  function getId(value) {
          return listValues && listValues.find((item, index) => {return item.value = value});
      }
      */

    return (
        <>
            <Form.Control
                {...props}
                type="number"
                onWheel={(e) => e.target.blur()}
                value={valueSelected}
                pattern="\d*"
                onChange={(e) => {
                    let newValue = e.target.value;
                    const casasDecimais = atributoItemEntrada.Atributo.CasasDecimais ?? 2;

                    if ((casasDecimais === 0) && !(/^\d*$/.test(newValue))) {
                        const numero = newValue.replace(".", "").replace(",", "");
                        setValueSelected(numero);
                        setValueText(numero);
                        return;
                    }

                    if ((casasDecimais > 0) && (!newValue.match(/^\d*\.?\d*$/))) {
                        return;
                    }
                    const parts = newValue.split('.');
                    if (parts.length > 1 && parts[1].length > casasDecimais) {
                        newValue = `${parts[0]}.${parts[1].slice(0, casasDecimais)}`;
                    }
                    const numero = newValue.replace(".", ",");
                    setValueSelected(newValue);
                    setValueText(numero);

                }}
                isValid={isValid}

            >

            </Form.Control>
            {name && <ErrorMessage name={name} >
                {(msg) => <Badge bg="danger">{msg}</Badge>
                }
            </ErrorMessage >}

        </>
    )
}

export default AtributoItemDecimalControl;