import { ErrorMessage, useField, useFormikContext } from "formik";
import { forwardRef, useEffect } from "react";
import { Form, Badge } from "react-bootstrap";
import InputMask from "react-input-mask";


const EditFoneControl = forwardRef((props, ref) => {
    const { name, placeholder, hideTitle, value, onChange, disabled, mask } = props;

    const { handleChange, validateOnMount } = useFormikContext();
    const [fieldId, metaId, handleId] = useField(name);

    return (
        <Form.Group>
            {!hideTitle && <Form.Label className="mb-0">{placeholder}</Form.Label>}

            <InputMask
                {...props}
                disabled={disabled}
                ref={ref}
                name={name}
                value={value ? value : metaId.value}
                onChange={(e) => {
                    handleChange(e)
                    onChange && onChange(e);
                }}
                onWheel={(e) => e.target.blur()}
                mask={mask}
            >
                {(inputProps) => <Form.Control {...inputProps} disabled={disabled} placeholder="" />}
            </InputMask>

            {validateOnMount && metaId.error && <Badge bg="danger">{metaId.error}</Badge>}

            {
                !validateOnMount && <ErrorMessage name={props.name}>
                    {(msg) => <Badge bg="danger">{msg}</Badge>}
                </ErrorMessage>
            }

        </Form.Group >
    );
});

export default EditFoneControl;

