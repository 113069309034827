import { Button, Form, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { useFormikContext } from "formik";
import { ItemOrcamentoVendaContext } from "../../contexts/itemorcamentovenda-context";
import ItemOrcamentoVendaCondicaoEspecialCtx from "./itemorcamentovenda-condicaoespecial";

const ItemOrcamentoVendaCondicaoEspecialItemCtx = (props) => {

    const {
        modalDescontoEspecial,
        setModalDescontoEspecial,
        vendedorSimulado,
        configs,
        orcamentoVenda,
        addCondicaoEspecial,
        constantes,
    } = useContext(ItemOrcamentoVendaContext);


    const {
        name,
        placeholder,
        namedefault,
        valuecalculated,
        hideTitle,
        disabled,
        lado,
        onBlur,
        value,
        heightLabel,
    } = props;
    const [fieldId, metaId, handleId] = useField(name ?? null);

    const hasValueCalculated = valuecalculated > 0 ? true : false;

    const { handleChange, errors, isValid } = useFormikContext();

    return (
        <>
            <Form.Label style={{ height: heightLabel ?? 40 }} htmlFor={placeholder}>
                {placeholder}
            </Form.Label>
            <InputGroup>
                <Form.Control
                    {...props}
                    style={{ maxWidth: 250 }}
                    id={placeholder}
                    name={name ?? null}
                    type="number"
                    value={value ? value : metaId.value}
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    onWheel={(e) => e.target.blur()}
                    aria-describedby={placeholder}
                    placeholder="0,00"
                    disabled={true}
                />
                <ItemOrcamentoVendaCondicaoEspecialCtx />
            </InputGroup>

            {hasValueCalculated && (
                <Form.Text id={placeholder} muted>
                    {constantes.Tabela} ({format_percent_2.format(valuecalculated ?? 0)}
                    )
                </Form.Text>
            )}

            <ErrorMessage name={name}>
                {(msg) => <Badge bg="danger">{msg}</Badge>}
            </ErrorMessage>
        </>
    );
};

export default ItemOrcamentoVendaCondicaoEspecialItemCtx;