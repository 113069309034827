import { Accordion, Badge, Card, Col, Container, Form, InputGroup, ListGroup, Row, Stack, Tab, Tabs } from "react-bootstrap";
import { GetConfiguracoes } from "../../config/localstorageConfig/localstorage";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import { useContext } from "react";

const UsuarioConfiguracoes = (props) => {
       
    const { constantes } = useContext(OrcamentoVendaContext)
    const {
        configuracoes,
    } = props;

    return (
        <>
            <Form>
                <hr />
                <Container>
                    <Badge>{constantes.InfoUsuario}</Badge>
                    <hr />
                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-sm">{constantes.Email}</InputGroup.Text>
                        <Form.Control
                            disabled={true}
                            type="email"
                            value={configuracoes?.Usuario?.Email}
                        />
                    </InputGroup>
                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-sm">{constantes.IdVendedor}</InputGroup.Text>
                        <Form.Control
                            disabled={true}
                            type="number"
                            value={configuracoes?.Usuario?.IdVendedor}
                        />
                    </InputGroup>
                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-sm">{constantes.IdSupervisor}</InputGroup.Text>
                        <Form.Control
                            disabled={true}
                            type="number"
                            value={configuracoes?.Usuario?.IdSupervisor}
                        />
                    </InputGroup>

                    <Container>
                        <Badge>{constantes.PerfildeAcesso}</Badge>
                        <hr />
                        <Form.Switch
                            disabled={true}
                            checked={configuracoes?.Usuario?.PerfilAcesso?.PermitirAlterarAcrescimoVenda}
                            label="Permitir Alterar Acrescimo Venda"
                        />
                        <Form.Switch
                            disabled={true}
                            valcheckede={configuracoes?.Usuario?.PerfilAcesso?.HalitarDescontoEspecial}
                            label="Permitir Alterar Desconto Especial"
                        />
                        <Form.Switch
                            disabled={true}
                            checked={configuracoes?.Usuario?.PerfilAcesso?.PermitirAlterarDescontosVenda}
                            label="Permitir Alterar Descontos Venda"
                        />
                        <Form.Switch
                            disabled={true}
                            checked={configuracoes?.Usuario?.PerfilAcesso?.PermitirAlterarFreteVenda}
                            label="Permitir Alterar Frete Venda"
                        />
                        <Form.Switch
                            disabled={true}
                            checked={configuracoes?.Usuario?.PerfilAcesso?.PermitirAlterarOperacaoVenda}
                            label="Permitir Alterar Operação Venda"
                        />
                        <Form.Switch
                            disabled={true}
                            checked={configuracoes?.Usuario?.PerfilAcesso?.PermitirSimulacaoWeb}
                            label="Permitir Simular acesso"
                        />

                        <Form.Switch
                            disabled={true}
                            checked={configuracoes?.Usuario?.PerfilAcesso?.VisualizarApenasOrcamentoRascunho}
                            label="Visualizar apenas orçamentos rascunho"
                        />
                    </Container>
                </Container>
                <hr />
                <Container >
                    <Badge>{constantes.ModoUso}</Badge>
                    <hr />
                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-sm">{constantes.ContratacaoRemetente}</InputGroup.Text>
                        <Form.Control
                            disabled={true}
                            value={configuracoes?.ConfiguracaoWeb?.ConfigWebJson?.destacarFreteContratacaoRemetente}
                        />
                    </InputGroup>
                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-sm">{constantes.ContratacaoDestinatario}</InputGroup.Text>
                        <Form.Control
                            disabled={true}
                            value={configuracoes?.ConfiguracaoWeb?.ConfigWebJson?.destacarFreteContratacaoDestinatario}
                        />
                    </InputGroup>
                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-sm">{constantes.ContratacaoTerceiros}</InputGroup.Text>
                        <Form.Control
                            disabled={true}
                            value={configuracoes?.ConfiguracaoWeb?.ConfigWebJson?.destacarFreteContratacaoTerceiros}
                        />
                    </InputGroup>
                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-sm">{constantes.ProprioDestinatario}</InputGroup.Text>
                        <Form.Control
                            disabled={true}
                            value={configuracoes?.ConfiguracaoWeb?.ConfigWebJson?.destacarFreteProprioDestinatario}
                        />
                    </InputGroup>
                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-sm">{constantes.ProprioRemetente}</InputGroup.Text>
                        <Form.Control
                            disabled={true}
                            value={configuracoes?.ConfiguracaoWeb?.ConfigWebJson?.destacarFreteProprioRemetente}
                        />
                    </InputGroup>
                </Container>
            </Form>
        </>
    )
}

export default UsuarioConfiguracoes;