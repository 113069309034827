import ItemOrcamentoVenda from "../../components/item_orcamento_venda";
import ItemOrcamentoVendaNew from "../../components/orcamento_venda/orcamentovenda-incluiritens.new";
import ItemOrcamentoVendaContextProvider, { ItemOrcamentoVendaContext } from "../../contexts/itemorcamentovenda-context";

const ExampleItemOrcamento = () => {


    const item_entrada = {
        "$id": 2,
        "TotalItem": 397.29,
        "IdentificacaoProjeto": "",
        "UnidadeMedida": "PC",
        "Id": 10,
        "PrecoUnitario": 397.2918,
        "Item": 1,
        "PrecoTabela": 499,
        "Quantidade": 1,
        "CodProduto": 1400,
        "Referencia": "1400",
        "NomeProduto": "CADEIRA GAMER BEST-G500A",
        "Observacoes": "",
        "IndexItemFiname": 0,
        "TabelaPrecoId": 5,
        "PrecoTabelaComDesconto": 474.05,
        "FreteUnitario": 0,
        "FreteItem": 0,
        "FreteUnitarioCalculado": 0,
        "FreteItemCalculado": 0,
        "PrecoUnitarioComercial": 397.2918,
        "QuantidadeComercial": 1,
        "UnidadeMedidaComercial": "PC",
        "FatorConvUndCom": 1,
        "PercentualDesconto1Tabela": 5,
        "PercentualDesconto2Tabela": 0,
        "PercentualDesconto3Tabela": 0,
        "PercentualDesconto4Tabela": 0,
        "PercentualDesconto5Tabela": 0,
        "PercentualAcrescimoTabela": 0,
        "PercentualAcrescimoEspecialTabela": 0,
        "PercentualDesconto1Praticado": 0,
        "PercentualDesconto2Praticado": 0,
        "PercentualDesconto3Praticado": 0,
        "PercentualDesconto4Praticado": 0,
        "PercentualDesconto5Praticado": 0,
        "PercentualDescontoEspecialPraticado": 0,
        "PercentualAcrescimoPraticado": 0,
        "PercentualAcrescimoEspecialPraticado": 0,
        "DescontoAcimaAutorizado": 76.76,
        "Cubagem": 0.12173,
        "TabelaDescontoId": 0,
        "HashTributos": "64e2b9d53128369e23398e6087175645",
        "TributosCached": false,
        "IdClasseFiscalOperacao": 11,
        "Atributos": [],
        "Cfop": {
            "$id": 3,
            "TipoFaturamento": "Normal",
            "ReplicadorConfig": "True",
            "ClasseInterna": "",
            "Descricao": "VENDA MERCADORIA ADQUIRIDA OU REC. DE TERCEIROS (Simples Nacional)",
            "Complemento2": "",
            "Id": "5.102",
            "Complemento1": "",
            "DataHoraAlteracao": "2021-03-24T15:14:06.242",
            "Situacao": "Ativo",
            "IdRotinaContabil": 1,
            "TipoOperacao": {
                "$id": 4,
                "Id": 1,
                "Nome": "Venda Normal",
                "CodigoIntegracao": ""
            },
            "Configuracao": null
        },
        "Formula": null,
        "QuantidadeVolumes": 1,
        "QtdeMultiplaVenda": 0,
        "FormulaParaQuantidadeId": 0
    }

    const configs_entrada = {
        QuantidadeMaximaDescontos: 5,
        PermitirAlterarDescontosVenda: true,
        PermitirAlterarAcrescimoVenda: true,
        UsarAtributosVenda: true
    }

    const descontos_tabela_entrada = {
        PercentualDesconto1Tabela: 0,
        PercentualDesconto2Tabela: 0,
        PercentualDesconto3Tabela: 0,
        PercentualDesconto4Tabela: 0,
        PercentualDesconto5Tabela: 0,
        PercentualAcrescimoTabela: 0,
        PercentualAcrescimoEspecialTabela: 0,
    }


    const orcamento_entrada = {
        "$id": 1,
        "CondicaoPagamento": "A Prazo",
        "TipoOperacao": "Revenda",
        "ObservacaoVenda": "",
        "CpfCnpj": "993.912.529-15",
        "Id": 295,
        "ClienteRetira": false,
        "Situacao": "Rascunho",
        "ObservacaoInterna": "",
        "TipoFrete": "4",
        "DataValidade": "2023-07-02",
        "ObservacaoUsuario": "",
        "Telefone": "",
        "ObservacaoFiscal": "",
        "Distancia": 3485.9,
        "PercentualFrete": 20,
        "ValorFrete": 0,
        "ModoUsoFrete": "Não considerar",
        "ValorFreteCalculado": 16038.81,
        "FreteFixo": false,
        "VencimentoFixo": false,
        "PrazoMedio": 0,
        "Parcelas": 1,
        "CalcularDimensoesItens": true,
        "PercentualDesconto1Tabela": 0,
        "PercentualDesconto2Tabela": 0,
        "PercentualDesconto3Tabela": 0,
        "PercentualDesconto4Tabela": 0,
        "PercentualDesconto5Tabela": 0,
        "PercentualAcrescimoTabela": 0,
        "PercentualAcrescimoEspecialTabela": 0,
        "PercentualDesconto1Padrao": 0,
        "PercentualDesconto2Padrao": 0,
        "PercentualDesconto3Padrao": 0,
        "PercentualDesconto4Padrao": 0,
        "PercentualDesconto5Padrao": 0,
        "PercentualDescontoEspecialPadrao": 0,
        "PercentualAcrescimoPadrao": 0,
        "PercentualAcrescimoEspecialPadrao": 0,
        "PercentualDesconto1Praticado": 0,
        "PercentualDesconto2Praticado": 0,
        "PercentualDesconto3Praticado": 0,
        "PercentualDesconto4Praticado": 0,
        "PercentualDesconto5Praticado": 0,
        "PercentualDescontoEspecialPraticado": 0,
        "PercentualAcrescimoPraticado": 0,
        "PercentualAcrescimoEspecialPraticado": 0,
        "DataLimiteValidade": "1899-12-30",
        "Itens": [
            {
                "$id": 2,
                "TotalItem": 35547,
                "IdentificacaoProjeto": "",
                "UnidadeMedida": "PC",
                "Id": 353,
                "PrecoUnitario": 289,
                "Item": 1,
                "PrecoTabela": 289,
                "Quantidade": 123,
                "CodProduto": 1993,
                "Referencia": "4441",
                "NomeProduto": "FORNO ELET 44LT PRETO 127V",
                "Observacoes": "",
                "IndexItemFiname": 0,
                "TabelaPrecoId": 5,
                "PrecoTabelaComDesconto": 289,
                "FreteUnitario": 0,
                "FreteItem": 0,
                "FreteUnitarioCalculado": 130.3968,
                "FreteItemCalculado": 16038.81,
                "PrecoUnitarioComercial": 289,
                "QuantidadeComercial": 123,
                "UnidadeMedidaComercial": "PC",
                "FatorConvUndCom": 1,
                "PercentualDesconto1Tabela": 0,
                "PercentualDesconto2Tabela": 0,
                "PercentualDesconto3Tabela": 0,
                "PercentualDesconto4Tabela": 0,
                "PercentualDesconto5Tabela": 0,
                "PercentualAcrescimoTabela": 0,
                "PercentualAcrescimoEspecialTabela": 0,
                "PercentualDesconto1Praticado": 0,
                "PercentualDesconto2Praticado": 0,
                "PercentualDesconto3Praticado": 0,
                "PercentualDesconto4Praticado": 0,
                "PercentualDesconto5Praticado": 0,
                "PercentualDescontoEspecialPraticado": 0,
                "PercentualAcrescimoPraticado": 0,
                "PercentualAcrescimoEspecialPraticado": 0,
                "DescontoAcimaAutorizado": 0,
                "Cubagem": 14.29137,
                "TabelaDescontoId": 0,
                "HashTributos": "",
                "TributosCached": false,
                "IdClasseFiscalOperacao": 0,
                "Atributos": [
                    {
                        "$id": 3,
                        "Valor": "CHAPA DE 0,95 MM",
                        "Id": 487,
                        "ValorAtributoId": "",
                        "Atributo": {
                            "$id": 4,
                            "Id": 9,
                            "Obrigatorio": false,
                            "Nome": "Especificacao_Material",
                            "Tipo": "Texto",
                            "Grupo": ""
                        },
                        "ValorAtributo": {
                            "$id": 5,
                            "Id": 263
                        }
                    },
                    {
                        "$id": 6,
                        "Valor": "1,46",
                        "Id": 488,
                        "ValorAtributoId": "",
                        "Atributo": {
                            "$id": 7,
                            "Id": 1,
                            "Obrigatorio": true,
                            "Nome": "Medida_A",
                            "Tipo": "Decimal",
                            "Grupo": ""
                        },
                        "ValorAtributo": {
                            "$id": 8,
                            "Id": 34
                        }
                    },
                    {
                        "$id": 9,
                        "Valor": "5",
                        "Id": 489,
                        "ValorAtributoId": "",
                        "Atributo": {
                            "$id": 10,
                            "Id": 6,
                            "Obrigatorio": true,
                            "Nome": "Quantidade_Inteira",
                            "Tipo": "Inteiro",
                            "Grupo": ""
                        },
                        "ValorAtributo": null
                    },
                    {
                        "$id": 11,
                        "Valor": "6",
                        "Id": 490,
                        "ValorAtributoId": "",
                        "Atributo": {
                            "$id": 12,
                            "Id": 2,
                            "Obrigatorio": true,
                            "Nome": "Medida_B",
                            "Tipo": "Decimal",
                            "Grupo": ""
                        },
                        "ValorAtributo": null
                    }
                ],
                "Cfop": {
                    "$id": 13,
                    "TipoFaturamento": "Normal",
                    "ReplicadorConfig": "True",
                    "ClasseInterna": "",
                    "Descricao": "VENDA MERCADORIA ADQUIRIDA OU REC. DE TERCEIROS",
                    "Complemento2": "",
                    "Id": "5.102.02",
                    "Complemento1": "Venda",
                    "DataHoraAlteracao": "2021-06-04T11:04:55.473",
                    "Situacao": "Ativo",
                    "IdRotinaContabil": 1,
                    "TipoOperacao": {
                        "$id": 14,
                        "Id": 1,
                        "Nome": "Venda Normal",
                        "CodigoIntegracao": ""
                    },
                    "Configuracao": null
                },
                "Formula": {
                    "$id": 15,
                    "Id": 9
                },
                "QuantidadeVolumes": 123,
                "QtdeMultiplaVenda": 3,
                "FormulaParaQuantidadeId": 9
            },
            {
                "$id": 16,
                "TotalItem": 35547,
                "IdentificacaoProjeto": "",
                "UnidadeMedida": "PC",
                "Id": 354,
                "PrecoUnitario": 289,
                "Item": 2,
                "PrecoTabela": 289,
                "Quantidade": 123,
                "CodProduto": 1993,
                "Referencia": "4441",
                "NomeProduto": "FORNO ELET 44LT PRETO 127V",
                "Observacoes": "",
                "IndexItemFiname": 0,
                "TabelaPrecoId": 5,
                "PrecoTabelaComDesconto": 289,
                "FreteUnitario": 0,
                "FreteItem": 0,
                "FreteUnitarioCalculado": 65.1984,
                "FreteItemCalculado": 8019.41,
                "PrecoUnitarioComercial": 289,
                "QuantidadeComercial": 123,
                "UnidadeMedidaComercial": "PC",
                "FatorConvUndCom": 1,
                "PercentualDesconto1Tabela": 0,
                "PercentualDesconto2Tabela": 0,
                "PercentualDesconto3Tabela": 0,
                "PercentualDesconto4Tabela": 0,
                "PercentualDesconto5Tabela": 0,
                "PercentualAcrescimoTabela": 0,
                "PercentualAcrescimoEspecialTabela": 0,
                "PercentualDesconto1Praticado": 0,
                "PercentualDesconto2Praticado": 0,
                "PercentualDesconto3Praticado": 0,
                "PercentualDesconto4Praticado": 0,
                "PercentualDesconto5Praticado": 0,
                "PercentualDescontoEspecialPraticado": 0,
                "PercentualAcrescimoPraticado": 0,
                "PercentualAcrescimoEspecialPraticado": 0,
                "DescontoAcimaAutorizado": 0,
                "Cubagem": 14.29137,
                "TabelaDescontoId": 0,
                "HashTributos": "",
                "TributosCached": false,
                "IdClasseFiscalOperacao": 0,
                "Atributos": [
                    {
                        "$id": 17,
                        "Valor": "POLIP. AMARELO",
                        "Id": 491,
                        "ValorAtributoId": "",
                        "Atributo": {
                            "$id": 18,
                            "Id": 9,
                            "Obrigatorio": false,
                            "Nome": "Especificacao_Material",
                            "Tipo": "Texto",
                            "Grupo": ""
                        },
                        "ValorAtributo": {
                            "$id": 19,
                            "Id": 265
                        }
                    },
                    {
                        "$id": 20,
                        "Valor": "2,96",
                        "Id": 492,
                        "ValorAtributoId": "",
                        "Atributo": {
                            "$id": 21,
                            "Id": 1,
                            "Obrigatorio": true,
                            "Nome": "Medida_A",
                            "Tipo": "Decimal",
                            "Grupo": ""
                        },
                        "ValorAtributo": {
                            "$id": 22,
                            "Id": 67
                        }
                    },
                    {
                        "$id": 23,
                        "Valor": "7",
                        "Id": 493,
                        "ValorAtributoId": "",
                        "Atributo": {
                            "$id": 24,
                            "Id": 2,
                            "Obrigatorio": true,
                            "Nome": "Medida_B",
                            "Tipo": "Decimal",
                            "Grupo": ""
                        },
                        "ValorAtributo": null
                    },
                    {
                        "$id": 25,
                        "Valor": "9",
                        "Id": 494,
                        "ValorAtributoId": "",
                        "Atributo": {
                            "$id": 26,
                            "Id": 6,
                            "Obrigatorio": true,
                            "Nome": "Quantidade_Inteira",
                            "Tipo": "Inteiro",
                            "Grupo": ""
                        },
                        "ValorAtributo": null
                    }
                ],
                "Cfop": {
                    "$id": 27,
                    "TipoFaturamento": "Normal",
                    "ReplicadorConfig": "True",
                    "ClasseInterna": "",
                    "Descricao": "VENDA MERCADORIA ADQUIRIDA OU REC. DE TERCEIROS",
                    "Complemento2": "",
                    "Id": "5.102.02",
                    "Complemento1": "Venda",
                    "DataHoraAlteracao": "2021-06-04T11:04:55.473",
                    "Situacao": "Ativo",
                    "IdRotinaContabil": 1,
                    "TipoOperacao": {
                        "$id": 28,
                        "Id": 1,
                        "Nome": "Venda Normal",
                        "CodigoIntegracao": ""
                    },
                    "Configuracao": null
                },
                "Formula": {
                    "$id": 29,
                    "Id": 9
                },
                "QuantidadeVolumes": 123,
                "QtdeMultiplaVenda": 3,
                "FormulaParaQuantidadeId": 9
            }
        ],
        "Vencimentos": [
            {
                "Dias": 0,
                "Data": "2023-06-30",
                "Valor": 80194.04,
                "Percentual": 100,
                "PercentualFixado": false,
                "DataFixada": false,
                "ValorFixado": false,
                "PercentualDigitado": 0,
                "ValorDigitado": 0
            }
        ],
        "EnderecoEntrega": {
            "$id": 31,
            "Observacao": "OBS",
            "Bairro": "teste",
            "Email": "lll@tmp.com.br",
            "Cep": "92035-000",
            "CpfCnpj": "001.661.780-30",
            "Numero": "123",
            "NomeRecebedor": "Luiz",
            "InscricaoEstadual": "123456",
            "Endereco": "Avenida Nazario",
            "Telefone": "5199999999",
            "Municipio": {
                "$id": 32,
                "Id": 1,
                "Nome": "Acrelândia",
                "EstadoSigla": "",
                "Estado": null
            }
        },
        "Cliente": {
            "$id": 33,
            "Id": 14608,
            "Nome": "ADRIANO FARIA",
            "IdTabelaDesconto": 0,
            "IdTabelaComissao": 0
        },
        "FormaPagamento": {
            "$id": 34,
            "Id": 3,
            "Nome": "Pagamento antecipado",
            "ExigirItensFiname": false,
            "Condicao": "A Prazo",
            "BloqueadoAlterarVencimento": false,
            "BloqueioAlterarCondicaoPagamento": false,
            "Vencimentos": []
        },
        "TabelaDesconto": {
            "$id": 35,
            "Id": 4,
            "Nome": "Padrão - 2019"
        },
        "Atributos": [
            {
                "$id": 36,
                "Valor": " 3 - Matrizes - Produção de ovos",
                "Id": 234,
                "ValorAtributoId": "1310",
                "Atributo": {
                    "$id": 37,
                    "Id": 22,
                    "Obrigatorio": true,
                    "Nome": "",
                    "Tipo": "",
                    "Grupo": ""
                },
                "ValorAtributo": {
                    "Id": 1310
                }
            },
            {
                "$id": 39,
                "Valor": "ACREAVES ALIMENTOS LTDA",
                "Id": 235,
                "ValorAtributoId": "1961",
                "Atributo": {
                    "$id": 40,
                    "Id": 23,
                    "Obrigatorio": true,
                    "Nome": "",
                    "Tipo": "",
                    "Grupo": ""
                },
                "ValorAtributo": {
                    "Id": 1961
                }
            }
        ],
        "Municipio": {
            "$id": 42,
            "Id": 4570,
            "Nome": "Maravilha",
            "EstadoSigla": "SC",
            "Estado": {
                "$id": 43,
                "CodigoFederal": 42,
                "DifalNaoContrib": "Única (por fora)",
                "ReducaoBaseCalculoIcms": 0,
                "PercentualBcIcmsPart": 0,
                "Id": 24,
                "Sigla": "SC",
                "Nome": "Santa Catarina",
                "AliquotaIcms": 17,
                "AliquotaCreditoIcmsSimples": 0,
                "BaseCalculoDifalNaoContrib": "mbcUnicaPorFora",
                "Pais": {
                    "$id": 44,
                    "Id": 1058,
                    "Sigla": "BRA",
                    "Nome": "Brasil"
                }
            }
        },
        "TabelaPreco": {
            "$id": 45,
            "Id": 5,
            "Nome": "Padrão",
            "Moeda": "BRL"
        },
        "ItensFiname": [],
        "Totais": {
            "$id": 46,
            "ValorFCPRetSt": 3554.7,
            "PesoLiquidoTotal": 1599,
            "TotalFrete": 0,
            "TotalIpi": 5545.34,
            "TotalIcms": 2843.76,
            "TotalCofins": 5187.02,
            "TotalPis": 1126.12,
            "TotalSimples": 0,
            "TotalIss": 0,
            "TotalIva": 0,
            "PesoBrutoTotal": 1722,
            "BaseCalculoComissao": 71094,
            "DescontoAcimaAuto": 0,
            "PercentualComissao": 0,
            "TotalItens": 71094,
            "ValorComissao": 0,
            "Cubagem": 28.5827399999999,
            "QuantidadeVolumes": 246,
            "ValorTotal": 80194.04,
            "ValorSt": 0,
            "PercentualDescontoAcima": 0,
            "ValorDif": 0,
            "TotalPrecoTabela": 71094
        },
        "Vendedor": {
            "$id": 47,
            "Id": 1,
            "Nome": "BEST COMERCIAL IMPORTADORA LTDA",
            "IdTabelaDesconto": 0,
            "IdTabelaComissao": 2,
            "IdSupervisor": 0,
            "IdGerente": 0
        },
        "TabelaFrete": {
            "$id": 48,
            "Id": 8,
            "Nome": "FRETE PADRÃO 2022",
            "CoefDensidade": 200
        },
        "Configs": {
            "$id": 49,
            "DesabilitarDestacarFrete": false,
            "QuantidadeDiasValidade": 2,
            "DiasLimiteValidade": 10,
            "QuantidadeMaximaParcelas": 120,
            "QuantidadeMaximaDescontos": 4,
            "PermitirAlterarDescontosVenda": true,
            "PermitirAlterarAcrescimoVenda": true,
            "HabilitarDescontoEspecial": true,
            "PermitirAlterarFreteVenda": false,
            "DescontoMaximoEspecial": 5,
            "VisualizarApenasOrcamentoRascunho": false,
            "PermitirAlterarOperacaoVenda": true,
            "UsarAtributosVenda": false
        },
        "Cfop": {
            "$id": 50,
            "TipoFaturamento": "Normal",
            "ReplicadorConfig": "True",
            "ClasseInterna": "",
            "Descricao": "VENDA MERCADORIA ADQUIRIDA OU REC. DE TERCEIROS (Simples Nacional)",
            "Complemento2": "",
            "Id": "5.102",
            "Complemento1": "",
            "DataHoraAlteracao": "2021-03-24T15:14:06.242",
            "Situacao": "Ativo",
            "IdRotinaContabil": 1,
            "TipoOperacao": {
                "$id": 51,
                "Id": 1,
                "Nome": "Venda Normal",
                "CodigoIntegracao": ""
            },
            "Configuracao": null
        },
        "CategoriaVenda": {
            "$id": 52,
            "Id": 1,
            "Nome": "VISTA"
        },
        "Recalculo": null,
        "DataPrevisaoFaturamento": "2023-08-29",
        "ExigirItensFiname": false,
        "PercentualDesconto1": 0,
        "PercentualDesconto2": 0,
        "PercentualDesconto3": 0,
        "PercentualDesconto4": 0,
        "PercentualDesconto5": 0,
        "PercentualAcrescimo": 0,
        "PercentualAcrescimoEspecial": 0,
        "PercentualDescontoEspecial": 0
    }

    const somenteLeitura = false;
    const itens = [];
    const itemSelected = item_entrada;
    const indexItemSelected = 0;
    const itensFiname = [];
    const tabelaPrecoId = item_entrada.TabelaPrecoId;
    const OrcamentoVendaCalculo = orcamento_entrada;
    const descontosTabela = descontos_tabela_entrada;
    const cfop = item_entrada.Cfop;
    const configs = configs_entrada;
    const continueAddItem = false;


    return (
        <>
            {/* <ItemOrcamentoVendaNew
                somenteLeitura={somenteLeitura}
                maioritem={itens?.length}
                selectedItem={itemSelected}
                indexselectedItem={indexItemSelected}
                ItensFiname={itensFiname}
                TabelaPrecoId={tabelaPrecoId}
                orcamentovenda={OrcamentoVendaCalculo}
                descontosTabela={descontosTabela}
                cfop={cfop}
                configs={configs}
                continuar={continueAddItem}
                onAddItem={(item) => {
                    console.log(item);
                }}
                onEditItem={(item) => {
                    console.log(item);
                }}
            /> */}

            <ItemOrcamentoVendaContextProvider>
                <ItemOrcamentoVenda />
            </ItemOrcamentoVendaContextProvider>
        </>
        //     <ItemOrcamentoVenda />
        // </>
    )

}

export default ExampleItemOrcamento;

