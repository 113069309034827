import { useContext } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import { Badge, Col, Row } from "react-bootstrap";
import NewOperacaoVendaControl from "../controls_comp/control-operacaoVenda.jsx";
import NewSearchListTabelaPrecos from "../searchs_comp/search-List.Tabela.Precos.jsx";
import NewSearchListTabelaDescontos from "../searchs_comp/search-List.Tabela.Descontos.jsx";
import NewSearchListFormasPagamento from "../searchs_comp/search-List.Formas.Pagamento.jsx";
import ControlSelect from "../controls_comp/control-selectcontrol.jsx";
import NewCheckBoxControl from "../controls_comp/control-checkbox.jsx";
import NewButtonControl from "../controls_comp/control-Button.jsx";
import NewModalVencimentosControl from "../ui_comp/Modals/ModalVencimentosControl.jsx";
import { ErrorMessage, useFormikContext } from "formik";
import EditControl from "../controls_comp/control-edit.jsx";

const OrcamentoVendaCondicoesCtx = () => {

    const {
        orcamentoVenda,
        somenteLeitura,
        constantes,
        handleLoad,
        handleError,
        updateTabelaPreco,
        updateFormaPagamento,
        updateTabelaDesconto,
        modalVencimentos,
        setModalVencimentos,
        updateVencimentos,
        parcelas,
        prazoMedio,
        exigirItensFiname,
        diferencaVencimentos,
        setDiferencaVencimentos
    } = useContext(OrcamentoVendaContext);

    const {
        values,
        setFieldValue,
        handleSubmit,
        isSubmitting
    } = useFormikContext();

    const itensCondicaoPagamento = [
        { Id: 1, Valor: "À Vista" },
        { Id: 2, Valor: "A Prazo" },
        { Id: 3, Valor: "Sem Pagamento" },
    ];

    return (
        <>
            <Row className="d-flex align-items-center">
                <Col xs={12} md={6} className="mb-3">
                <NewOperacaoVendaControl
                    constantes={constantes}
                    disabled={somenteLeitura}
                />
                </Col>

                <Col xs={12} md={6} className="mb-3">
                    <NewSearchListTabelaPrecos
                        cliente={values?.OrcamentoVenda?.Cliente?.Id}
                        tipofrete={values?.OrcamentoVenda?.TipoFrete}
                        disabled={
                            somenteLeitura ||
                            values?.OrcamentoVenda?.Itens?.length > 0
                        }
                        nameId="OrcamentoVenda.TabelaPreco.Id"
                        nameDesc="OrcamentoVenda.TabelaPreco.Nome"
                        placeholder={constantes.TabelaPreco}
                        onLoad={handleLoad}
                        onError={handleError}
                        onItemSelected={(item) => {
                            const tabelaPreco = {
                                Id: item.Id,
                                Nome: item.Nome,
                                Moeda: item.Moeda.Codigo,
                                IdTabelaComissao: item.IdTabelaComissao
                            };
                            updateTabelaPreco(tabelaPreco, setFieldValue, values);
                        }}
                        onItemClear={() => {
                            updateTabelaPreco(null, setFieldValue, values);
                        }}
                    />
                </Col>
                <Col xs={12} md={6} className="mb-3">
                    <NewSearchListTabelaDescontos
                        disabled={
                            somenteLeitura ||
                            values?.OrcamentoVenda?.Itens?.length > 0
                        }
                        nameId="OrcamentoVenda.TabelaDesconto.Id"
                        nameDesc="OrcamentoVenda.TabelaDesconto.Nome"
                        placeholder={constantes?.TabelaDesconto}
                        onLoad={handleLoad}
                        onError={handleError}
                        onItemSelected={(item) => {
                            updateTabelaDesconto(item, setFieldValue, values);
                        }}
                        onItemClear={() => {
                            updateTabelaDesconto(null, setFieldValue, values);
                        }}
                    />
                    <ErrorMessage name={"OrcamentoVenda.TabelaDesconto"}>
                        {(msg) => (
                            <Badge bg="danger">{msg}</Badge>
                        )}
                    </ErrorMessage>
                </Col>
                <Col xs={12} md={6} className="mb-3">
                    <NewSearchListFormasPagamento
                        disabled={somenteLeitura}
                        nameId="OrcamentoVenda.FormaPagamento.Id"
                        nameDesc="OrcamentoVenda.FormaPagamento.Nome"
                        placeholder={constantes?.FormaPagamento}
                        onLoad={handleLoad}
                        onError={handleError}
                        onItemSelected={(item) => {
                            updateFormaPagamento(item, setFieldValue, values);
                        }}
                    />
                </Col>
                <Col xs={12} md={6} className="mb-3">
                    <ControlSelect
                        disabled={
                            somenteLeitura ||
                            values?.OrcamentoVenda?.FormaPagamento
                                ?.BloqueioAlterarCondicaoPagamento
                        }
                        name="OrcamentoVenda.CondicaoPagamento"
                        placeholder={constantes.CondicaodePagamento}
                        itens={itensCondicaoPagamento}
                        nulloption
                    />
                </Col>

                <Col
                    xs={12}
                    md={6}
                    className="d-flex align-items-center mb-0"
                >
                    <NewCheckBoxControl
                        checked={exigirItensFiname}
                        name="OrcamentoVenda.ExigirItensFiname"
                        placeholder={constantes.ExigirItensFiname}
                        disabled
                    />
                </Col>
                <Col xs={12} md={6} className="mb-3">
                    <EditControl
                        value={orcamentoVenda.Parcelas}
                        name="OrcamentoVenda.Parcelas"
                        placeholder={constantes.Parcelas}
                        type="number"
                        disabled
                    />
                </Col>
                <Col xs={12} md={6} className="mb-3">
                    <EditControl
                        value={orcamentoVenda.PrazoMedio}
                        name="OrcamentoVenda.PrazoMedio"
                        placeholder={constantes.PrazoMedio}
                        type="number"
                        disabled
                    />
                </Col>
                <Col xs={12} md={6} className="mb-3">
                    {values?.OrcamentoVenda?.DataPrevisaoFaturamento &&
                        <NewButtonControl
                            // somenteLeitura={somenteLeitura}
                            placeholder={constantes.DefinirVencimentos}
                            variant="secondary"
                            onClick={() => {
                                setDiferencaVencimentos(false);
                                setModalVencimentos(true);
                            }}
                        />
                    }
                    {!values?.OrcamentoVenda?.DataPrevisaoFaturamento &&
                        <Badge bg="danger">{constantes.DataPrevisaoFaturamentoNula}</Badge>
                    }

                </Col>
            </Row>

            <NewModalVencimentosControl
                disabled={somenteLeitura}
                DataBaseVencimentos={
                    values?.OrcamentoVenda?.DataPrevisaoFaturamento
                }
                toggle={() => {
                    setModalVencimentos(false);
                    return false;
                }}
                modal={modalVencimentos}
                somenteLeitura={somenteLeitura}
                BloqueadoAlterarVencimento={
                    values?.OrcamentoVenda?.FormaPagamento
                        ?.BloqueadoAlterarVencimento
                }
                eventoGravar={diferencaVencimentos ? handleSubmit : null}
                MaximoDiasVencimento={
                    values?.Configs?.MaximoDiasVencimento
                }
                MinimoDiasVencimento={
                    values?.Configs?.MinimoDiasVencimento
                }
                valortotal={values?.OrcamentoVenda?.Totais?.ValorTotal}
                vencimentos={values?.OrcamentoVenda?.Vencimentos}
                vencimentofixo={values?.OrcamentoVenda?.VencimentoFixo}
                onVencimentos={(vencimentos, vencimentofixo) => {
                    updateVencimentos(vencimentos, vencimentofixo, setFieldValue, values);
                    setModalVencimentos(false);

                    //setFieldValue("OrcamentoVenda.Parcelas", vencimentos.length);
                    //setFieldValue("OrcamentoVenda.PrazoMedio", vencimentos.length);
                }}
            />

        </>
    )
}

export default OrcamentoVendaCondicoesCtx;

