
import React, { useEffect, useState } from "react";
import OrcamentoVendaService from "../../../../services/service.orcamentovenda";
import {
  Alert,
} from "react-bootstrap";
import T from "../../../../common/traducao";
import { Box, Divider, Grid, LinearProgress, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PrintIcon from "@mui/icons-material/Print";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function NewImprimirOrcamento({ id }) {
  const constantes = T();

  const [listaRelatoriosComDesconto, setListaRelatoriosComDesconto] = useState(
    []
  );
  const [listaRelatoriosSemDesconto, setListaRelatoriosSemDesconto] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [erroImpressao, setErroImpressao] = useState();
  const [openCollapseComDescontos, setOpenCollapseComDescontos] = React.useState(true);
  const [openCollapseSemDescontos, setOpenCollapseSemDescontos] = React.useState(true);
  const navigate = useNavigate()
  const handleCollapseComDescontos = () => {
    setOpenCollapseComDescontos(!openCollapseComDescontos)
  }

  const handleCollapseSemDescontos = () => {
    setOpenCollapseSemDescontos(!openCollapseSemDescontos)
  }
  
  async function GetRelatorios() {
    await OrcamentoVendaService.GetListaRelatoriosCustomizados(id)
      .then((response) => {
        setListaRelatoriosComDesconto(response.data.ImpressoesComDesconto);
        setListaRelatoriosSemDesconto(response.data.ImpressoesSemDesconto);
      })
      .catch((error) => {
        const _error =
          error.code === "ERR_NETWORK"
            ? "Server OffLine"
            : error.response && error.response.data && error.response.data.error
            ? error.response.data.error.message
            : error.message;
        setErroImpressao(_error);
        toast.error(error.message)
        navigate("/orcamentosvenda")
      })
      .finally(() => {});
  }

  async function ImprimirRelatorio(link) {
    //window.open(`${process.env.REACT_APP_BASE_URL}${link}`);

    setLoading(true);
    await OrcamentoVendaService.ImprimirRelatorio(link)
      .then((response) => {
        try {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
          setLoading(false);
      } catch (error) {
          navigate("/orcamentosvenda")
          toast.error("Ocorreu um erro ao tentar abrir o arquivo. Por favor, tente novamente mais tarde.");
      }
      })
      .catch((error) => {
        const _error =
          error.code === "ERR_NETWORK"
            ? "Server OffLine"
            : error.response && error.response.data && error.response.data.error
            ? error.response.data.error.message
            : error.message;
        setErroImpressao(error);
        toast.error(error.message)
        navigate("/orcamentosvenda")
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    GetRelatorios();
  }, []);

  function ListagemRelatorios() {
    const alertClicked = (link) => {
      ImprimirRelatorio(link);
    };

    return (
      <>
        <Grid container display={"flex"} flexDirection={"column"} gap={1}>
          <Grid item sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <PrintIcon  sx={{color: "#5e5e5e"}} />
            <Typography
              sx={{ color: "#5e5e5e", fontSize: "22px" }}
            >{`${constantes.ImpressaoOrcamento} ${id}`}</Typography>
          </Grid>
          <Divider sx={{color: "black", background:"black"}} />
          <Grid item xl={15} sx={{ display: "flex", flexDirection: "column" }}>
            <List
              sx={{
                width: "100%",
                maxWidth: "100%",
                bgcolor: "background.paper",
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton onClick={handleCollapseComDescontos}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText sx={{color: "#595959", fontWeight: "bold"}} primary={constantes.ComDesconto}/>
                {openCollapseComDescontos ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openCollapseComDescontos} timeout="auto" unmountOnExit>
                {listaRelatoriosComDesconto &&
                  listaRelatoriosComDesconto.map((item, index) => {
                    return (
                      <>
                        <ListItemButton
                          disabled={loading}
                          key={index}
                          sx={{ pl: 4 }}
                          onClick={() => alertClicked(item.LinkDownload)}
                        >
                          <ListItemIcon>
                            <TextSnippetIcon />
                          </ListItemIcon>
                          <ListItemText primary={item.Descricao} />
                        </ListItemButton>
                      </>
                    );
                  })}
              </Collapse>

              <ListItemButton onClick={handleCollapseSemDescontos}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText sx={{color: "#595959", fontWeight: "bold"}} primary={constantes.SemDesconto}/>
                {openCollapseSemDescontos ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={openCollapseSemDescontos} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {listaRelatoriosSemDesconto &&
                    listaRelatoriosSemDesconto.map((item, index) => {
                      return (
                        <>
                          <ListItemButton
                            disabled={loading}
                            key={index}
                            sx={{ pl: 4 }}
                            onClick={() => alertClicked(item.LinkDownload)}
                          >
                            <ListItemIcon>
                              <TextSnippetIcon />
                            </ListItemIcon>
                            <ListItemText primary={item.Descricao} />
                          </ListItemButton>
                        </>
                      );
                    })}
                </List>
              </Collapse>
            </List>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      {ListagemRelatorios()}

      {loading && (
        <Box sx={{display: "flex", flexDirection:"column",gap: 1, fontSize: "15px",}}>
          <Typography sx={{fontWeight: "bold", color: "#707070"}}>{constantes.MsgAguardeImprimirOrcamento}</Typography>
          <LinearProgress color="inherit"  />
        </Box>
      )}

      {erroImpressao && <Alert variant="danger">{erroImpressao}</Alert>}
    </>
  );
}

