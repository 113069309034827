import { useState } from "react";
import ProdutoService from "../../services/service.produto";
import NewSearchBarControlFormik from "./search-Bar.Control.Formik";


const SearchListProdutosFiname = (props) => {

    const [produtos, setProdutos] = useState();
    const [pages, setPages] = useState();
    const [filter, setFilter] = useState();

    const columns = [
        {
            field: 'Id',
            headerName: 'Codigo',
            width: 90,
            key: true
        },
        {
            field: 'Referencia',
            headerName: 'Referencia',
            tipo: 'text'
        },
        {
            field: 'Nome',
            headerName: 'Nome',
            width: 150,
        },
        {
            field: 'IdUnidadeMedida',
            headerName: 'Unid',
            width: 70,
        },
        {
            field: 'PermissaoVenda',
            headerName: 'Perm. Venda',
            tipo: 'text'
        },
    ]

    const columnsFilter = [
        {
            field: 'Id',
            headerName: 'Codigo',
            tipo: 'number'
        },
        {
            field: 'Nome',
            headerName: 'Nome',
            tipo: 'text'
        },
        {
            field: 'Referencia',
            headerName: 'Referencia',
            tipo: 'text'
        },
        {
            field: 'PermissaoVenda',
            headerName: 'Perm. Venda',
            tipo: 'text'
        },

    ]

    function onLoad(loading) {
        props.onLoad(loading)
    }

    function onError(error) {
        props.onError(error)
    }

    function onSearchTitle(filter) {
        onLoad(true);
        ProdutoService.getAllFiname(filter)
            .then(response => {
                setProdutos(response.data.value);
                setPages(response.data.pages);
                setFilter(filter);
            })
            .catch(e => {
                onError(e);
            })
            .finally(() => {
                onLoad(false);
            });

    }

    function onGetItem(id) {
        id && onLoad(true);
        id && ProdutoService.getFiname(id)
            .then(response => {
                if (response.response?.data.error) {
                    onError(response.response?.data.error);
                } else {
                    response.data && onItemSelected(response.data);
                }
            })
            .catch(e => {
                onError(e);
            })
            .finally(() => {
                onLoad(false);
            });
    }

    function onItemSelected(item) {
        props.onItemSelected(item);
    }


    return (
        <>
            <NewSearchBarControlFormik
                {...props}
                fullscreen
                pages={pages}
                onSearchTitle={onSearchTitle}
                onGetItem={onGetItem}
                onItemSelected={onItemSelected}
                data={produtos}
                columns={columns}
                columnsFilter={columnsFilter}
                storagename={"ProdutosFI"}
            />
        </>
    )
}


export default SearchListProdutosFiname;
