import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Divider,
} from "@mui/material";
import { RiDeleteBinLine as DeleteIcon } from "react-icons/ri";
import { useDropzone } from "react-dropzone";
import { RemoveRedEye } from "@mui/icons-material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/Download";
import { AssistenciasContext } from "../../contexts/assistencias-context";
import { useFormikContext } from "formik";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { saveAs } from "file-saver";
import CircularProgress from "@mui/material/CircularProgress";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Button } from "react-bootstrap";

const AssistenciaAnexos = () => {
  const [modalOpenPreview, setModalOpenPreview] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [itemDowloadSelecionado, setItemDowloadSelecionado] = useState();
  const [loadingDowloadSelecionado, setLoadingDowloadSelecionado] =
    useState(false);
  const [indexParaDowload, setIndexParaDowload] = useState();

  const { values, setFieldValue } = useFormikContext();
  const pdfjs = require("pdfjs-dist");

  const {
    constantes,
    addAnexos,
    getAnexos,
    deleteAnexo,
    formatFilesSize,
    somenteLeitura,
  } = useContext(AssistenciasContext);

  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: file.type.startsWith("image/")
          ? URL.createObjectURL(file)
          : null,
      })
    );
    addAnexos(newFiles, values, setFieldValue);
  };

  const handlePreview = (item) => {
    setModalOpenPreview(true);
    if (item.TipoArquivo === ".pdf") {
      setModalContent(
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
        >
          <Viewer
            fileUrl={`${process.env.REACT_APP_BASE_URL}${item.AnexoLink}`}
          />
        </Worker>
      );
    } else if (item.TipoArquivo === ".png" || ".jpg") {
      setModalContent(
        <img
          src={`${process.env.REACT_APP_BASE_URL}${item.AnexoLink}`}
          alt={item.NomeArquivo}
          style={{ width: "100%", borderRadius: 5 }}
        />
      );
    } else {
      setModalContent(
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#ccc",
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {" "}
            {constantes.MsgAnexoNaoCarregado}
          </Typography>
        </Box>
      );
    }
  };

  const handleDelete = (item) => {
    deleteAnexo(values, item, setFieldValue);
  };

  const handleDownloadAnexo = async (item) => {
    setLoadingDowloadSelecionado(true);

    const downloadUrl = `${process.env.REACT_APP_BASE_URL}${item.AnexoLink}`;
    const fileName = item.NomeArquivo;

    await fetch(downloadUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(constantes.ErrorDowload);
        }
        return response.blob();
      })
      .then((blob) => {
        saveAs(blob, fileName);
      })
      .catch((error) => {
        setLoadingDowloadSelecionado(true);
        console.error("Erro:", error);
      })
      .finally(() => {
        setLoadingDowloadSelecionado(false);
      });
  };

  const handleCloseModal = () => {
    setModalOpenPreview(false);
    setModalContent(null);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    getAnexos(values, setFieldValue);
  }, [values.Assistencia.Anexo]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!somenteLeitura && (
          <Paper elevation={3} sx={{ padding: "16px" }}>
            <Typography variant="h6">{constantes.AnexarArquivos}</Typography>
            <div
              {...getRootProps()}
              style={{
                marginTop: "16px",
                padding: "16px",
                border: "2px dashed #ccc",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              <input {...getInputProps()} />
              <Typography color="textSecondary">
                {constantes.MsgAddAnexo}
              </Typography>
            </div>
          </Paper>
        )}
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ padding: "16px" }}>
          {values.Assistencia?.Anexos?.length !== 0 ? (
            <Typography variant="h6">
              {constantes.ListadeArquivosEnviados}
            </Typography>
          ) : (
            <Typography variant="h6" sx={{ color: "#6d6d6d" }}>
              {constantes.Nenhumanexoadicionado}...
            </Typography>
          )}

          <List>
            {values.Assistencia.Anexos.map((file, index) => (
              <ListItem key={index}>
                <a
                  onMouseMove={() => {
                    setItemDowloadSelecionado(index);
                  }}
                  onClick={() => {
                    handleDownloadAnexo(file);
                    setIndexParaDowload(index);
                  }}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {loadingDowloadSelecionado && indexParaDowload === index ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: "10px",
                      }}
                    >
                      <CircularProgress size={18} color="inherit" />
                    </Box>
                  ) : (
                    <Box>
                      {itemDowloadSelecionado !== index ? (
                        <InsertDriveFileIcon
                          fontSize={"medium"}
                          sx={{ marginRight: "10px", cursor: "pointer" }}
                        />
                      ) : (
                        <DownloadIcon
                          fontSize={"medium"}
                          sx={{ marginRight: "10px", cursor: "pointer" }}
                        />
                      )}
                    </Box>
                  )}
                </a>

                <ListItemText
                  primary={file.NomeArquivo}
                  secondary={formatFilesSize(file.TamanhoArquivo)}
                />
                <ListItemSecondaryAction sx={{ display: "flex", gap: 1 }}>
                  <IconButton edge="end" onClick={() => handlePreview(file)}>
                    <RemoveRedEye />
                  </IconButton>
                  <IconButton
                    edge="end"
                    disabled={somenteLeitura}
                    onClick={() => handleDelete(file)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
      <Dialog
        open={modalOpenPreview}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Typography>
            <AttachFileIcon sx={{ marginBottom: "5px", marginRight: "4px" }} />
            {constantes.VisualizarAnexo}
          </Typography>
          <Divider sx={{ background: "black" }} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{modalContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseModal()} style={{background:"crimson",border:"none"}}>
                {constantes.Fechar}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AssistenciaAnexos;
