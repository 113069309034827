import ControlSelect from "./control-selectcontrol.jsx";

const NewModoUsoFrete = (props) => {

    const { onBlur } = props;

    const itens = [
        { Id: 1, Valor: 'Embutir' },
        { Id: 2, Valor: 'Destacar' },
        { Id: 3, Valor: 'Não considerar' }
    ]

    return (
        <ControlSelect
            {...props}
            nulloption
            onBlur={(e) => onBlur ? onBlur(e) : e.target.blur()}
            name={"OrcamentoVenda.ModoUsoFrete"}
            placeholder={"Modo de uso de Frete"}
            itens={itens}
        />)
}

export default NewModoUsoFrete;