import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import OrcamentoVendaDescontos from "./orcamentovenda-descontos.content.modal.jsx";

const OrcamentoVendaDescontosModal = (props) => {

    const [modal, setModdal] = useState(false);
    const {toggle, className} = props;

    useEffect(() => {
        setModdal(props.modal);
    }, [props.modal])

    return (
        <Modal
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modal}
            onHide={toggle}
            className={className}>
            <ModalHeader toggle={toggle} closeButton></ModalHeader>
            <ModalBody>
                <OrcamentoVendaDescontos {...props} />
            </ModalBody>
        </Modal>
    )
}

export default OrcamentoVendaDescontosModal;
