import http, { GetIdiomaFiltro } from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class ProdutoService extends ServiceBase {

  getProdutoTraduzido(produto) {
    const nometraduzido = produto?.Traducoes.find((item) => item.Idioma === GetIdiomaFiltro());
    return nometraduzido ? nometraduzido.Traducao : produto.Nome;
  }

  getAll(filter, idTabelaPreco) {
    const consulta =
      idTabelaPreco
        ? `/produtos/tabelapreco/${idTabelaPreco}${this.getFilter(filter)}`
        : `/produtos${this.getFilter(filter)}`
    return http.get(consulta);
  }

  getAllFiname(filter) {
    const consulta = `/produtos/finame${this.getFilter(filter)}`
    return http.get(consulta);
  }

  get(id, idTabelaPreco) {
    const consulta =
      idTabelaPreco
        ? `/produtos/${id}/tabelapreco/${idTabelaPreco}`
        : `/produtos/${id}`
    return http.get(consulta);
  }

  getFiname(id) {
    const consulta = `/produtos/finame/${id}`
    return http.get(consulta);
  }

  getReferencia(id, idTabelaPreco) {
    const consulta =
      idTabelaPreco
        ? `/produtos/referencia/${id}/tabelapreco/${idTabelaPreco}`
        : `/produtos/referencia/${id}`
    return http.get(consulta);
  }

  postFormula(id, data) {
    return http.post(`/produtos/${id}/formula`, data);
  }

  getAtributosFormula(id) {
    return http.get(`/produtos/${id}/atributosformula`);
  }

  getAtributos(id) {
    return http.get(`/produtos/${id}/atributos`);
  }

  getImage(id) {
    return http.get(`/produtos/${id}/image`);
  }

  getComposicao(id) {
    return http.get(`/produtos/${id}/composicao`);
  }

  getComposicaoAssistencia(id) {
    return http.get(`/produtos/${id}/composicaoassistencia`);
  }
  getProdutoComposicaoAssistencia(idMestre, idProduto) {
    return http.get(`/produtos/${idMestre}/produtocomposicaoassistencia/${idProduto}`);
  }

  getImageLink(id) {
    return `${http.getUri()}/produtos/${id}/image`;
  }

}

export default new ProdutoService();