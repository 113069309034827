import { Box } from "@mui/material";
import React from "react";

export const ClientePainel = ({ children, value, index }) => {
  return (
    <Box role="tabpanel" hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </Box>
  );
};
