import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../../assets/logo.svg";

// Importe a API ou o módulo de serviço apropriado aqui
// import api from "../../services/api";
import api from "../../config/axiosConfig/http-common";
import T from "../../common/traducao";


import { Alert, Button, Container, Form, Image, Stack } from "react-bootstrap";
const SignUp = () => {
  const constantes = T(); 
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const { username, email, password } = formData;

    if (!username || !email || !password) {
      setError("Preencha todos os dados para se cadastrar");
    } else {
      try {
        
         await api.post("/users", { username, email, password });

       
        navigate("/");
      } catch (err) {
        console.error(err);
        setError("Ocorreu um erro ao registrar sua conta. T.T");
      }
    }
  };
 
  return (
    <Container style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "80vh", /*estava em 800*/
    }}>
      <Form style={{
          width: 400,
          padding: 20,
          display: "flex",
          alignItems: "center"
        }}
        onSubmit={handleSignUp}>
          <Stack gap={2} direction="vertical">
        <img src={Logo} alt="Airbnb logo" />
        {error && <p>{error}</p>}
        <Form.Control
          type="text"
          name="username"
          placeholder="Nome de usuário"
          onChange={handleInputChange}
        />
        <Form.Control
          type="email"
          name="email"
          placeholder="Endereço de e-mail"
          onChange={handleInputChange}
        />
        <Form.Control
          type="password"
          name="password"
          placeholder="Senha"
          onChange={handleInputChange}
        />
        <Button style={{ backgroundColor: "green",border: "none" }} type="submit">{constantes.CadastrarGratis}</Button>
        <hr />
        <Link to="/" style={{textAlign:'center'}/*Alinhamento temporario adicionado*/}>{constantes.Fazerlogin}</Link>
        </Stack>
      </Form>
      
    </Container>
  );
};

export default SignUp;