import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";
import { GetServerVersion, SetServerVersion } from "../config/localstorageConfig/localstorage";

class ConfigService extends ServiceBase {

    getServerVerion() {
        return http.get("/Config/Version");
    }

    async setCacheServerVersion() {
        const permissoes = GetServerVersion();
        if (!permissoes) {
            await this.getServerVerion()
                .then(response => {
                    SetServerVersion(response.data.value)
                })
                .catch(e => {
                    SetServerVersion("")
                    console.error(e);
                })
        };
    }

}

export default new ConfigService();