import React, { useContext, useEffect } from "react";
import {
  Box,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  Tab,
  Tabs,
  Grid,
  Drawer,
} from "@mui/material";
import { Alert, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import ClientesValidacoes from "./clientes-ValidacoesCliente";
import { ClienteContext } from "../../contexts/clientes-context";
import { useParams } from "react-router-dom";
import NewAcoesCadastro from "./clientes-AcoesCadastro";
import { FiMenu } from "react-icons/fi";
import ClienteTabs from "./cliente.tabs";
import { useFormikContext } from "formik";

export default function ViewClientes() {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const screenWidth = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    constantes,
    setDisabled,
    dadosDetalhesClientes,
    setDetalhesClientes,
    loading,
    loadingError,
    menu,
    abrirMenu,
    fecharMenu,
    tabValue,
    setTab,
    setAtributos,
    updateAtributos,
    configAtributosCliente,
  } = useContext(ClienteContext);

  const { setValues, errors, setFieldValue } = useFormikContext();

  useEffect(() => {
    id && dadosDetalhesClientes(id);
  }, [id]);

  function selecionarTab(event, newValue) {
    setTab(newValue);
    fecharMenu();
  }
  
  useEffect(() => {
    !id && updateAtributos(configAtributosCliente, setValues);
  }, [configAtributosCliente])

  useEffect(() => {
    console.log(errors)
  }, [errors])

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={1}
      >
        <Box
          display="flex"
          alignItems="center"
          p={screenWidth ? 1 : 3}
          gap={screenWidth ? 1 : 3}
        >
          {!screenWidth && (
            <FaArrowAltCircleLeft
              size={30}
              color="#0d9b86"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
                setDisabled(true);
                setDetalhesClientes(null);
              }}
            />)}
          <Typography
            variant={screenWidth ? "h6" : "h5"}
            color={"grey"}
            component={screenWidth ? "h6" : "h5"}
          >
            {id ? "Detalhes do Cliente" : "Novo Cliente"}
          </Typography>
        </Box>

        {screenWidth && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"center"}

          >
            <Box mb={0.5} mr={1}>
              <FiMenu onClick={abrirMenu} />
            </Box>
            <Drawer
              variant="temporary"
              anchor="top"
              open={menu}
              onClose={fecharMenu}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Tabs
                    value={tabValue}
                    onChange={selecionarTab}
                    aria-label="Tabs"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    orientation="vertical"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    textColor={"#007767"}
                    TabIndicatorProps={{
                      style: { backgroundColor: "#007767" },
                    }}
                  >
                    <Tab label="Geral" />
                    <Tab label="Endereço Principal" />
                    <Tab label="Endereço de Entrega" />
                    <Tab label="Endereço de Cobrança" />
                    <Tab label="E-mails" />
                    <Tab label="Condições de venda" />
                    <Tab label="Notas Gerais" />
                  </Tabs>
                </Grid>
              </Grid>
            </Drawer>
          </Box>

        )}
      </Box>
      <Divider
        color="#0d9b86"
        sx={{ marginBottom: 2, marginLeft: 3, marginRight: 3 }}
      />

      {loading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
          margin={2}
          width={"auto"}
          flexDirection={"column"}
        >
          <Spinner
            animation="border"
            style={{ color: "#009180" }}
            role="status"
          />
          <span style={{ fontSize: 18, color: "#009180", padding: "5px 0" }}>
            {constantes.Carregando}...
          </span>
        </Box>
      ) : (
        <Box mb={10}>
          {loadingError &&
            <Alert variant="danger">
              {loadingError.message}
            </Alert>}
          {!loadingError && <>

            <ClienteTabs />
            <ClientesValidacoes />
            <NewAcoesCadastro />
          </>}
        </Box>
      )}
    </>
  );
}
