import { useState, useContext, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import { useFormikContext } from "formik";
import { ClienteContext } from "../../contexts/clientes-context";
import { GrDeliver } from "react-icons/gr";
import SearchListMunicipios from "../searchs_comp/search-List.Municipios";
import EditControl from "../controls_comp/control-edit";
import EditCpfCnpjControl from "../controls_comp/control-edit-cpf-cnpj";
import EditFoneControl from "../controls_comp/control-edit-fone";
import EditCepControl from "../controls_comp/control-edit-cep";

export default function ClienteTabEnderecoEntrega() {
  const {
    constantes,
    handleLoad,
    camposDesabilitados,
    somenteLeitura
  } = useContext(ClienteContext);

  const [tipoPessoaSwitch, setTipoPessoaSwitch] = useState('Física');

  const { values, setFieldValue, setValues } = useFormikContext();

  useEffect(() => {
    console.log(values?.CpfCnpjEntrega.length);
    setTipoPessoaSwitch(values?.CpfCnpjEntrega.length > 11 ? 'Jurídica' : 'Física');
  }, [values?.CpfCnpjEntrega])

  return (
    <Box component={Paper}>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        height={50}
        sx={{ backgroundColor: "rgba(59, 134, 124, 0.08)" }}
      >
        <Typography variant="h1" fontSize={22} fontWeight="sembold">
          <GrDeliver style={{ marginBottom: "5px", marginRight: "8px" }} />
          {constantes.EnderecodeEntrega}
        </Typography>
      </Box>

      <Grid container spacing={2} p={2}>
        <Grid
          spacing={2}
          container
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          alignItems="end"
        >
          <Grid ml={2} xs={12} sm={3.6} md={4.2}>
            <EditCepControl
              disabled={somenteLeitura}
              name="CepEntrega"
              placeholder={constantes?.Cep}
              type="text"
              onCepResponse={(enderecoResponse, municipioResponse) => {
                setValues({
                  ...values,
                  EnderecoEntrega: enderecoResponse.logradouro,
                  BairroEntrega: enderecoResponse.bairro,
                  MunicipioEntrega: municipioResponse
                })
              }}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={10}>
            <EditControl
              disabled={somenteLeitura || camposDesabilitados.EnderecodeEntrega}
              name="EnderecoEntrega"
              placeholder={constantes?.Endereco}
              type="text" />
          </Grid>

          <Grid item xs={12} sm={5} md={5}>
            <EditControl
              disabled={somenteLeitura || camposDesabilitados.EnderecodeEntrega}
              name="NumeroEnderecoEntrega"
              placeholder={constantes?.Numerodoendereco}
              type="text" />
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <EditControl
              disabled={somenteLeitura || camposDesabilitados.EnderecodeEntrega}
              name="ComplementoEnderecoEntrega"
              placeholder={constantes?.Complemento}
              type="text" />
          </Grid>
          <Grid item xs={12} sm={10} md={10}>
            <EditControl
              disabled={somenteLeitura || camposDesabilitados.EnderecodeEntrega}
              name="BairroEntrega"
              placeholder={constantes?.Bairro}
              type="text" />
          </Grid>

          <Grid item xs={12} sm={10} md={10}>
            <SearchListMunicipios
              name="MunicipioEntrega"
              nameId="MunicipioEntrega.Id"
              nameDesc="MunicipioEntrega.Nome"
              placeholder={constantes.Municipio}
              disabled={somenteLeitura || camposDesabilitados.EnderecodeEntrega}
              onLoad={handleLoad}
              onItemSelected={(item) => {
                setFieldValue("MunicipioEntrega", item);
              }}
              onItemClear={() => {
                console.log("clear")
                setFieldValue("MunicipioEntrega", {Id:0, Nome : ""});
              }}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={10}>
            <EditControl
              inputProps={{ maxLength: 50 }}
              disabled={somenteLeitura || camposDesabilitados.EnderecodeEntrega}
              name="NomeRecebEntrega"
              placeholder={constantes?.NomeRecebedor}
              type="text" />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={5} mt={-0.6} md={5}>
          <EditCpfCnpjControl
            tipoPessoa={tipoPessoaSwitch}
            inputProps={{ maxLength: 14 }}
            disabled={somenteLeitura}
            name="CpfCnpjEntrega"
            placeholder={constantes?.CpfCnpjCliente}
            type="text" />
        </Grid>

        <Grid item xs={12} sm={5} md={5}>
          <EditFoneControl
            inputProps={{ maxLength: 12 }}
            disabled={somenteLeitura || camposDesabilitados.EnderecodeEntrega}
            name="TelefoneEntrega"
            placeholder={constantes?.Telefone}
            type="text" />
        </Grid>
        <Grid item xs={12} sm={10} md={10}>
          <EditControl
            disabled={somenteLeitura || camposDesabilitados.EnderecodeEntrega}
            name="EmailEntrega"
            placeholder={constantes?.Email}
            type="email" />
        </Grid>

        <Grid item xs={12} sm={10} md={10}>
          <EditControl
            disabled={somenteLeitura || camposDesabilitados.EnderecodeEntrega}
            name="InscricaoEstadualEntrega"
            placeholder={constantes?.InscrEstadualProdutor}
            type="text" />
        </Grid>

        <Grid item xs={12} sm={10} md={10}>
          <EditControl
            disabled={somenteLeitura || camposDesabilitados.EnderecodeEntrega}
            name="ObservacaoEntrega"
            placeholder={constantes?.ObservacaoEntrega}
            type="text" />
        </Grid>
      </Grid>
    </Box>
  );
}
