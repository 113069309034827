import { useEffect, useState } from "react";
import serviceProduto from "../../services/service.produto";
import { Col, Row } from "react-bootstrap";
import { Form } from "formik";
import T from "../../common/traducao";
const NewAtributosProdutoControl = (props) => {
    const constantes = T();
    const [produtoId, setProdutoId] = useState(1993);
    
    const [atributos, setAtributos] = useState();
    const [atributosFormula, setAtributosFormula] = useState();

    const [atributosSelecionados, setatributosSelecionados] = useState();
    const [atributosFormulaSelecionados, setatributosFormulaSelecionados] = useState();

    const [aributosView, setAtributosView] = useState([]);

    async function getAtributosService() {
        await serviceProduto.getAtributos(produtoId)
            .then((response) => {
                setAtributos(response.data.value);
            })
            .catch((error) => console.error("atributos", error))

        await serviceProduto.getAtributosFormula(produtoId)
            .then((response) => {
                setAtributosFormula(response.data.value);
            })
            .catch((error) => console.error("atributos formula", error))
    }

    useEffect(() => {
        getAtributosService();
    }, [])

    function getValoresAtributo(atributo) {

        const valores = atributo && atributo.Valores.map((item, i) => {
            return <option key={i} value={parseInt(item.Id)}>{item.Valor}</option>
        });


        const retorno = <Form.Select>
            <option>{constantes.Teste}</option>
        </Form.Select>

        return retorno;
    }

    return (
        <>
            <h1>carregando {produtoId}</h1>

            {atributos && atributos.map((item, i) => {
                let retorno = null;
                if (item.Atributo.Tipo === 'Texto' && item.Valores.length > 0) {
                    retorno = getValoresAtributo(item);
                } else if (item.Atributo.Tipo === 'Texto') {
                    retorno = <small>{constantes.itemValores}</small>
                } else {
                    retorno = <small>{constantes.itemTexto}</small>
                }

                return (

                    <Row key={i}>
                        <Col>
                            {retorno}
                        </Col>
                    </Row>
                )
            })}
            <h1>Formula {produtoId}</h1>

            {atributosFormula && atributosFormula.map((item, i) => {
                let retorno = null;
                if (item.Atributo.Tipo === 'Texto' && item.Valores.length > 0) {
                    retorno = <small>{constantes.itemTexto}</small>
                } else if (item.Atributo.Tipo === 'Texto') {
                    retorno = <small>{constantes.itemValores}</small>
                } else {
                    retorno = <small>{constantes.itemTexto}</small>
                }

                return (

                    <Row key={i}>
                        <Col>
                            {retorno}
                        </Col>
                    </Row>
                )
            })}
        </>
    )
}

export default NewAtributosProdutoControl;