import { useState } from "react";
import TipoAssistencia from "../../services/service.tipo.assistencia";
import NewSearchBarControlFormik from "../searchs_comp/search-Bar.Control.Formik";
import { GetIdiomaFiltro } from "../../config/axiosConfig/http-common";


const CamposSearchTipoAssistencia = (props) => {

    const [tipoAssistencia, setTipoAssistencia] = useState();
    const [pages, setPages] = useState();
    const [filter, setFilter] = useState();



    const idioma = GetIdiomaFiltro();
    const { idAssistencia } = props;

    const columns = [
        {
            field: 'Id',
            headerName: 'Codigo',
            width: 90,
            key: true
        },
        {
            field: 'Nome',
            headerName: 'Nome',
          
        },
    ]

    const columnsFilter = [
        {
            field: 'Id',
            headerName: 'Codigo',
            tipo: 'number'
        },
        {
            field: 'Nome',
            headerName: 'Nome',
            tipo: 'text'
        },
    ]


    function onLoad(loading) {
        props.onLoad(loading)
    }

    function onError(error) {
        props.onError(error)
    }

    function onSearchTitle(filter) {
        onLoad(true);
         TipoAssistencia.getAll(filter)
            .then(response => {
                const tiposdeassistencia = response.data.value
                
                setTipoAssistencia(tiposdeassistencia);
                setPages(response.data.pages);
                setFilter(filter);
            })

            .catch(e => {
                onError(e);
            })

            .finally(() => {
                onLoad(false);
            });
        }

   function onGetItem(id) {
        id && onLoad(true);
        id && TipoAssistencia.getId(id)
            .then(response => {
                if (response.response?.data.error) {
                    onError(response.response?.data.error);
                } else {
                    // const traducao = ProdutoService.getProdutoTraduzido(response.data);
                    // const produto = { ...response.data, Nome: traducao };
                    response.data && onItemSelected(response.data);
                }
            })
            .catch(e => {
                onError(e);
            })
            
            .finally(() => {
                onLoad(false);
            });
    }

    function onItemSelected(item) {
        props.onItemSelected(item);
    }


    return (
        <>
            <NewSearchBarControlFormik
                {...props}
                fullscreen
                pages={pages}
                onSearchTitle={onSearchTitle}
                onGetItem={onGetItem}
                onItemSelected={onItemSelected}
                data={tipoAssistencia}
                columns={columns}
                storagename={"TipoAssistencia"}
                columnsFilter={columnsFilter}
            />
        </>
    )
}


export default CamposSearchTipoAssistencia;
