import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import T from "../../../common/traducao";
const NewModalOrcamentovendaDelete = (props) => {

    const constantes = T();
    const [modal, setModdal] = useState(props.modal);
    const { id, toggle, className, onExcluir } = props;

    useEffect(() => {
        setModdal(props.modal);
    }, [props.modal])

    return (
        <>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modal}
                onHide={toggle}
                backdrop="static"
                keyboard={false}
                className={className}>
                <ModalHeader toggle={toggle} closeButton></ModalHeader>
                <ModalBody>
                    <h2>{constantes.MsgDesejaRealmenteExcluir } {id} ? </h2>
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={toggle}>{constantes.Nao} {constantes.Excluir}</Button>
                    <Button variant="danger" onClick={() => onExcluir(id)} >{constantes.Excluir}</Button>
                </ModalFooter>
            </Modal>

        </>
    )

}

export default NewModalOrcamentovendaDelete;