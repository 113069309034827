import { ErrorMessage, useField, useFormikContext } from "formik";
import { Badge, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { format_percent_2 } from "../../common/format";
import OrcamentoVendaCondicaoEspecialCtx from "../orcamento_venda/orcamentovenda-condicaoespecial";
import T from "../../common/traducao";
const CondicaoEspecialControl = (props) => {

  const { name, placeholder, namedefault, valuecalculated, hideTitle, disabled, lado, onBlur, value, heightLabel } = props;
  const [fieldId, metaId, handleId] = useField(name ?? null);

  const hasValueCalculated = valuecalculated > 0 ? true : false;
  const constantes = T();
  const { handleChange, errors, isValid } = useFormikContext();

  return (
    <>
      <Form.Label style={{ height: heightLabel ?? 40 }} htmlFor={placeholder}>{placeholder}</Form.Label>
      <InputGroup>
        <Form.Control
          {...props}
          style={{ maxWidth: 250 }}
          id={placeholder}
          name={name ?? null}
          type="number"
          value={value ? value : metaId.value}
          onChange={(e) => {
            handleChange(e)
          }}
          onWheel={(e) => e.target.blur()}
          aria-describedby={placeholder}
          placeholder="0,00"
        />
        <OrcamentoVendaCondicaoEspecialCtx/>
        

      </InputGroup>

      {hasValueCalculated &&
        <Form.Text id={placeholder} muted>
          {constantes.Tabela} ({format_percent_2.format(valuecalculated ?? 0)})
        </Form.Text>
      }

      <ErrorMessage name={name}>
        {(msg) =>
          <Badge bg="danger">{msg}</Badge>
        }
      </ErrorMessage>
    </>
  )
}


export default CondicaoEspecialControl;
