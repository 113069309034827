
import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class AssistenciasService extends ServiceBase {

  getAll(filter) {
    //return http.get("/clientes?$top=10");
    return http.get("/Assistencias" + this.getFilter(filter));
  }

  get(id) {
    return http.get(`/Assistencias/${id}`);
  }

  post(data) {
    return http.post(`/Assistencias`, data)
  }

  solicitarMudanca(id, data) {
    return http.post(`/Assistencias/${id}/solicitaralteracao`, data)
  }
  put(id, data) {
    return http.put(`/Assistencias/${id}`, data)
  }
  
  finalizarAssistencia(id, data){
     return http.put(`/Assistencias/${id}/AtualizarSituacao`, data)
  } 

  addComentario(id, data) {
    return http.post(`/Assistencias/${id}/comentarios`, data)
  }

  removerComentario(id, idcomentario) {
    return http.delete(`/Assistencias/${id}/comentarios/${idcomentario}`)
  }

  deletarAssistenciaService(id) {
    return http.delete(`/Assistencias/${id}`)
  }
  
  duplicarAssistenciaService(id){ 
    return http.post(`/Assistencias/${id}/Duplicar`)
  }

  getListaRelatoriosAssistencias(id) {
    return http.get(`/Assistencias/${id}/ListagemImpressoes`);
  }
  imprimirRelatoriosAssistencias(link) {
    return http.get(link,
      {
        responseType: "blob",
      });
  }
}

export default new AssistenciasService();


