import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Alert,
} from "react-bootstrap";
import { useContext, useRef } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { useFormikContext } from "formik";
import "../../styles/botoesBuscar.css"
import "../../styles/orcamentocondicaoespecial.css";

const OrcamentoVendaCondicaoEspecialCtx = (props) => {
  const {
    modalDescontoEspecial,
    setModalDescontoEspecial,
    constantes,
    configs,
    somenteLeitura,
    addCondicaoEspecial,
    alertaLimitePorcentagemEspecial,
    setAlertaLimitePorcentagemEspecial,
  } = useContext(OrcamentoVendaContext);

  const { setFieldValue, values } = useFormikContext();

  const refDescontoEspecial = useRef();
  const refJustificativa = useRef();

  const limitePercentualEspecial = configs.DescontoMaximoEspecial; //orcamentoVenda.TabelaComissao.LimiteDesconto;

  return (
    <>
      {/* {vendedorSimulado && */}
      <Modal
        {...props}
        fullscreen={"xl-down"}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalDescontoEspecial}
        onHide={() => {
          setModalDescontoEspecial(false);
          setAlertaLimitePorcentagemEspecial(false);
        }}
        backdrop="static"
        keyboard={false}
        className={props.className}
      >
        <ModalHeader toggle={props.toggle} closeButton>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#009180",
            }}
          >
            <h2> {constantes.DescontoEspecialExtra} </h2>
          </div>
        </ModalHeader>
        <ModalBody>
          <Form.Label
            bsPrefix="Container_Label"
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 10,
              margin: 0,
              height: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 10,
                margin: 0,
                height: "2rem",
              }}
            >
              <p className="DescontosLabel">
                {constantes.DescontoEspecialExtra} (%)
              </p>
            </div>

            {limitePercentualEspecial &&
              <div>
                <p className="DescontosAvisoLimite">`${constantes.SeuLimitede} ${limitePercentualEspecial}%`</p>
              </div>
            }
          </Form.Label>

          <Form.Control
            type="Number"
            ref={refDescontoEspecial}
            onWheel={(e) => e.target.blur()}
          />

          {alertaLimitePorcentagemEspecial ? (
            <Alert
              variant="danger"
              className="DescontosAlerta"
              style={{ padding: "0 0.6rem", height: "1.8rem" }}
            >
              {constantes.MsgLimite} !
            </Alert>
          ) : (
            ""
          )}

          <Form.Label style={{ marginTop: "0.3rem" }}>
            {constantes.Justificativa}
          </Form.Label>
          <Form.Control ref={refJustificativa} />
        </ModalBody>
        <ModalFooter>
          <Button
          bsPrefix="ButtonStyle DescontoEspecialOk" 
            onClick={() => {
              addCondicaoEspecial(
                {
                  PercentualDescontoEspecial: Number(
                    refDescontoEspecial.current.value
                  ),
                  Justificativa: refJustificativa.current.value,
                },
                setFieldValue,
                values
              );
            }}
          >
            {constantes.Aplicar}
          </Button>
        </ModalFooter>
      </Modal>
      {configs?.HabilitarDescontoEspecial && !somenteLeitura && (
        <Button
          bsPrefix="ButtonStyle adicionarDescontoEspecial" 
           disabled={somenteLeitura}
          onClick={() => {
            setModalDescontoEspecial(true);
          }}
        >
          <FontAwesomeIcon icon={faAdd} />
        </Button>
      )}
    </>
  );
};

export default OrcamentoVendaCondicaoEspecialCtx;
