import { Icon, IconButton, useTheme } from "@mui/material";


export const BotaoIcone = ({
  onClick,
  icon,
  isDisabled,
  color,
  type,
}) => {
  const theme = useTheme();
  return (
    <IconButton
      type="submit"
      disabled={isDisabled}
      onClick={onClick}
      sx={
        color === "primary"
          ? {
              background: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              "&:hover": {
                background: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
              },
            }
          : {
              border: `1px solid ${color}`,
              color: color,
              "&:hover": {
                background: color,
                color: theme.palette.primary.contrastText,
              },
              ":disabled": {
                border: `1px solid ${color}`,
              },
            }
      }
    >
      <Icon>{icon}</Icon>
    </IconButton>
  );
};

