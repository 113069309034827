import { Button, Spinner, Stack, Table } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { faAlignCenter } from "@fortawesome/free-solid-svg-icons";
import { format_currency_2, format_currency_4, format_currency_5, format_percent_2, format_percent_3, format_percent_4, format_percent_5 } from "../../common/format";
import { config } from "@fortawesome/fontawesome-svg-core";
import { useContext } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";

const TotalControl = (props) => {
  const { totais, atualizartotal, onAtualizartotal, recalculando } = props;

  const {
    configs,
    constantes
  } = useContext(OrcamentoVendaContext);

  function getTotalCompelto() {
    return (
      <Table bordered hover size="sm">
        <thead>

        </thead>
        <tbody>
          <tr>
            <td colSpan={3}>{constantes.TotalItens}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_percent_2.format(totais?.TotalItens ?? 0)} placeholder="0,00" disabled />
            </td>
          </tr>
          <tr>
            <td>{constantes.PesoLiquidoTotal}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_percent_3.format(totais?.PesoLiquidoTotal ?? 0)} placeholder="0,000" disabled />

            </td>
            <td>{constantes.PesoBrutoTotal}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_percent_3.format(totais?.PesoBrutoTotal ?? 0)} placeholder="0,000" disabled />
            </td>
          </tr>
          <tr>
            <td>{constantes.Cubagem}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_percent_4.format(totais?.Cubagem ?? 0)} placeholder="0,0000" disabled />
            </td>
            <td>{constantes.DescontoAcimaAuto}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_percent_4.format(totais?.DescontoAcimaAuto ?? 0)} placeholder="0,0000" disabled />
            </td>
          </tr>
          <tr>
            <td>{constantes.BaseCalculoComissao}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_currency_2.format(totais?.BaseCalculoComissao ?? 0)} placeholder="0,00" disabled />
            </td>

            <td>{constantes.TotalPrecoTabela}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_currency_2.format(totais?.TotalPrecoTabela ?? 0)} placeholder="0,00" disabled />
            </td>
          </tr>
          <tr>
            <td>{constantes.PercentualComissao}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_percent_5.format(totais?.PercentualComissao ?? 0)} placeholder="0,0000" disabled />
            </td>
            <td>{constantes.PrecentualDescAcimaAuto}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_percent_4.format(totais?.PercentualDescontoAcima ?? 0)} placeholder="0,0000" disabled />
            </td>
          </tr>
          <tr>
            <td>{constantes.ValorComissao}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_currency_2.format(totais?.ValorComissao ?? 0)} placeholder="0,00" disabled />
            </td>
            <td>{constantes.TotalFrete} (+)</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_currency_2.format(totais?.TotalFrete ?? 0)} placeholder="0,00" disabled />
            </td>
          </tr>
          <tr>
            <td>{constantes.ValorST} (+)</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_currency_4.format(totais?.ValorSt ?? 0)} placeholder="0,0000" disabled />
            </td>
            <td>{constantes.TotalIPI} (+)</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_currency_2.format(totais?.TotalIpi ?? 0)} placeholder="0,00" disabled />
            </td>
          </tr>
          <tr>
            <td>{constantes.FCPST} (+)</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_currency_4.format(totais?.ValorFCPRetSt ?? 0)} placeholder="0,0000" disabled />
            </td>
            <td>{constantes.ValorDif} (+)</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_currency_2.format(totais?.ValorDif ?? 0)} placeholder="0,00" disabled />
            </td>
          </tr>
          <tr>
            {atualizartotal && <td style={{ fontSize: 25, color: "gray" }} colSpan={3}>{constantes.ValorTotal} (=) ** Falta atualizar **</td>}
            {!atualizartotal && <td style={{ fontSize: 25 }} colSpan={3}>{constantes.ValorTotal}  (=)</td>}
            <td>
              {recalculando
                ? <Stack direction="horizontal" gap={2} >
                  <Spinner
                    variant="success"
                    animation="border"
                    role="status" />
                  Recalculando
                </Stack>
                : <Form.Control style={{ fontSize: 25, color: atualizartotal ? "gray" : null, textAlign: 'right', fontWeight: 'bold' }} value={format_currency_2.format(totais?.ValorTotal ?? 0)} placeholder="0,00" disabled />
              }
              {atualizartotal && <Button
                variant="success"
                style={{ width: '100%', height: '100%' }}
                onClick={onAtualizartotal}
                disabled={recalculando}
              >
                {recalculando ? <Spinner animation="border" role="status">
                </Spinner> : "Atualizar Total"}

              </Button>}
            </td>
          </tr>


        </tbody>

      </Table>
    )
  }

  function getTotalComCamposDesabilitados() {
    return (
      <Table bordered hover size="sm">
        <thead>

        </thead>
        <tbody>
          <tr>
            <td colSpan={3}>{constantes.TotalItens}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_percent_2.format(totais?.TotalItens ?? 0)} placeholder="0,00" disabled />
            </td>
          </tr>
          <tr>
            <td>{constantes.PesoLiquidoTotal}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_percent_3.format(totais?.PesoLiquidoTotal ?? 0)} placeholder="0,000" disabled />

            </td>
            <td>{constantes.PesoBrutoTotal}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_percent_3.format(totais?.PesoBrutoTotal ?? 0)} placeholder="0,000" disabled />
            </td>
          </tr>
          <tr>
            <td>{constantes.Cubagem}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_percent_4.format(totais?.Cubagem ?? 0)} placeholder="0,0000" disabled />
            </td>
            <td>{constantes.DescontoAcimaAuto}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_percent_4.format(totais?.DescontoAcimaAuto ?? 0)} placeholder="0,0000" disabled />
            </td>
          </tr>
          <tr>

            <td>{constantes.TotalPrecoTabela}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_currency_2.format(totais?.TotalPrecoTabela ?? 0)} placeholder="0,00" disabled />
            </td>
            <td>{constantes.PrecentualDescAcimaAuto}</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_percent_4.format(totais?.PercentualDescontoAcima ?? 0)} placeholder="0,0000" disabled />
            </td>
          </tr>
          <tr>
            <td>{constantes.TotalFrete} (+)</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_currency_2.format(totais?.TotalFrete ?? 0)} placeholder="0,00" disabled />
            </td>
            <td>{constantes.ValorST} (+)</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_currency_4.format(totais?.ValorSt ?? 0)} placeholder="0,0000" disabled />
            </td>
          </tr>
          <tr>
            <td>{constantes.TotalIPI} (+)</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_currency_2.format(totais?.TotalIpi ?? 0)} placeholder="0,00" disabled />
            </td>
            <td>{constantes.FCPST} (+)</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_currency_4.format(totais?.ValorFCPRetSt ?? 0)} placeholder="0,0000" disabled />
            </td>
          </tr>
          <tr>
            <td>{constantes.ValorDif} (+)</td>
            <td>
              < Form.Control style={{ textAlign: 'right' }} value={format_currency_2.format(totais?.ValorDif ?? 0)} placeholder="0,00" disabled />
            </td>
          </tr>
          <tr>
            {atualizartotal && <td style={{ fontSize: 25, color: "gray" }} colSpan={3}>{constantes.ValorTotal} (=) ** Falta atualizar **</td>}
            {!atualizartotal && <td style={{ fontSize: 25 }} colSpan={3}>{constantes.ValorTotal}  (=)</td>}
            <td>
              {recalculando
                ? <Stack direction="horizontal" gap={2} >
                  <Spinner
                    variant="success"
                    animation="border"
                    role="status" />
                  Recalculando
                </Stack>
                : <Form.Control style={{ fontSize: 25, color: atualizartotal ? "gray" : null, textAlign: 'right', fontWeight: 'bold' }} value={format_currency_2.format(totais?.ValorTotal ?? 0)} placeholder="0,00" disabled />
              }
              {atualizartotal && <Button
                variant="success"
                style={{ width: '100%', height: '100%' }}
                onClick={onAtualizartotal}
                disabled={recalculando}
              >
                {recalculando ? <Spinner animation="border" role="status">
                </Spinner> : "Atualizar Total"}

              </Button>}
            </td>
          </tr>


        </tbody>

      </Table>
    )
  }

  return (
    configs.DesabilitarCamposConformePerfil
      ? getTotalComCamposDesabilitados()
      : getTotalCompelto()

  )
}

export default TotalControl;