import React, { useEffect, useState } from "react";

import { ButtonGroup, Dropdown, Image, Stack } from "react-bootstrap";
import { GetIdiomaSelecionado, SetIdiomaSelecionado } from "../../config/localstorageConfig/localstorage";
import T from "../../common/traducao";
import Brasil from "../../assets/brasil.png";
import Espanha from "../../assets/espanha.png";

function LanguageSelector({children}) {

  const [idiomaAtual, setIdiomaAtual] = useState(GetIdiomaSelecionado());
  const constantes = T();
  useEffect(() => {
    setIdiomaAtual(GetIdiomaSelecionado());
  }, [GetIdiomaSelecionado])

  return (
    <>
      <Dropdown
        as={ButtonGroup}
      >
       {children}
      </Dropdown>
    </>
  );
}

export default LanguageSelector;
