

import React, { useEffect, useRef, useState } from "react";
import { Container, Navbar, Row,Spinner, Stack } from "react-bootstrap";
import T from "../../common/traducao";



import { GetTextosIdioma, SetTextosIdioma } from "../../services/service.idioma";
import { GetPermissaoVisualizar, SetPermissoes } from "../../config/localstorageConfig/localstorage";


const MenuLoading = () => {
  const constantes = T();
  const brandRef = useRef(null);

  const [isClicked, setIsClicked] = useState(true); 

  useEffect(() => {
 
    const clickInterval = setTimeout(() => {
      if (brandRef.current) {
        brandRef.current.click();
        setIsClicked(true);
      }
    }, 1);

    return () => {
      clearInterval(clickInterval);
    };
  }, []);

  return (
    <>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row>
          <Navbar.Brand
            href={"/home"}
            ref={brandRef}
            className={isClicked ? "clicked" : ""}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "90vh",
            }}
          >
          </Navbar.Brand>
        </Row>
      </Container>
    </>
  );
};

export default MenuLoading;