import { useNavigate } from "react-router-dom";
import { logout } from "../../services/auth";
import { useEffect } from "react";

const Logout = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    logout();
    navigate("/sigin");
    window.location.reload(false);
  }, [])
  return (
    <>
    </>
  );
}
export default Logout

