import { useState } from "react";
import GoogleMaps from "../../components/map";
import ModalMapControl from "../../components/ui_comp/Modals/ModalMap";
import { Button } from "react-bootstrap";

const ExampleMap = () => {

    const [modal, setModal] = useState(false);

    return (
        <>
            <Button onClick={() => setModal(true)}>Abrir Mapa</Button>
            <ModalMapControl 
                onSelectedMarker={(marker) => {console.log(marker)}} 
                modal={modal} 
                toggle={(e) => setModal(false)} />
            <GoogleMaps onSelectedMarker={(marker) => {console.log(marker)}} />
        </>
    )
}

export default ExampleMap;