import { Button } from "react-bootstrap";
import ListComp from "../../common/listcomp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import ListComp2 from "../../common/listcomp2";
import PaginationControl from "../../components/controls_comp/control-Pagination";

const ExampleItensList = () => {

    const columnsItens = [
        {
            field: 'Item',
            headerName: 'Item',
            width: 40,
            key: true
        },
        {
            field: 'CodProduto',
            headerName: 'Cód Produto',
            width: 40
        },
        {
            field: 'Referencia',
            headerName: 'Referencia',
            width: 40
        },
        {
            field: 'NomeProduto',
            headerName: 'Nome Produto',
            width: 40
        },
        {
            field: 'UnidadeMedida',
            headerName: 'Und',
            width: 40
        },
        {
            field: 'PrecoTabela',
            headerName: 'Preco Tabela',
            width: 40
        },
        {
            field: 'PrecoTabelaComDesconto',
            headerName: 'Preço Tabela com Desconto',
            width: 40
        },
        {
            field: 'PrecoUnitario',
            headerName: 'Preço Unitario',
            width: 40
        },
        {
            field: 'Quantidade',
            headerName: 'Quantidade',
            width: 40
        },
        {
            field: 'TotalItem',
            headerName: 'TotalItem',
            width: 40
        },
        {
            field: 'IdentificacaoProjeto',
            headerName: 'Projeto',
            width: 40
        }
    ]

    const btns = [
        {
            getBtn: (itemdata, index) => {
                return (
                    <Button
                        value={index}
                        onClick={(arg) => {
                            alert(JSON.stringify(itemdata, null, 2));
                        }}  >
                        <FontAwesomeIcon value={index} icon={faEdit}>

                        </FontAwesomeIcon>
                    </Button>

                )
            },
        },
        {

            getBtn: (itemdata, index) => {
                return (
                    <Button
                        variant="danger"
                        value={index}
                        onClick={(arg) => {
                            alert(JSON.stringify(itemdata, null, 2))
                        }}  >
                        <FontAwesomeIcon value={index} icon={faTrash}>

                        </FontAwesomeIcon>
                    </Button>

                )
            },
        },
    ];

    const itens = [
        {
            "group" : "Teste",
            "TotalItem": 0,
            "IdentificacaoProjeto": "1234",
            "Acrescimo": 0,
            "Desconto": 0,
            "DescontoEspecial": 0,
            "UnidadeMedida": "UND",
            "Id": 1,
            "PrecoUnitario": 13.767,
            "Item": 1,
            "PrecoTabela": 13.767,
            "Quantidade": 1,
            "PrecoTabelaComDesconto": 13.767,
            "CodProduto": 3174,
            "Referencia": "3174-VP",
            "NomeProduto": "TAMPA SAIDA DUPLA",
            "Observacoes": "",
            "IndexItemFiname": 0,
            "TabelaPrecoId": 82,
            "Atributos": [],
            "FreteUnitario": 0,
            "FreteItem": 0,
            "FreteUnitarioCalculado": 0,
            "FreteItemCalculado": 0,
            "Cfop": {
                "$id": 3,
                "TipoFaturamento": "Normal",
                "ReplicadorConfig": "False",
                "ClasseInterna": "",
                "Descricao": "Venda de produção - Consumo ou imobilizado AP",
                "Complemento2": "",
                "Id": "6.101.05",
                "Complemento1": "Estoque",
                "DataHoraAlteracao": "2022-05-16T07:41:31.014",
                "Situacao": "Ativo",
                "TipoOperacao": {
                    "$id": 4,
                    "Id": 2,
                    "Nome": "VENDA",
                    "CodigoIntegracao": ""
                }
            },
            "PrecoUnitarioComercial": 13.767,
            "QuantidadeComercial": 1,
            "UnidadeMedidaComercial": "UND",
            "FatorConvUndCom": 1
        },
        {
            "TotalItem": 0,
            "IdentificacaoProjeto": "Agora vai",
            "Acrescimo": 0,
            "Desconto": 0,
            "DescontoEspecial": 0,
            "UnidadeMedida": "UND",
            "Id": 2,
            "PrecoUnitario": 13.767,
            "Item": 2,
            "PrecoTabela": 13.767,
            "Quantidade": 1,
            "CodProduto": 3174,
            "Referencia": "3174-VP",
            "NomeProduto": "TAMPA SAIDA DUPLA",
            "Observacoes": "",
            "IndexItemFiname": 0,
            "TabelaPrecoId": 82,
            "PrecoTabelaComDesconto": 13.767,
            "Atributos": [],
            "FreteUnitario": 0,
            "FreteItem": 0,
            "FreteUnitarioCalculado": 0,
            "FreteItemCalculado": 0,
            "Cfop": {
                "$id": 3,
                "TipoFaturamento": "Normal",
                "ReplicadorConfig": "False",
                "ClasseInterna": "",
                "Descricao": "Venda de produção - Consumo ou imobilizado AP",
                "Complemento2": "",
                "Id": "6.101.05",
                "Complemento1": "Estoque",
                "DataHoraAlteracao": "2022-05-16T07:41:31.014",
                "Situacao": "Ativo",
                "TipoOperacao": {
                    "$id": 4,
                    "Id": 2,
                    "Nome": "VENDA",
                    "CodigoIntegracao": ""
                }
            },
            "PrecoUnitarioComercial": 13.767,
            "QuantidadeComercial": 1,
            "UnidadeMedidaComercial": "UND",
            "FatorConvUndCom": 1
        },
        {
            "TotalItem": 0,
            "IdentificacaoProjeto": "Teste 1",
            "Acrescimo": 0,
            "Desconto": 0,
            "DescontoEspecial": 0,
            "UnidadeMedida": "UND",
            "Id": 3,
            "PrecoUnitario": 13.767,
            "Item": 3,
            "PrecoTabela": 13.767,
            "Quantidade": 1,
            "CodProduto": 3174,
            "Referencia": "3174-VP",
            "NomeProduto": "TAMPA SAIDA DUPLA",
            "Observacoes": "",
            "IndexItemFiname": 0,
            "TabelaPrecoId": 82,
            "PrecoTabelaComDesconto": 13.767,
            "Atributos": [],
            "FreteUnitario": 0,
            "FreteItem": 0,
            "FreteUnitarioCalculado": 0,
            "FreteItemCalculado": 0,
            "Cfop": {
                "$id": 3,
                "TipoFaturamento": "Normal",
                "ReplicadorConfig": "False",
                "ClasseInterna": "",
                "Descricao": "Venda de produção - Consumo ou imobilizado AP",
                "Complemento2": "",
                "Id": "6.101.05",
                "Complemento1": "Estoque",
                "DataHoraAlteracao": "2022-05-16T07:41:31.014",
                "Situacao": "Ativo",
                "TipoOperacao": {
                    "$id": 4,
                    "Id": 2,
                    "Nome": "VENDA",
                    "CodigoIntegracao": ""
                }
            },
            "PrecoUnitarioComercial": 13.767,
            "QuantidadeComercial": 1,
            "UnidadeMedidaComercial": "UND",
            "FatorConvUndCom": 1
        },
        {
            "group" : "aaaaa",
            "TotalItem": 0,
            "IdentificacaoProjeto": "aaaaaa",
            "Acrescimo": 0,
            "Desconto": 0,
            "DescontoEspecial": 0,
            "UnidadeMedida": "UND",
            "Id": 4,
            "PrecoUnitario": 13.767,
            "Item": 4,
            "PrecoTabela": 13.767,
            "Quantidade": 1,
            "CodProduto": 3174,
            "Referencia": "3174-VP",
            "NomeProduto": "TAMPA SAIDA DUPLA",
            "Observacoes": "",
            "IndexItemFiname": 0,
            "TabelaPrecoId": 82,
            "PrecoTabelaComDesconto": 13.767,
            "Atributos": [],
            "FreteUnitario": 0,
            "FreteItem": 0,
            "FreteUnitarioCalculado": 0,
            "FreteItemCalculado": 0,
            "Cfop": {
                "$id": 3,
                "TipoFaturamento": "Normal",
                "ReplicadorConfig": "False",
                "ClasseInterna": "",
                "Descricao": "Venda de produção - Consumo ou imobilizado AP",
                "Complemento2": "",
                "Id": "6.101.05",
                "Complemento1": "Estoque",
                "DataHoraAlteracao": "2022-05-16T07:41:31.014",
                "Situacao": "Ativo",
                "TipoOperacao": {
                    "$id": 4,
                    "Id": 2,
                    "Nome": "VENDA",
                    "CodigoIntegracao": ""
                }
            },
            "PrecoUnitarioComercial": 13.767,
            "QuantidadeComercial": 1,
            "UnidadeMedidaComercial": "UND",
            "FatorConvUndCom": 1
        },
        {
            "group" : "",
            "TotalItem": 0,
            "IdentificacaoProjeto": "",
            "Acrescimo": 0,
            "Desconto": 0,
            "DescontoEspecial": 0,
            "UnidadeMedida": "UND",
            "Id": 5,
            "PrecoUnitario": 13.767,
            "Item": 5,
            "PrecoTabela": 13.767,
            "Quantidade": 1,
            "CodProduto": 3174,
            "Referencia": "3174-VP",
            "NomeProduto": "TAMPA SAIDA DUPLA",
            "Observacoes": "",
            "IndexItemFiname": 0,
            "TabelaPrecoId": 82,
            "PrecoTabelaComDesconto": 13.767,
            "Atributos": [],
            "FreteUnitario": 0,
            "FreteItem": 0,
            "FreteUnitarioCalculado": 0,
            "FreteItemCalculado": 0,
            "Cfop": {
                "$id": 3,
                "TipoFaturamento": "Normal",
                "ReplicadorConfig": "False",
                "ClasseInterna": "",
                "Descricao": "Venda de produção - Consumo ou imobilizado AP",
                "Complemento2": "",
                "Id": "6.101.05",
                "Complemento1": "Estoque",
                "DataHoraAlteracao": "2022-05-16T07:41:31.014",
                "Situacao": "Ativo",
                "TipoOperacao": {
                    "$id": 4,
                    "Id": 2,
                    "Nome": "VENDA",
                    "CodigoIntegracao": ""
                }
            },
            "PrecoUnitarioComercial": 13.767,
            "QuantidadeComercial": 1,
            "UnidadeMedidaComercial": "UND",
            "FatorConvUndCom": 1
        },
        ,
        {
            "TotalItem": 0,
            "IdentificacaoProjeto": "",
            "Acrescimo": 0,
            "Desconto": 0,
            "DescontoEspecial": 0,
            "UnidadeMedida": "UND",
            "Id": 6,
            "PrecoUnitario": 13.767,
            "Item": 6,
            "PrecoTabela": 13.767,
            "Quantidade": 1,
            "CodProduto": 3174,
            "Referencia": "3174-VP",
            "NomeProduto": "TAMPA SAIDA DUPLA",
            "Observacoes": "",
            "IndexItemFiname": 0,
            "TabelaPrecoId": 82,
            "PrecoTabelaComDesconto": 13.767,
            "Atributos": [],
            "FreteUnitario": 0,
            "FreteItem": 0,
            "FreteUnitarioCalculado": 0,
            "FreteItemCalculado": 0,
            "Cfop": {
                "$id": 3,
                "TipoFaturamento": "Normal",
                "ReplicadorConfig": "False",
                "ClasseInterna": "",
                "Descricao": "Venda de produção - Consumo ou imobilizado AP",
                "Complemento2": "",
                "Id": "6.101.05",
                "Complemento1": "Estoque",
                "DataHoraAlteracao": "2022-05-16T07:41:31.014",
                "Situacao": "Ativo",
                "TipoOperacao": {
                    "$id": 4,
                    "Id": 2,
                    "Nome": "VENDA",
                    "CodigoIntegracao": ""
                }
            },
            "PrecoUnitarioComercial": 13.767,
            "QuantidadeComercial": 1,
            "UnidadeMedidaComercial": "UND",
            "FatorConvUndCom": 1
        },
        {
            "TotalItem": 0,
            "IdentificacaoProjeto": "aaaaaa",
            "Acrescimo": 0,
            "Desconto": 0,
            "DescontoEspecial": 0,
            "UnidadeMedida": "UND",
            "Id": 7,
            "PrecoUnitario": 13.767,
            "Item": 7,
            "PrecoTabela": 13.767,
            "Quantidade": 1,
            "CodProduto": 3174,
            "Referencia": "3174-VP",
            "NomeProduto": "TAMPA SAIDA DUPLA",
            "Observacoes": "",
            "IndexItemFiname": 0,
            "TabelaPrecoId": 82,
            "PrecoTabelaComDesconto": 13.767,
            "Atributos": [],
            "FreteUnitario": 0,
            "FreteItem": 0,
            "FreteUnitarioCalculado": 0,
            "FreteItemCalculado": 0,
            "Cfop": {
                "$id": 3,
                "TipoFaturamento": "Normal",
                "ReplicadorConfig": "False",
                "ClasseInterna": "",
                "Descricao": "Venda de produção - Consumo ou imobilizado AP",
                "Complemento2": "",
                "Id": "6.101.05",
                "Complemento1": "Estoque",
                "DataHoraAlteracao": "2022-05-16T07:41:31.014",
                "Situacao": "Ativo",
                "TipoOperacao": {
                    "$id": 4,
                    "Id": 2,
                    "Nome": "VENDA",
                    "CodigoIntegracao": ""
                }
            },
            "PrecoUnitarioComercial": 13.767,
            "QuantidadeComercial": 1,
            "UnidadeMedidaComercial": "UND",
            "FatorConvUndCom": 1
        }
    ]

    const [datalist, setDataList] = useState(itens);

    useEffect(() => {

        const ordenado = itens.sort((item1, item2) => {
            return item1.IdentificacaoProjeto.toLowerCase().localeCompare(item2.IdentificacaoProjeto.toLowerCase());
        })
        let lastgroup = "";
        const listanovaagrupada = ordenado.map((item, index) => {
            let group = null;
            if (lastgroup !== item.IdentificacaoProjeto) {
                lastgroup = item.IdentificacaoProjeto;
                group = item.IdentificacaoProjeto;
            }

            return {
                ...item,
                Item: index + 1,
                group : group  
            }
        })

        setDataList(listanovaagrupada);
    }, [])

    return (
        <>
            <ListComp
                data={itens}
                columns={columnsItens}

            />

            <ListComp2
                columns={columnsItens}
                data={datalist}
                msgAviso={true}
                buttons={btns}
                onGetStyle={(itemData) => {
                    if ((itemData?.SituacaoProduto === "Inativo") || (itemData?.SituacaoProduto === "Bloqueado")) {
                        return { background: "red", color: 'white' }
                    }
                    return null
                }}

            />

            <ListComp
                data={itens}
                columns={columnsItens}
                buttons={btns}
                onGetStyle={(itemData) => {
                    if ((itemData?.SituacaoProduto === "Inativo") || (itemData?.SituacaoProduto === "Bloqueado")) {
                        return { background: "red", color: 'white' }
                    }
                    return null
                }}
            />
            <PaginationControl
                pages={10}
                onPageSelect={(number) => {
                    console.log(number)
          }}
          />
            
        </>
    )
}

export default ExampleItensList;


/*

const columnsItens = [
            {
                field: 'Item',
                headerName: 'Item',
                width: 40,
                key: true
            },
            {
                field: 'CodProduto',
                headerName: 'Cód Produto',
                width: 40
            },
            {
                field: 'Referencia',
                headerName: 'Referencia',
                width: 40
            },
            {
                field: 'NomeProduto',
                headerName: 'Nome Produto',
                width: 40
            },
            {
                field: 'UnidadeMedida',
                headerName: 'Und',
                width: 40
            },
            {
                field: 'PrecoTabela',
                headerName: 'Preco Tabela',
                width: 40
            },
            {
                field: 'PrecoTabelaComDesconto',
                headerName: 'Preço Tabela com Desconto',
                width: 40
            },
            {
                field: 'PrecoUnitario',
                headerName: 'Preço Unitario',
                width: 40
            },
            {
                field: 'Quantidade',
                headerName: 'Quantidade',
                width: 40
            },
            {
                field: 'TotalItem',
                headerName: 'TotalItem',
                width: 40
            },
        ]

        const btns = [
            {
                text: "Edit",
                onClick: (arg) => {
                    //setItemSelected(itens[parseInt(arg.target.value)])
                    setIndexItemSelected(parseInt(arg.target.value));
                    setModalItem(true);
                }
            },
            {
                text: "Del",
                onClick: (arg) => {
                    const newList = values.OrcamentoVenda.Itens.filter((item, i) =>
                        parseInt(arg.target.value) !== i
                    );
                    setFieldValue("OrcamentoVenda.Itens", newList);
                    setItens(newList);

                }
            }

        ]

        return (
            <>
                <Row>
                    <ListComp
                        data={itens}
                        columns={columnsItens}
                        buttons={btns}
                    />
                </Row>
            </>
        )

*/