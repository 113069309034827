import { useState, useContext } from "react";
import AtributoItemSelectControl from "./atributo.AtributoItemSelectControl";
import AtributoItemDecimalControl from "./atributo.ItemDecimalControl";
import AtributoItemLogicoControl from "./atributo.ItemLogicoControl";
import AtributoItemTextoControl from "./atributo.ItemTexto";
import { ClienteContext } from "../../contexts/clientes-context";
import { useFormikContext } from "formik";
import { Badge } from "react-bootstrap";

const AtributosCliente = (props) => {


    const [atributos, setAtributos] = useState(props.atributos ?? props.configAtributosCliente);
    const [saidaAtributosItem, setSaidaAtributosItem] = useState(props.atributos ?? props.configAtributosCliente);

    const { setValues, errors, values, setFieldValue } = useFormikContext();

    const {
        updateAtributos,
    } = useContext(ClienteContext);

    function HandleItemChange(item, index, tipo) {
        const listaitenssaida = saidaAtributosItem?.filter((itemfind, idx) => {
            return itemfind.Atributo?.Id !== item.Atributo?.Id
        });

        const novos_atributos = saidaAtributosItem.map((item_item, index_item) => {
            if (index === index_item) {
                return item
            }
            return item_item;
        })
        setSaidaAtributosItem(novos_atributos);
        updateAtributos(novos_atributos, setValues, values, setFieldValue);
    }

    function GetAtributoItem(item, index, tipo) {
        const item_saida = saidaAtributosItem?.find((item_saida, index_saida) => {
            return item_saida.Atributo.Id === item.Atributo.Id;
        });
        const itemvaluename = `Atributos[${index}].Valor`;
        let retorno;

        if (item.Valores?.length > 0) {
            retorno =
                <AtributoItemSelectControl
                    key={index}
                    name={itemvaluename}
                    required={item?.Atributo?.Obrigatorio}
                    disabled={props?.somenteLeitura}
                    listvalues={item.Valores}
                    atributoItemEntrada={item_saida}
                    index={index}
                    tipo={tipo}
                    onItemChange={HandleItemChange}
                />
        } else if (item.Atributo.Tipo === "Texto") {
            retorno =
                <AtributoItemTextoControl
                    key={index}
                    name={itemvaluename}
                    required={item?.Atributo?.Obrigatorio}
                    disabled={props?.somenteLeitura}
                    atributoItemEntrada={item_saida}
                    index={index}
                    tipo={tipo}
                    onItemChange={HandleItemChange}
                />
        } else if (item.Atributo.Tipo === "Decimal") {
            retorno =
                <AtributoItemDecimalControl
                    key={index}
                    name={itemvaluename}
                    required={item?.Atributo?.Obrigatorio}
                    disabled={props?.somenteLeitura}
                    atributoItemEntrada={item_saida}
                    index={index}
                    tipo={tipo}
                    onItemChange={HandleItemChange}
                />
        } else if (item.Atributo.Tipo === "Inteiro") {
            retorno =
                <AtributoItemDecimalControl
                    key={index}
                    name={itemvaluename}
                    required={item?.Atributo?.Obrigatorio}
                    disabled={props?.somenteLeitura}
                    atributoItemEntrada={item_saida}
                    index={index}
                    tipo={tipo}
                    onItemChange={HandleItemChange}
                />
        } else if (item.Atributo.Tipo === "Lógico") {
            retorno =
                <AtributoItemLogicoControl
                    key={index}
                    name={itemvaluename}
                    required={item?.Atributo?.Obrigatorio}
                    disabled={props?.somenteLeitura}
                    atributoItemEntrada={item_saida}
                    index={index}
                    tipo={tipo}
                    onItemChange={HandleItemChange}
                />
        }

        return (<>
            {retorno}
            {errors?.Atributos && errors?.Atributos[index]?.Valor && <Badge bg="danger">{errors.Atributos[index].Valor}</Badge>}
        </>)
    }

    return (
        <>
            {atributos &&
                atributos?.map((item, index) => {

                    return (
                        <>
                            <h6>{item.Atributo.NomeExibicao}</h6>
                            {GetAtributoItem(item, index, "cliente")}
                        </>
                    );
                })}
        </>
    );
}


export default AtributosCliente;
