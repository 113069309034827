import { useEffect, useState } from "react";
import ProdutoService from "../../services/service.produto";
import PedidoService from "../../services/service.pedido";
import T from "../../common/traducao";
import { Image } from "react-bootstrap";
import { Button } from "react-bootstrap";
import {
  Box,
  Grid,
  Paper,
  Switch,
  Typography,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import ListComp2 from "../../common/listcomp2";
import ListComp from "../../common/listcomp";
import { ExpandComponent } from "../controls_comp/control-Expand";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { LoadingProgress } from '../../components/ui_comp/Loading'

const NewComposicaoProdutoProblemaControl = (props) => {
  const {
    onItemSelected,
    confirmItem,
    id,
    idBusca,
    idItem,
    idItemBusca,
  } = props;

  const constantes = T();

  const columns_images = [
    {
      field: "action",
      headerName: "",
      width: 90,
      key: true,
      type: "expand",
      fieldExpand: "Composicao"
    },
    {
      field: "ProdutoId",
      headerName: constantes.Codigo,
      width: 90,
      key: true,
    },
    {
      field: "ProdutoNome",
      headerName: constantes.Nome,
      width: 90,
    },
    {
      field: "ProdutoReferencia",
      headerName: constantes.Referencia,
      width: 90,
    },
    {
      field: "ProdutoUnidadeMedida",
      headerName: constantes.UnidadeMedida,
      width: 90,
    },
    {
      field: "Quantidade",
      headerName: constantes.Quantidade,
      width: 150,
      digits: 4,
    },
    {
      field: "Imagem",
      headerName: constantes.Imagem,
      width: 250,
      type: "image",
    }
  ];

  const columns_sem_images = [
    {
      field: "action",
      headerName: "",
      width: 90,
      key: true,
      type: "expand",
      fieldExpand: "Composicao"
    },
    {
      field: "ProdutoId",
      headerName: constantes.Codigo,
      width: 90,
      key: true,
    },
    {
      field: "ProdutoNome",
      headerName: constantes.Nome,
      width: 90,
    },
    {
      field: "ProdutoReferencia",
      headerName: constantes.Referencia,
      width: 90,
    },
    {
      field: "ProdutoUnidadeMedida",
      headerName: constantes.UnidadeMedida,
      width: 90,
    },
    {
      field: "Quantidade",
      headerName: constantes.Quantidade,
      width: 150,
      digits: 4,
    }
  ];

  // const params = useParams();

  const [produto, setProduto] = useState();
  const [errorProduto, setErrorProduto] = useState(false);
  const [composicao, setComposicao] = useState();
  const [pages, setPages] = useState();
  const [showimages, setShowImages] = useState(false);

  const [imagemproduto, setImagemProduto] = useState();
  const [selecionarItemCompleto, setSelecionarItemCompleto] = useState(false);
  const [expand, setExpand] = useState({});
  const [loading, setLoading] = useState(false)

  async function GetProduto(id, idBusca) {
    if (id) {
      await ProdutoService.get(id)
        .then((response) => {
          const traducao = ProdutoService.getProdutoTraduzido(response.data);
          const produto = { ...response.data, Nome: traducao };
          setProduto(produto);
        })
        .catch((error) => {
          console.log(error);
          setErrorProduto(true);
        })
        .finally();
    } else if (idBusca) {
      await ProdutoService.get(idBusca)
        .then((response) => {
          const traducao = ProdutoService.getProdutoTraduzido(response.data);
          const produto = { ...response.data, Nome: traducao };
          setProduto(produto);
        })

        .catch((error) => {
          console.log(error);
          toast.error(error);
          setErrorProduto(true);
        })

        .finally();
    }
  }

  async function GetImagemProduto(id, idBusca) {
    if (id) {
      await ProdutoService.getImage(id)
        .then((response) => {
          setImagemProduto(response.request.responseURL);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error);
          setErrorProduto(true);
        })
        .finally();
    } else if (idBusca) {
      await ProdutoService.getImage(idBusca)
        .then((response) => {
          setImagemProduto(response.request.responseURL);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error);
          setErrorProduto(true);
        })
        .finally();
    }
  }

  async function GetComposicao(id, idBusca) {
    setLoading(true)
    if (id) {
      await ProdutoService.getComposicaoAssistencia(id)
        .then((response) => {
          setComposicao(response.data.value);
          setPages(response.data.pages);
          setLoading(false)
        })
        .catch((error) => {
          console.log(error);
          toast.error(error);
          setErrorProduto(true);
          setLoading(false)
        })
        .finally(() => setLoading(false));
    } else if (idBusca) {
      await ProdutoService.getComposicaoAssistencia(idBusca)
        .then((response) => {
          setComposicao(response.data.value);
          setPages(response.data.pages);
          setLoading(false)
        })
        .catch((error) => {
          console.log(error);
          toast.error(error);
          setErrorProduto(true);
          setLoading(false)
        })
        .finally(() => setLoading(false));
    }
  }

  useEffect(() => {
    GetProduto(id, idBusca);
    GetImagemProduto(id, idBusca);
    GetComposicao(id, idBusca);
  }, []);

  const handleItemSelected = (item) => {
    onItemSelected(item);
  };

  useEffect(() => {
  }, [composicao])

  const toggleExpand = (produtoId) => {
    setExpand((prevExpand) => ({
      ...prevExpand,
      [produtoId]: !prevExpand[produtoId],
    }));
  };

  const listWithActionExpand =
    composicao &&
    composicao
      .map((item) => {
        return {
          ...item,
          Imagem: showimages
            ? `${process.env.REACT_APP_BASE_URL}${item.Imagem}`
            : null,
        };
      });

  const GetLista = (props) => {
    return (
      <>
        {!props.data && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
          >
            <LoadingProgress labelLoading={constantes.Carregando} />
          </Box>
        )}
        {props.data && props.data.length > 0 &&
          <ListComp2
            selecionar={true}
            onItemSelected={handleItemSelected}
            data={props.data}
            columns={props.columns}
            activeExpand={props.activeExpand ?? true}
            //expandList={props.data?.Composicao}
            onExpanded={(itemdata) => {
              const itens_data = itemdata.Composicao &&
              itemdata.Composicao
                  .map((item) => {
                    return {
                      ...item,
                      Imagem: showimages
                        ? `${process.env.REACT_APP_BASE_URL}${item.Imagem}`
                        : null,
                    };
                  })
              return (
                <GetLista data={itens_data} columns={props.columns} activeExpand={true} />
              )
            }}
          />}
      </>
    )
  }

  return (
    <>
      <Grid container gap={1.5} sx={{ width: "100%" }}>
        <Grid
          component={Paper}
          item
          xs={15}
          sm={15}
          md={15}
          lg={15}
          xl={15}
          padding={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            },
            gap: { xs: 1, sm: 1, md: 5, lg: 5, xl: 5 },
            width: "100vw",
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <Grid sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box>
                <Typography sx={{ color: "#4c4c4f", fontWeight: "bold" }}>
                  {constantes.Codigo}
                </Typography>
                <Typography>{produto?.Id}</Typography>
              </Box>

              <Box>
                <Typography sx={{ color: "#4c4c4f", fontWeight: "bold" }}>
                  {constantes.Referencia}
                </Typography>
                <Typography>{produto?.Referencia}</Typography>
              </Box>
            </Grid>

            <Grid sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box>
                <Typography sx={{ color: "#4c4c4f", fontWeight: "bold" }}>
                  {constantes.Nome}
                </Typography>
                <Typography>{produto?.Nome}</Typography>
              </Box>

              <Box>
                <Typography sx={{ color: "#4c4c4f", fontWeight: "bold" }}>
                  {constantes.Observacao}
                </Typography>
                <Typography>{produto?.Observacao}</Typography>
              </Box>
            </Grid>
          </Box>

          <Box>
            <Button
              onClick={() => {
                confirmItem();
              }}
              style={{
                background: "#008975",
                width: "100%",
                height: "35px",
                border: "none",
                color: "white",
                transtion: "all 0.3s ease-in-out",
                "&:hover": {
                  transtion: "all 0.5s ease-in-out",
                  background: "#007765",
                },
              }}
            >
              {constantes?.SelecionarItemVendido}
            </Button>
          </Box>
        </Grid>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              padding: "1rem 0",

            }}
          >
            <LoadingProgress labelLoading={constantes.Carregando} />
          </Box>

        ) : (
          <>
            {composicao && composicao.length > 0 ? (
              <Grid
                component={Paper}
                item
                xl={12}
                padding={1}
                sx={{ width: "100%" }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: {
                      xs: "column",
                      sm: "column",
                      md: "column",
                      lg: "row",
                    },
                  }}
                >
                  <Grid
                    item
                    sx={{
                      marginTop: 5.5,
                      width: "30%",
                    }}
                  >
                    <Image
                      thumbnail
                      width={"100%"}
                      height={300}
                      src={imagemproduto}
                      rounded
                    />
                  </Grid>

                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Box>
                      <FormGroup
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              value={showimages}
                              checked={showimages}
                              onChange={(e) => {
                                setShowImages(e.target.checked);
                              }}
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  color: "#666666",
                                  fontSize: 12,
                                  "@media (max-width: 550px)": {
                                    fontSize: 10,
                                  },
                                },
                              }}
                            />
                          }
                          label="Exibir Imagens"
                        />
                      </FormGroup>
                    </Box>
                    <GetLista data={listWithActionExpand} columns={showimages ? columns_images : columns_sem_images} />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "50px",
                }}
              >
                <Typography
                  sx={{ fontSize: 16, fontWeight: "bold", color: "#424244" }}
                >
                  {" "}
                  {constantes.MsgItemNaoPossuiComponentes}
                </Typography>
              </Box>
            )}
          </>
        )


        }

      </Grid>
    </>
  );
};

export default NewComposicaoProdutoProblemaControl;
