import { React, useEffect, useState } from "react";
import { Formik, useFormikContext } from "formik";
import * as Yup from 'yup';
import { Alert, Button, Form } from "react-bootstrap";
import EditControl from "../controls_comp/control-edit";
import ProdutoService from "../../services/service.produto";
import {LoadModal} from "../ui_comp/Loading/index";
import ErrosModal from "../../errors/ModalErrors";
import T from "../../common/traducao";
import NewSearchListProdutosFiname from "../searchs_comp/search-List.Produtos.Finame";

const initialValues = {
    Distancia: 0,
    ItemOrcamentoVenda: {
        Item: 1,
        Id: 0,
        Quantidade: 1,
        ItemOrcamentoVenda: 1,
        CodProduto: "",
        NomeProduto: "",
    }
}

const ErrorObserver = (props) => {
    const { errors, isValid, setErrors,submitCount } = useFormikContext();
    const constantes = T()
    const errorsArray = errors?.ItemOrcamentoVenda;

    const _retorno =
        props.somenteLeitura || isValid || submitCount === 0 ?
            null
            :
            <Alert variant="danger" onClose={() => setErrors({})} dismissible>
                <Alert.Heading>{constantes.Errospendentes}</Alert.Heading>
                <p>
                    <ul>
                        {errorsArray?.CodProduto && <li>{errorsArray?.CodProduto}</li>}
                        
                        {errorsArray?.Quantidade && <li>{errorsArray?.Quantidade}</li>}

                    </ul>

                </p>
            </Alert>;

    return _retorno;
}


// let _retorno =
//         (props.somenteLeitura || isValid) ? null :

//             submitCount === 0 ? null :
//                 (
//                     <>
//                         <Alert variant="danger" onClose={() => setErrors({})} dismissible>
//                             <Alert.Heading>Existem alguns erros pendentes</Alert.Heading>

//                             <ul>
//                                 {errorsArray?.FormaPagamento && <li>{errorsArray?.FormaPagamento}</li>}

//                                 {errorsArray?.Municipio && <li>{errorsArray?.Municipio}</li>}

//                                 {errorsArray?.TabelaDesconto && <li>{errorsArray?.TabelaDesconto}</li>}

//                                 {errorsArray?.TabelaPreco && <li>{errorsArray?.TabelaPreco}</li>}

//                                 {errorsArray?.TipoFrete && <li>{errorsArray?.TipoFrete}</li>}

//                                 {errorsArray?.TipoContribuinte && <li>{errorsArray?.TipoContribuinte}</li>}

//                                 {errorsArray?.IndicadorIe && <li>{errorsArray?.IndicadorIe}</li>}

//                                 {errorsArray?.TipoOperacao && <li>{errorsArray?.TipoOperacao}</li>}

//                                 {validacoes &&
//                                     validacoes.map((item) => {
//                                         return <li>{item}</li>
//                                     })

//                                 }
//                             </ul>
//                         </Alert>
//                     </>
//                 );


//     return _retorno;


function SetDadosProduto(props, itemorcamentovenda, setFieldValue, setFieldTouched, isSubmitting) {
    //const { onLoad, onError, setCfop } = props;
    setFieldValue('ItemOrcamentoVenda.NomeProduto', itemorcamentovenda.Nome);
}




const FormObserverProduto = (props) => {
    const { values, setFieldValue, setFieldTouched, isSubmitting } = useFormikContext();
    const { onLoad, onError, setCfop } = props;

    useEffect(() => {
        setFieldValue("ItemOrcamentoVenda.CodProduto", values.ItemOrcamentoVenda.CodProdutoNovo);

        if (values.ItemOrcamentoVenda.CodProdutoNovo && (!isSubmitting)) {
            onLoad && onLoad(true)

            ProdutoService.get(values.ItemOrcamentoVenda.CodProdutoNovo)
                .then(response => {
                    const itemorcamentovenda = response.data;
                    SetDadosProduto(props, itemorcamentovenda, setFieldValue, setFieldTouched, isSubmitting);
                }
                )
                .catch(error => {
                    const _error = error?.response?.data ? error.response.data.error : error;
                    onError && onError(_error);
                })
                .finally(() => {
                    onLoad && onLoad(false);
                })
                ;
        }
    },
        [values.ItemOrcamentoVenda?.CodProdutoNovo]);


}
/*
const FormObserverProduto = (props) => {
    const { values, touched, setFieldValue,  setFieldTouched } = useFormikContext();
    const { onLoad, onError } = props;

    useEffect(() => {

        touched.ItemOrcamentoVenda?.CodProduto &&
            values.ItemOrcamentoVenda?.CodProduto &&
            onLoad(true);

        setTimeout(() => {
            touched.ItemOrcamentoVenda?.CodProduto &&
                values.ItemOrcamentoVenda?.CodProduto &&
                ProdutoService.get(values.ItemOrcamentoVenda.CodProduto)
                    .then(response => {
                        setFieldValue('ItemOrcamentoVenda.CodProduto', response.data.Id);
                        setFieldValue('ItemOrcamentoVenda.NomeProduto', response.data.Nome);

                        //setFieldValue('OrcamentoVenda.ItemOrcamentoVenda.Produto', { Id: response.data.Id, Nome: response.data.Nome });

                        setFieldTouched("ItemOrcamentoVenda.CodProduto", false);

                        onLoad(false);
                    }
                    )
                    .catch(error => {
                        const _error = error && error.response && error.response.data ? error.response.data.error : error;
                        onError(_error);
                        setFieldTouched("ItemOrcamentoVenda.CodProduto", false);
                        setFieldValue('ItemOrcamentoVenda.CodProduto', "");
                        setFieldValue('ItemOrcamentoVenda.NomeProduto', "");
                        onLoad(false);
                    });
        }, 200)

    }, [touched.ItemOrcamentoVenda?.CodProduto]);
};
*/

const ItemFinameOrcamentoVendaNew = (props) => {

    const { selectedItem, somenteLeitura, idTabelaPreco } = props;

    const _initialvalues = { ItemOrcamentoVenda: selectedItem };

    const constantes = T();

    const [load, setLoad] = useState(false);
    const [loadErro, setLoadErro] = useState(false);
    const [erros, setErros] = useState(null);

    useEffect(() => {
        if (erros) {
            setLoadErro(true);
        }
    }, [erros]);

    function onToogle() {
        setLoad(prevvalue => !prevvalue);
    }

    function onLoad(loading) {
        setLoad(loading);
    }

    function onError(error) {
        const _error =
            error.code === "ERR_NETWORK"
                ? constantes.ServerOffLine
                : error.response && error.response.data && error.response.data.error
                    ? error.response.data.error.message
                    : error.message;
        setErros(_error);
    }

    function onToogleErro() {
        setLoadErro(prevvalue => !prevvalue);
        setErros(null);
    }


    return (
        <div>
            <h1>{constantes.ItensFiname} </h1>
            <Formik
                enableReinitialize={true}
                initialValues={selectedItem ? _initialvalues : initialValues}

                validationSchema={Yup.object().shape({
                    ItemOrcamentoVenda: Yup.object({
                        CodProduto: Yup.string().required("Produto Obrigatório"),
                        Quantidade: Yup.string().required("Quantidade Obrigatória"),
                    })
                })}


                onSubmit={(values, { setSubmitting, resetForm }) => {

                    selectedItem ?
                        props.onEditItem(values.ItemOrcamentoVenda) :
                        props.onAddItem(values.ItemOrcamentoVenda);
                    setSubmitting(false);
                    resetForm();
                }}

            >
                {({ values, errors, touched, setFieldValue, setFieldTouched, handleSubmit }) =>

                    <Form onSubmit={handleSubmit}>
                        <ErrorObserver somenteLeitura={somenteLeitura} />
                        <FormObserverProduto
                            onLoad={onLoad}
                            onError={onError}
                        />
                        <LoadModal onHide={onToogle} show={load} />
                        <ErrosModal onHide={onToogleErro} show={loadErro} erro={erros} />
                        <Form.Group>
                            {values.ItemOrcamentoVenda.Id > 0 &&
                                <EditControl name="ItemOrcamentoVenda.Id" placeholder="Id" type="number" disabled />
                            }
                            <EditControl name="ItemOrcamentoVenda.Item" placeholder={constantes.Item} type="number" disabled />
                            <NewSearchListProdutosFiname
                                disabled={somenteLeitura}
                                nameId="ItemOrcamentoVenda.CodProduto"
                                nameDesc="ItemOrcamentoVenda.NomeProduto"
                                placeholder={constantes.Produto}
                                onLoad={onLoad}
                                onError={onError}
                                onItemSelected={
                                    (item) => {
                                        setFieldValue("ItemOrcamentoVenda.CodProdutoNovo", item.Id);
                                        //  setFieldValue('ItemOrcamentoVenda.CodProduto', item.Id);
                                        //  setFieldValue('ItemOrcamentoVenda.NomeProduto', item.Nome);
                                    }
                                }
                            />
                            <EditControl disabled={somenteLeitura} name="ItemOrcamentoVenda.Quantidade" placeholder={constantes.Quantidade} type="number" />

                        </Form.Group>

                        <Button sx={{backgroundColor:"#009180",color:"white", border: "1px solid rgba(76,77,76,0.1550595238095238)"}} disabled={somenteLeitura} type="submit" >{constantes.Confirmar}</Button>

                    </Form>

                }
            </Formik >

        </div >
    )
}


export default ItemFinameOrcamentoVendaNew;
