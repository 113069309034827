import { Badge, Col, Row } from "react-bootstrap";
import EditControl from "../controls_comp/control-edit";
import DateControl from "../controls_comp/control-Date";
import SearchListMunicipios from "../searchs_comp/search-List.Municipios";
import { useContext } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import IndicadorIeControl from "../controls_comp/control-IndicadorIe";
import NewTipoContribuinteControl from "../controls_comp/control-tipocontribuinte";
import { ErrorMessage, useFormikContext } from "formik";
import CampoSearchListClientesAtivos from "../campos_comp/campo-searchlist-clientesAtivos";

const OrcamentoVendaIdentificacaoCtx = () => {

    const {
        orcamentoVenda,
        somenteLeitura,
        constantes,
        handleError,
        handleLoad,
        idOrcamentoDuplicado,
        newWeb,
        configuracoes,
        updateCliente
    } = useContext(OrcamentoVendaContext);

    const {
        values,
        setFieldValue,
        resetForm,
    } = useFormikContext();


    const bloqueartrocacliente =
        orcamentoVenda.Cliente !== null &&
        orcamentoVenda?.Itens?.length > 0;

    return (
        <Row>
            {orcamentoVenda.Municipio &&
                <Col md={3} sm={12} className="mb-3">
                    <EditControl
                        name="OrcamentoVenda.Id"
                        placeholder={constantes?.Numero}
                        type="number"
                        disabled
                    />
                    {idOrcamentoDuplicado && <small>{constantes.DuplicadodoOrcamento} {idOrcamentoDuplicado}</small>}
                </Col>}

            {orcamentoVenda.Municipio && <Col md={3} sm={12} className="mb-3">
                <EditControl
                    name="OrcamentoVenda.Situacao"
                    placeholder={constantes.Situacao}
                    type="text"
                    disabled
                />
            </Col>}
            {orcamentoVenda.Municipio && <Col md={3} sm={12} className="mb-3">
                <DateControl
                    name="OrcamentoVenda.DataValidade"
                    placeholder={constantes?.DataValidade}
                    // errors={errors}
                    min={new Date().toISOString().slice(0, 10)}
                    max={values.OrcamentoVenda.DataLimiteValidade}
                    selected={values.OrcamentoVenda?.DataValidade}
                    disabled={somenteLeitura}
                />
            </Col>
            }
            {orcamentoVenda.Municipio && <Col md={3} sm={12} className="mb-3">
                <DateControl
                    name="OrcamentoVenda.DataPrevisaoFaturamento"
                    placeholder={constantes?.DataPrevisaoFaturamento}
                    min={new Date().toISOString().slice(0, 10)}
                    selected={values.OrcamentoVenda?.DataPrevisaoFaturamento}
                    disabled={somenteLeitura || !configuracoes.ConfiguracaoWeb.HabilitarPrevFaturamento}
                />
            </Col>}
            <Col xs={12} md={12} className="mb-3">
                <CampoSearchListClientesAtivos
                    disabled={somenteLeitura || bloqueartrocacliente}
                    disabledDesc={somenteLeitura || bloqueartrocacliente}
                    nameId="OrcamentoVenda.Cliente.Id"
                    nameDesc="OrcamentoVenda.NomeCliente"
                    placeholder={constantes?.Clientes}
                    onLoad={handleLoad}
                    onError={handleError}
                    onItemSelected={(item) => {
                        const cliente = item ? { Id: item.Id } : null;

                        const orcamento_new_web = {
                            ...values.OrcamentoVenda,
                            Cliente: cliente
                        }
                        newWeb(orcamento_new_web, setFieldValue, values);
                    }}
                    onItemClear={() => {
                        setFieldValue("OrcamentoVenda.Cliente", null)
                    }}
                />
            </Col>

            <Col xs={12} md={12} className="mb-3">
                <SearchListMunicipios
                    disabled={
                        somenteLeitura ||
                        values.OrcamentoVenda?.Cliente?.Id > 0
                    }
                    nameId="OrcamentoVenda.Municipio.Id"
                    nameDesc="OrcamentoVenda.Municipio.Nome"
                    placeholder={constantes?.Municipio}
                    onLoad={handleLoad}
                    onError={handleError}
                    onItemSelected={(item) => {
                        const municipio = item ? { Id: item.Id } : null;
                        const orcamento_new_web = {
                            ...values.OrcamentoVenda,
                            Municipio: municipio
                        }
                        newWeb(orcamento_new_web, setFieldValue, values);
                    }}
                />
                <ErrorMessage name={"OrcamentoVenda.Municipio"}>
                    {(msg) => (
                        <Badge bg="danger">{msg}</Badge>
                    )}
                </ErrorMessage>
            </Col>

            <Col xs={12} md={3} className="mb-3">
                <EditControl
                    name="OrcamentoVenda.CpfCnpj"
                    placeholder={constantes?.CpfCnpj}
                    type="text"
                    disabled={
                        somenteLeitura ||
                        values.OrcamentoVenda?.Cliente?.Id > 0
                    }
                />
            </Col>
            <Col xs={12} md={3} className="mb-3">
                <EditControl
                    name="OrcamentoVenda.Telefone"
                    placeholder={constantes?.Telefone}
                    type="text"
                    disabled={
                        somenteLeitura ||
                        values.OrcamentoVenda?.Cliente?.Id > 0
                    }
                />
            </Col>

            <Col xs={12} md={3} className="mb-3">
                {orcamentoVenda.Municipio &&
                    <EditControl
                        name="OrcamentoVenda.Distancia"
                        placeholder={constantes?.Distancia}
                        type="text"
                        disabled
                    />
                }
            </Col>
            <Col xs={12} md={6} className="mb-3">
                <IndicadorIeControl
                    name="OrcamentoVenda.IndicadorIe"
                    disabled={somenteLeitura ||
                        values.OrcamentoVenda?.Cliente?.Id > 0
                    }
                />
            </Col>
            <Col xs={12} md={6} className="mb-3">
                <NewTipoContribuinteControl
                    disabled={somenteLeitura ||
                        values.OrcamentoVenda?.Cliente?.Id > 0
                    }
                />
            </Col>
            <Col xs={12} md={12} className="mb-3">
                <EditControl
                    disabled={somenteLeitura}
                    name="OrcamentoVenda.ObservacaoUsuario"
                    placeholder={constantes?.ObservacaoUsuario}
                    type="text"
                />
            </Col>
        </Row>

    )

}

export default OrcamentoVendaIdentificacaoCtx;