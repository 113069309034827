import { useContext } from "react";
import { ClienteContext } from "../../contexts/clientes-context";
import { Alert, Container } from "react-bootstrap";
import { useFormik, useFormikContext } from "formik";
import {IoAlertCircle} from 'react-icons/io5'


const ClientesValidacoes = () => {

    const {
        somenteLeitura,
        constantes,
        validacoes,
        setModalValidacoes,
        modalValidacoes,
        fecharModalValidacoes
    } = useContext(ClienteContext);


    return (
      
                <div>
                    {modalValidacoes ? ( 
                         <>
                         <hr className="h-50 mb-5" />
                    <Container
                        style={{ height: '30%', width: '100%'}}   
                        className="gap-1 bottom-0 position-fixed bg-white"
                        fluid
                    >
                        
                          <Alert variant="danger" onClose={() => fecharModalValidacoes()} dismissible>
                            <Alert.Heading><IoAlertCircle style={{marginTop: -5, marginRight: 5}}/>{constantes.Campospendentes}</Alert.Heading>
                            {modalValidacoes && (
                            <ul style={{ maxHeight: "100px", overflowY: "auto" }}>
                                  {validacoes}
                            </ul>
                            )}
                        </Alert>
                        <hr className="h-50 mb-5" />
                        <hr className="h-50 mb-5" />
                    </Container>
                         </>
                    ) : ""}
                    
                </div>
        
    )

}

export default ClientesValidacoes;