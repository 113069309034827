import { useContext, useState } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import ListComp from "../../common/listcomp";
import { useFormikContext } from "formik";
import NewModalItemFinameOrcamentoVendaControl from "../ui_comp/Modals/ModalItemFinameOrcamentoVendaControl";

const OrcamentoVendaItensFinameCtx = () => {

    const {
        orcamentoVenda,
        somenteLeitura,
        constantes,
        modalItemFiname,
        updateItensFiname,
        deleteItemFiname,
        setModalItemFiname,
        exigirItensFiname
    } = useContext(OrcamentoVendaContext);


    const {
        values,
        setFieldValue,
    } = useFormikContext();

    const [indexItemFinameSelected, setIndexItemFinameSelected] = useState(null);


    const ListaItensFiname = () => {
        const columnsItens = [
            {
                field: "Item",
                headerName: "Item",
                width: 40,
                key: true,
            },
            {
                field: "CodProduto",
                headerName: "Cód Produto",
                width: 40,
            },
            {
                field: "NomeProduto",
                headerName: "Produto",
                width: 40,
            },
            {
                field: "Quantidade",
                headerName: "Quantidade",
                width: 40,
                digits: 4,
            },
        ];

        const btns = [
            {
                getBtn: (itemdata, index) => {
                    return (
                        <Button
                            value={index}
                            onClick={(arg) => {
                                setIndexItemFinameSelected(index);
                                setModalItemFiname(true);
                            }}  >
                            <FontAwesomeIcon value={index} icon={faEdit}>

                            </FontAwesomeIcon>
                        </Button>

                    )
                },
            },
            {

                getBtn: (itemdata, index) => {
                    return (
                        <Button
                            variant="danger"
                            value={index}
                            onClick={(arg) => {
                                deleteItemFiname(index, setFieldValue, values);
                            }}  >
                            <FontAwesomeIcon value={index} icon={faTrash}>

                            </FontAwesomeIcon>
                        </Button>

                    )
                },
            },
        ];

        const btns_leitura = [
            {
                text: "View",
                onClick: (arg) => {
                    setIndexItemFinameSelected(parseInt(arg.target.value));
                    setModalItemFiname(true);
                },
            },
        ];

        return (
            <>
                {!somenteLeitura && (
                    <Row>
                        <Col>
                            <Button
                                variant="outline-secondary"
                                disabled={somenteLeitura}
                                onClick={() => {
                                    setIndexItemFinameSelected(null);
                                    setModalItemFiname(true);
                                }}
                            >
                                <FontAwesomeIcon icon={faAdd} />
                                {constantes?.Incluir}...
                            </Button>
                        </Col>
                    </Row>
                )}
                <Row>
                    <ListComp
                        data={orcamentoVenda.ItensFiname}
                        columns={columnsItens}
                        buttons={somenteLeitura ? btns_leitura : btns}
                    />
                </Row>
            </>
        );
    };

    return (
        <>
            <ListaItensFiname
                disabled={somenteLeitura}
            />
            <NewModalItemFinameOrcamentoVendaControl
                somenteLeitura={somenteLeitura}
                maioritem={orcamentoVenda.ItensFiname?.length}
                selectedItem={Number(indexItemFinameSelected ?? -1) >= 0 ? orcamentoVenda.ItensFiname[indexItemFinameSelected] : null}
                toggle={() => {
                    setModalItemFiname(false);
                }}
                modal={modalItemFiname}
                onAddItem={(item) => {
                    updateItensFiname(item, -1, setFieldValue, values);
                    setModalItemFiname(false);
                }}
                onEditItem={(item) => {
                    updateItensFiname(item, indexItemFinameSelected, setFieldValue, values);
                    setModalItemFiname(false);
                }}
            />
        </>
    )
}

export default OrcamentoVendaItensFinameCtx;