import { useContext } from "react";
import {
  Box,
  Grid,
  InputLabel,
  Paper,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormikContext } from "formik";
import { ClienteContext } from "../../contexts/clientes-context";
import { GrContactInfo } from "react-icons/gr";
import CondicoesVendaControl from "../controls_comp/control-select-condicoesvenda";

export default function ClientTabCondicoesVenda() {

  const { constantes, disabled, somenteLeitura } = useContext(ClienteContext)
  const { values, setFieldValue, handleChange, handleBlur } = useFormikContext()

  return (
    <Box component={Paper}>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        height={50}
        sx={{ backgroundColor: "rgba(59, 134, 124, 0.08)" }}
      >
        <Typography variant="h1" fontSize={22} fontWeight="sembold">
          <GrContactInfo style={{ marginBottom: "5px", marginRight: "1px" }} />
          {constantes.CondicoesdeVenda}
        </Typography>
      </Box>

      <Grid container spacing={2} p={2}>
        <Grid
          spacing={2}
          container
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          alignItems="end"
        >
          <Grid item xs={12} sm={2} md={2}>

            <CondicoesVendaControl
              name="DestinoOperacao"
              disabled={somenteLeitura}
            />

            {/* <>
              <InputLabel>{constantes.DestinoOperacao}</InputLabel>
              <Select
                name="DestinoOperacao"
                disabled={disabled}
                value={values.DestinoOperacao}
                style={{ width: "100%", maxHeight: 40 }}
                onChange={(event) => {
                  handleChange(event)
                }} >
                <MenuItem value="Consumo">{constantes.Consumo}</MenuItem>
                <MenuItem value="Revenda">{constantes.Revenda}</MenuItem>
                <MenuItem value="Industrialização">{constantes.Industrializacao}</MenuItem>
                <MenuItem value="Outros">{constantes.Outros}</MenuItem>
              </Select>
            </> */}

          </Grid>

        </Grid>

      </Grid>

    </Box>
  );
}
