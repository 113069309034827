
import { useFormikContext } from "formik";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useContext } from "react";
import { ItemOrcamentoVendaContext } from "../../contexts/itemorcamentovenda-context";
import NewSelectControl from "../controls_comp/control-selectcontrol.jsx";

const ItemFinameCtx = () => {

    const {
        constantes,
        somenteLeitura,
        itensFiname
    } = useContext(ItemOrcamentoVendaContext);

    const {
        values,
        setFieldValue,
        resetForm
    } = useFormikContext();

    return (
        <Container>
            <Row>
                <Col colSpan={3}>
                    <Form.Label className="mb-0">{constantes.ItensFiname}</Form.Label>
                    <NewSelectControl disabled={somenteLeitura} name="ItemOrcamentoVenda.ItemFiname" placeholder={constantes.ItemFiname}
                        hideTitle itens={itensFiname} />

                </Col>
            </Row>
        </Container>
    )
}

export default ItemFinameCtx;


