import { Divider, Grid, Typography } from "@mui/material";
import T from "../../../../common/traducao";
import MotivosSituacaoControl from "../../../controls_comp/control-MotivosSituacao";
import { Form } from "react-bootstrap";

export default function CancelarOrcamentoControl({ id, cancelamentoOrcamento, handleChange }) {

    const constantes = T();

    return (
        <>
            <Grid container display={"flex"} flexDirection={"column"} gap={1}>
                <Grid item sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography
                        sx={{ color: "#5e5e5e", fontSize: "22px" }}
                    >{`${constantes.CancelamentoOrcamento} ${id}`}</Typography>
                </Grid>
                <Divider sx={{ color: "black", background: "black" }} />
                <Grid item xl={15} sx={{ display: "flex", flexDirection: "column" }}>

                    <Typography
                        variant="h6"
                    >{`${constantes.InformeMotivoCancelamento}`}</Typography>
                    <Grid container spacing={2} padding={2}>
                        <Grid item xs={4}>
                            <Typography
                                variant="h7"
                            >{`${constantes.MotivoSituacao}`}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <MotivosSituacaoControl
                                handleChange={(motivo) => {
                                    handleChange({
                                        ...cancelamentoOrcamento,
                                        IdMotivoSituacao: Number(motivo)
                                    })
                                }}
                            />

                        </Grid>

                        <Grid item xs={4}>
                            <Typography
                                variant="h7"
                            >{`${constantes.Comentario}`}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Form.Control
                                as="textarea"
                                type="textarea"
                                onChange={(item) => {
                                    handleChange(
                                        {
                                            ...cancelamentoOrcamento,
                                            Comentario: item.target.value
                                        }
                                    )
                                }}
                            />

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

