export const format_percent_2 = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    minimumFractionDigits: 2,
});

export const format_percent_3 = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    minimumFractionDigits: 3,
});

export const format_percent_4 = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    minimumFractionDigits: 4,
});

export const format_percent_5 = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    minimumFractionDigits: 2,
});



export const format_currency_2 = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    //style: "currency",
    //currency: "BRL",
    minimumFractionDigits: 2
});

export const format_currency_3 = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    //style: "currency",
    //currency: "BRL",
    minimumFractionDigits: 3,
});

export const format_currency_4 = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    //style: "currency",
    //currency: "BRL",
    minimumFractionDigits: 4,
});

export const format_currency_5 = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    //style: "currency",
    //currency: "BRL",
    minimumFractionDigits: 5,
});