import { useContext, useEffect, useRef, useState } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";

export const useAtalhos = (handleAtalhos) => {
    useEffect(() => {
        window.addEventListener("keydown", handleAtalhos);

        return () => {
            window.removeEventListener("keydown", handleAtalhos);
        };
    },[handleAtalhos]);
};