import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import T from "../../common/traducao";
const ErrosModal = (props) => {
    const constantes = T()
    return (
        <Modal
            show={props.show}
            centered
            onHide={props.onHide}
        >
            <ModalHeader toogle={props.onHide} closeButton>Erro</ModalHeader>
            <ModalBody>
                <Alert variant="danger">
                    {props.erro}
                </Alert>

            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" onClick={props.onHide}>
                    {constantes.ok}
                </Button>
            </ModalFooter>
        </Modal>
    )
    /*:
    (
        <Toast onClose={props.onHide} show={props.show} delay={3000} autohide bg="Danger">
            <Toast.Header>
                <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                />
                <strong className="me-auto">Erro</strong>
            </Toast.Header>
            <Toast.Body >{props.erro}</Toast.Body>
        </Toast>
    )
    */
}


export default ErrosModal;