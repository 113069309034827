import { Button, Col, Container, Stack } from "react-bootstrap";
import OrcamentoVendaDescontosModal from "./orcamentovenda-descontos.modal.jsx";
import { useFormikContext } from "formik";
import { useState } from "react";
import OrcamentoVendaDescontos from "./orcamentovenda-descontos.content.modal.jsx";
import "../../styles/botoesBuscar.css";
const OrcamentoVendaDescontosModule = (props) => {
  const {
    somenteLeitura,
    configs,
    descontosTabela,
    constantes,
    setDescontos,
    permiteAplicarNaTela,
  } = props;
  const [modalDescontos, setModalDescontos] = useState(false);
  const { values } = useFormikContext();

  return (
    <>
      {!permiteAplicarNaTela && (
        <OrcamentoVendaDescontosModal
          modal={modalDescontos}
          toggle={() => setModalDescontos((prev) => !prev)}
          configs={configs}
          somenteLeitura={somenteLeitura}
          descontosTabela={descontosTabela}
          constantes={constantes}
          descontosLancados={{
            PercentualDesconto1Padrao:
              values.OrcamentoVenda.PercentualDesconto1Padrao,
            PercentualDesconto2Padrao:
              values.OrcamentoVenda.PercentualDesconto2Padrao,
            PercentualDesconto3Padrao:
              values.OrcamentoVenda.PercentualDesconto3Padrao,
            PercentualDesconto4Padrao:
              values.OrcamentoVenda.PercentualDesconto3Padrao,
            PercentualDesconto5Padrao:
              values.OrcamentoVenda.PercentualDesconto5Padrao,
            PercentualDescontoEspecialPadrao:
              values.OrcamentoVenda.PercentualDescontoEspecialPadrao,
            PercentualAcrescimoPadrao:
              values.OrcamentoVenda.PercentualAcrescimoPadrao,
            PercentualAcrescimoEspecialPadrao:
              values.OrcamentoVenda.PercentualAcrescimoEspecialPadrao,
          }}
          onAplicarDescontos={(descontos) => {
            setDescontos && setDescontos(descontos);
            setModalDescontos(false);
          }}
        />
      )}

      <Stack gap={2}>
        <OrcamentoVendaDescontos
          {...props}
          somenteLeitura={somenteLeitura || !permiteAplicarNaTela}
        ></OrcamentoVendaDescontos>

        {!somenteLeitura &&
          !permiteAplicarNaTela &&
          (configs.PermitirAlterarDescontosVenda ||
            configs.PermitirAlterarAcrescimoVenda) && (
            <>
              <hr className="h-1 mb-0" />
              <Container
                className="pt-3 pb-3 mt-2 d-flex justify-content-center align-items-center gap-1 bottom-0 bg-white"
                fluid
              >
                <Button
                  bsPrefix="ButtonStyle aplicarDescontoseAcrecimos"
                  onClick={() => setModalDescontos(true)}
                >
                  {constantes.AlterarDescontoseAcrescimos}
                </Button>
              </Container>
            </>
          )}
      </Stack>
    </>
  );
};

export default OrcamentoVendaDescontosModule;
