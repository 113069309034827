
import React, { Component, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import api from "../../config/axiosConfig/http-common";
import AtributoService from "../../services/service.atributo";
import OperacaoVendaService from "../../services/service.operacaovenda";
import TipoFreteService from "../../services/service.tabelafrete";
import IdiomaService from "../../services/service.idioma";
import RepsonsavelService from "../../services/service.responsavel";
import ConfigService from "../../services/service.config";
import LoginService from "../../services/service.login";
import T from "../../common/traducao";
import Logo from "../../assets/logo.png";
import UsuarioService from "../../services/service.usuario";
import {
  GetIdiomaSelecionado,
  SetIdiomaSelecionado,
  VERSAO,
  login,
  setTenatId,
} from "../../config/localstorageConfig/localstorage";

import { ClearStorageLogout } from "../../config/localstorageConfig/localstorage";
import { CircularProgress } from "@mui/material";

import { Input } from "../../components/ui_comp/Inputs";
import { AlertError } from '../../components/ui_comp/Alerts'
import { Image } from "../../components/ui_comp/Image";
import { ButtonDefault } from '../../components/ui_comp/Buttons'
import { BoxDefault } from "../../components/ui_comp/Container"

const SignIn = () => {
  const [user, setUser] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const [redirect, setRedirect] = useState();
  const [valido, setValido] = useState(false);
  const [loading, setLoading] = useState(false);

  const constantes = T();

  useEffect(() => {
    if (valido === true) {
      setRedirect("/");
    }
  }, [valido])

  async function handleSignIn(e) {
    e.preventDefault();
    if (!user || !password) {
      setError(constantes.PreenchaEmailESenha ?? "Preencha email e senha");
    } else {
      try {
        setLoading(true);
        setTenatId("");
        const response = await api.post("/login", { user, password });
        login(response.data.AccessToken, user);
        await AtributoService.setCache();
        await AtributoService.setCacheClientes();
        await OperacaoVendaService.setCache();
        await TipoFreteService.setCache();
        await RepsonsavelService.setCache();
        await IdiomaService.setCache("pt-br");
        await IdiomaService.setCache("es-es");
        await UsuarioService.setCachePermissoes();
        await UsuarioService.setCacheConfiguracoes();
        await ConfigService.setCacheServerVersion();
        localStorage.setItem("@hideMenu", JSON.stringify(false));
        const idiomaselecionado = GetIdiomaSelecionado();

        SetIdiomaSelecionado(idiomaselecionado ?? "pt-br");

        setValido(true);

      } catch (error) {
        const _error =
          error.code === "ERR_NETWORK"
            ? constantes.ServerOffLine
            : error.response && error.response.data && error.response.data.error
            ? error.response.data.error.message
            : error.message;
        setError(
          `${
            constantes.VerifiqueCredenciais ?? "Verifique as credenciais"
          }.${_error}`
        );
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    ClearStorageLogout();
    localStorage.setItem("@hideMenu", JSON.stringify(false));
  }, []);

  useEffect(() => {
    setError(null);
  }, [user, password]);

  return (
    <BoxDefault
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "85vh" /*estava em 800*/,
      }}
    >
      <form
        onSubmit={(event) => handleSignIn(event)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent:"center",
          width: 550,
        }}
      >
   
        <BoxDefault sx={{
          display:"flex", 
          flexDirection:"column",
  
          gap: 1.8, 
          padding: "3rem 6rem",
          width: 600,
          }}>
          <BoxDefault sx={{display:"flex",justifyContent:"center",alignItems:"center",width: "95%"}}>
            <Image
               src={Logo}
               alt="logo"
               loading="lazy"
               style={{
               width:  300,
               height: 100,
               margin: 10,
            }}
                />
          </BoxDefault>
         
          {error && <AlertError>{error}</AlertError>}

          {redirect && <Navigate to={redirect} replace={true} />}
          <Input
            inputProps={{ style: {height: "0.6rem"}}}
            placeholder={constantes.Usuario ?? "Usuário"}
            onChange={(e) => setUser(e.target.value)}
          />
          <Input
            type="password"
            inputProps={{ style: {height: "0.6rem"}}}
            placeholder={constantes.Senha ?? "Senha"}
            onChange={(e) => setPassword(e.target.value)}
          />
          
          {loading ? (
            <BoxDefault
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: 0,
              }}
            >
              <CircularProgress color="success"/>
            </BoxDefault>
          ) : (
            <ButtonDefault
              sx={{color:"white", "&:hover": { backgroundColor: "#006d00" }, backgroundColor: "green", border: "none" }}
              type="submit"
            >
              {constantes.Entrar ?? "Entrar"}
            </ButtonDefault>
          )}

          <hr />

          <small
            style={
              { textAlign: "center" } /*Alinhamento temporario adicionado*/
            }
          >
            {constantes.Versao} : {VERSAO}
          </small>
        </BoxDefault>
  
      </form>
    </BoxDefault>
    
  );
};

export default SignIn;