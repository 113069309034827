import { Box, Typography } from "@mui/material";
import { Button, Image } from "react-bootstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useEffect, useState } from "react";
import NotImage from "../../assets/sem-imagem.png";

export const ExpandComponent = ({
  dataComposicao,
  showImages,
  expand,
  toggleExpand,
}) => {
  if (dataComposicao) {
    return dataComposicao.map((item, index) => {
      const isExpanded = expand && expand[item?.$id];
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            margin: 0,
            padding: 0,
            width: "100%",
            background:"#f0f5ec"

          }}
          key={index}
        >
          <table
            style={{
              display: "flex",
              width: "100%",

              padding: 0,
            }}
          >
            <tbody style={{ width: "100%", margin: 0, padding: 0 }}>
              <tr
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "flex-start",
                  width: "100%",
                  padding: "0 0 0 0.1rem",
                  background: item.Composicao.length !== 0 ? "#FFFFB0" : "#b4f5c2", // Adicionando a cor de fundo aqui
                }}
                key={index}
              >
              
                <td>
                  {item.Composicao.length !== 0 && (
                    <Button
                      style={{
                        background: "transparent",
                        margin: 0,
                        padding: "0 0.2rem",
                        color: `${item.length === 0 ? "#797979" : "#000000"}`,
                        border: "none",
                      }}
                      onClick={() => toggleExpand(item?.$id)}
                      disabled={dataComposicao.length === 0}
                    >
                      {item.Composicao.length !== 0 && isExpanded ? (
                        <ExpandLessIcon color="black" />
                      ) : (
                        <ExpandMoreIcon color="black" />
                      )}
                    </Button>
                  )}
                </td>
                <td style={{ display: "flex", width: "10%", padding: "5px" }}>
                  {item.ProdutoId}
                </td>
                <td
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "5px 10px",
                  }}
                >
                  {item.ProdutoNome && item.ProdutoNome}
                </td>
                <td style={{ display: "flex", width: "18%", padding: "5px" }}>
                  {item.ProdutoReferencia ? (
                    item.ProdutoReferencia
                  ) : (
                    <Box sx={{ display: "flex", margin: "0 2.5rem" }}></Box>
                  )}
                </td>
                <td style={{ display: "flex", width: "12%", padding: "5px" }}>
                  {item.ProdutoUnidadeMedida}
                </td>
                <td style={{ padding: "5px" }}>{item.Quantidade}</td>
                {showImages && (
                  <>
                    {item.Imagem !== null ? (
                      <td>
                        <Image
                          width={100}
                          src={`${process.env.REACT_APP_BASE_URL}${item.Imagem}`}
                          fluid
                        />
                      </td>
                    ) : (
                      <td>
                        <Image width={100} src={NotImage} fluid />
                      </td>
                    )}
                  </>
                )}
              </tr>

              {isExpanded && (
                <tr
                  style={{
                    display: "flex",
         
                
                  }}
                >
                  <td style={{ display: "flex", width: "5%", padding: "1px", 
                    }}>
                  
                  </td>
                  <td
                    style={{
                      display: "flex",
                      flexDirection:"column",
                      width:"100%",
                      padding: 0,
                      margin: "0 0 0.1rem 0",
                  
                    }}
                  >
                    {item?.Composicao && expand && expand[item?.$id] && (
                      <ExpandComponent
                        dataComposicao={item?.Composicao}
                        showImages={showImages}
                        expand={expand}
                        toggleExpand={toggleExpand}
                      />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      );
    });
  }

  return null;
};
