
import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class AnexosService extends ServiceBase {
  getAll(id) {
    return http.get(`/assistencias/${id}/anexos`);
  }
  getAnexo(value) {
    return http.get();
  }

  post(data,id) {
    return http.post(`/assistencias/${id}/anexos`, data, { 
        headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

   postAnexosComentarios(data, idAssistencia, idComentario) {
     return http.post(`/assistencias/${idAssistencia}/comentarios/${idComentario}/anexos`, data, { 
         headers: {
         'Content-Type': 'multipart/form-data',
      },
     })
   }
 
   getAnexosComentarios(idAssistencia, idComentario) {
    return http.get(`/assistencias/${idAssistencia}/comentarios/${idComentario}/anexos`)
  }

  deleteAnexo(idAssistencia,idAnexo) {
    return http.delete(`/assistencias/${idAssistencia}/anexos/${idAnexo}`)
  }
 
}


export default new AnexosService();
