import { useEffect, useState } from "react";
import ClienteService from "../../services/service.cliente";
import PedidoService from "../../services/service.pedido";
import NewSearchBarControlFormik from "../searchs_comp/search-Bar.Control.Formik";
import T from "../../common/traducao";


const CampoSearchListPedidosCliente = (props) => {

    const [pedidos, setPedidos] = useState();
    const [pages, setPages] = useState();
    const [filter, setFilter] = useState();

    const { idCliente } = props;

    const constantes = T()
    
    const columns = [
        {
            field: 'Id',
            headerName: constantes.Codigo,
            width: 90,
            key: true
        },
        {
            field: 'DataCadastro',
            headerName: constantes.DataCadastro,
            width: 90,
            type: 'date',
            format: 'dd/MM/yyyyy'
        },
        {
            field: 'DataFaturamento',
            headerName: constantes.DataFaturamento,
            width: 90,
            type: 'date',
            format: 'dd/MM/yyyyy'
        }, 
        {
            field: 'DataPrevisaoFaturamento',
            headerName: constantes.DataPrevisaoFaturamento,
            width: 90,
            type: 'date',
            format: 'dd/MM/yyyyy'
        },       
        {
            field: 'VendedorNome',
            headerName: constantes.NomeVendedor
        },
        {
            field: 'Situacao',
            headerName: constantes.Situacao,
            width: 90
        },
        {
            field: 'ValorPedido',
            headerName: constantes.ValorPedido,
            width: 90,
            align: "right",
            digits: 2
        }
        
    ]

    const columnsFilter = [
        {
            field: 'Id',
            headerName: constantes.Codigo,
            tipo: 'number'
        },
        {
            field: 'DataCadastro',
            headerName: constantes.DataCadastro,
            width: 90,
            tipo: 'date',
            format: 'dd/MM/yyyyy'
        },
        {
            field: 'DataFaturamento',
            headerName: constantes.DataFaturamento,
            width: 90,
            tipo: 'date',
            format: 'dd/MM/yyyyy'
        }, 
        {
            field: 'DataPrevisaoFaturamento',
            headerName: constantes.DataPrevisaoFaturamento,
            width: 90,
            tipo: 'date',
            format: 'dd/MM/yyyyy'
        },       
        {
            field: 'VendedorNome',
            headerName: constantes.NomeVendedor,
            tipo: 'text'
        },
    ]

    function onLoad(loading) {
        props.onLoad(loading)
    }

    function onError(error) {
        props.onError(error)
    }

    function onSearchTitle(filter) {
        onLoad(true);
        ClienteService.pedidos(idCliente, filter)
            .then(response => {
                const pedidos = response.data.value;
                setPedidos(pedidos);
                setPages(response.data.pages);
                setFilter(filter);
            })
            .catch(e => {
                onError(e);
            })
            .finally(() => {
                onLoad(false);
            });

    }

    function onGetItem(id) {
        id && onLoad(true);
        id && PedidoService.get(id)
            .then(response => {
                if (response.response?.data.error) {
                    onError(response.response?.data.error);
                } else {
                    // const traducao = ProdutoService.getProdutoTraduzido(response.data);
                    // const produto = { ...response.data, Nome: traducao };
                    response.data && onItemSelected(response.data);
                }
            })
            .catch(e => {
                onError(e);
            })
            .finally(() => {
                onLoad(false);
            });
    }

    function onItemSelected(item) {
        props.onItemSelected(item);
    }

    function onItemClear() {
        setPedidos(null);
        props.onItemClear();
    }
    
    useEffect(() => {
        if(idCliente === ""){
            onItemSelected(null)
        }
    },[idCliente])


    return (
        <>
            <NewSearchBarControlFormik
                {...props}
                fullscreen
                pages={pages}
                onSearchTitle={onSearchTitle}
                onGetItem={onGetItem}
                onItemSelected={onItemSelected}
                onItemClear={onItemClear}
                data={pedidos}
                columns={columns}
                storagename={"Pedidos"}
                columnsFilter={columnsFilter}
            />
        </>
    )
}


export default CampoSearchListPedidosCliente;
