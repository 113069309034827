import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import { React, useEffect, useState } from "react";
import { Alert, Badge, Button, Col, Container, Stack, Table } from "react-bootstrap";
import { faEdit, faTrash, faClose, faCalculator, faPercentage } from "@fortawesome/free-solid-svg-icons";
import 'bootstrap/dist/css/bootstrap.min.css';
import LancamentoVencimentos from "./vencimentos-LancamentoVencimentos";
import OrcamentoVendaService from "../../services/service.orcamentovenda";
import "../../styles/botoesBuscar.css"

import { Debug } from "../../common/Debug";
import T from "../../common/traducao";
import { format_currency_2, format_percent_2 } from "../../common/format";


const initialvalues = {

    Valortotal: 0,
    DataBaseVencimentos: new Date(),
    Valorvencimento: 0,
    Percentualtotal: 0,
    diferenca: 0,
    vencimentofixo: false,
    edicao: -1,
    Dias: null,
    Data: (new Date()).toLocaleDateString(),
    Percentual: null,
    Valor: null,
    state: "inserindo",
    itemedicao: null,
    Vencimentos: [

    ]
}

const Recalculo = (props) => {
    const constantes = T();
    const { values, setFieldValue } = useFormikContext();

    function disabledTotalDigitado() {
        let valortotal = 0;
        for (const item in values.Vencimentos) {
            if (Object.hasOwnProperty.call(values.Vencimentos, item)) {
                const element = values.Vencimentos[item];
                valortotal = valortotal + element.ValorDigitado;
            }
        }
        return valortotal > values.Valortotal;
    }

    function disabledPercentualDigitado() {
        let percentualtotal = 0;
        for (const item in values.Vencimentos) {
            if (Object.hasOwnProperty.call(values.Vencimentos, item)) {
                const element = values.Vencimentos[item];
                percentualtotal = percentualtotal + element.PercentualDigitado;
            }
        }
        return percentualtotal > 100;
    }

    return (
        <Form>
            <div>
                <Table bordered>
                    <thead>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Field >
                                    {({ field, form }) => {
                                        return <div>valor<h5>{format_currency_2.format(props.valortotal ?? 0)}</h5></div>
                                    }}
                                </Field>
                            </td>
                            <td>
                                <Field component={ValorTotal} />
                            </td>
                            <td>
                                <Field component={Diferenca} />
                                <ErrorMessage name={"diferenca"}>
                                    {msg => <Badge bg="danger">{msg}</Badge>}
                                </ErrorMessage>

                            </td>
                            {!props.somenteLeitura &&
                                <td>
                                    <Col>
                                        <Button variant="outline-secondary" disabled={disabledTotalDigitado()} onClick={() => Recalcular(values, setFieldValue, props.DataBaseVencimentos, props.valortotal, true)}>
                                            <FontAwesomeIcon icon={faCalculator} />
                                            <></> {constantes.RecalcularTudo}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <small>{constantes.DataReferencia} {new Date(props.DataBaseVencimentos).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}</small>
                                    </Col>
                                </td>}
                        </tr>
                    </tbody>
                </Table>
            </div>
        </Form>
    )
}




const ValorTotal = () => {
    const { values } = useFormikContext();
    let resultado = 0;
    let resultadopercentual = 0;
    for (const item in values.Vencimentos) {
        if (Object.hasOwnProperty.call(values.Vencimentos, item)) {
            const element = values.Vencimentos[item];

            resultado = (((resultado + parseFloat(element.Valor)) * 100) / 100);
            resultadopercentual = resultadopercentual + parseFloat(element.Percentual);
        }
    }
    const lancamentos = parseFloat(resultado.toFixed(2));
    const percentual = parseFloat(resultadopercentual.toFixed(2));
    return <div>Lançamentos<h5>{isNaN(lancamentos) ? 0 : format_percent_2.format(lancamentos)}</h5>
        <h5>{isNaN(percentual) ? 0 : format_percent_2.format(percentual)} %</h5></div>
}

const Diferenca = () => {
    const { values } = useFormikContext();



    let resultado = 0;
    for (const item in values.Vencimentos) {
        if (Object.hasOwnProperty.call(values.Vencimentos, item)) {
            const element = values.Vencimentos[item];
            resultado = Number((resultado + Number(element.Valor ? element.Valor : 0)).toFixed(2));
        }
    }
    const diferenca = Number(values.Valortotal?.toFixed(2)) - Number(resultado.toFixed(2));
    const color = diferenca > 0 ? 'green' : diferenca < 0 ? 'red' : 'black';
    return <div>Diferença<h5 style={{ color: color }}>{format_currency_2.format(isNaN(diferenca) ? 0 : diferenca)}</h5></div>
}


const Recalcular = (values, setFieldValue, database, valortotalorcamento, zerar) => {

    let list = {};

    if (values.Vencimentos) {
        const newvencimentos = values.Vencimentos.map((item, i) => {
            return {
                Dias: item.Dias,
                Data: item.Data,
                ValorDigitado: zerar ? 0 : parseFloat(item.ValorDigitado),
                Percentual: 0,
                Valor: 0
            }
        })

        OrcamentoVendaService.CalcularVencimentosValor({ DataBase: database, ValorTotal: valortotalorcamento, Vencimentos: newvencimentos })
            .then((response) => {
                list = response.data.value;
                let valortotal = 0;
                let percentualtotal = 0;

                const vencimentoscalculados = list.map((item, i) => {
                    const retorno = item;

                    retorno.ValorDigitado = parseFloat(item.ValorDigitado);
                    retorno.PercentualDigitado = parseFloat(item.PercentualDigitado);
                    retorno.Percentual = parseFloat(item.Percentual);
                    retorno.Valor = parseFloat(item.Valor);

                    valortotal = valortotal + parseFloat(retorno.Valor);
                    percentualtotal = percentualtotal + parseFloat(retorno.Percentual);

                    return retorno;
                });

                setFieldValue("Vencimentos", vencimentoscalculados);
                setFieldValue("Valorvencimento", valortotal);
                setFieldValue("Diferenca", values.Valortotal - valortotal);
                setFieldValue("Percentualtotal", percentualtotal);

            })
            .catch((error) => {
                console.error("vencimentos erro", error);
            });
    }
}


const RecalcularPercentual = (values, setFieldValue, database, valortotalorcamento) => {

    let list = {};

    if (values.Vencimentos) {
        const newvencimentos = values.Vencimentos.map((item, i) => {
            return {
                Dias: item.Dias,
                Data: item.Data,
                PercentualDigitado: parseFloat(item.PercentualDigitado),
                Percentual: 0,
                Valor: 0
            }
        })

        OrcamentoVendaService.CalcularVencimentosPercentual({ DataBase: database, ValorTotal: valortotalorcamento, Vencimentos: newvencimentos })
            .then((response) => {
                list = response.data.value;

                let valortotal = 0;
                let percentualtotal = 0;
                for (const item in list) {
                    if (Object.hasOwnProperty.call(list, item)) {
                        const element = list[item];
                        valortotal = valortotal + element.Valor;
                        percentualtotal = percentualtotal + element.Percentual;
                    }
                }


                setFieldValue("Vencimentos", list);
                setFieldValue("Valorvencimento", valortotal);
                setFieldValue("Diferenca", values.Valortotal - valortotal);
                setFieldValue("Percentualtotal", percentualtotal);

            })
            .catch((error) => {
                console.error("vencimentos erro", error);
            });
    }
}


const Vencimentos = (props) => {
    const { values, setFieldValue } = useFormikContext();

    const constantes = T()
    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Dias</th>
                        {values.vencimentofixo && <th>Data</th>}
                        <th>%
                            {!props.somenteLeitura && !props.BloqueadoAlterarVencimento &&
                                <>
                                    <>   </>
                                    <Button variant="warning" onClick={() => RecalcularPercentual(values, setFieldValue, props.DataBaseVencimentos, props.valortotal)}>
                                        <></>  <small> {constantes.Recalcular} </small>
                                    </Button>
                                </>
                            }
                        </th>
                        <th>Valor
                            {!props.somenteLeitura && !props.BloqueadoAlterarVencimento &&
                                <>
                                    <>   </>
                                    <Button variant="warning" onClick={() => Recalcular(values, setFieldValue, props.DataBaseVencimentos, props.valortotal)}>
                                        <FontAwesomeIcon icon={faCalculator} />
                                        <></>  <small> {constantes.Recalcular}</small>
                                    </Button>

                                </>
                            }

                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>

                    {values.Vencimentos && values.Vencimentos.map(
                        (item, i) => {
                            return (
                                <tr key={i}>
                                    <td>
                                        {item.Dias}
                                    </td>
                                    {values.vencimentofixo &&
                                        <td>{new Date(item.Data).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}</td>}
                                    <td align="right" className={item.PercentualDigitado ? "-3 mb-2 bg-warning text-dark" : ""}>
                                        {format_percent_2.format(item.Percentual ?? 0)}
                                    </td>

                                    <td align="right" className={item.ValorDigitado ? "-3 mb-2 bg-warning text-dark" : ""}>
                                        {format_currency_2.format(item.Valor ?? 0)}
                                    </td>
                                    {!props.somenteLeitura && !props.BloqueadoAlterarVencimento &&
                                        <td>

                                            <Button variant="outline-secondary"
                                                onClick={() => {
                                                    const element = values.Vencimentos[i];

                                                    setFieldValue("Dias", element.Dias);
                                                    setFieldValue("Data", element.Data);
                                                    setFieldValue("Percentual", element.Percentual);
                                                    setFieldValue("Valor", element.valor);
                                                    setFieldValue("edicao", i);
                                                    setFieldValue("state", "editando");
                                                    setFieldValue("itemedicao", element);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                            <Button variant="outline-secondary" onClick={() => {
                                                const oldList = values.Vencimentos;
                                                const newList = oldList.filter((_, index) => index !== i);
                                                setFieldValue("Vencimentos", newList);
                                            }}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </td>}
                                </tr>
                            )
                        }
                    )}
                </tbody>
            </Table>
        </div >
    )
}

const Confirmacao = (props) => {
    return (
        <div>
            <Button bsPrefix="ButtonStyle salvarvencimento" disabled={props.somenteLeitura} type="submit">{props.salvarOrcamento ? "Salvar e Salvar Orçamento" : "Salvar"}</Button>
        </div>
    )
}

const handleSubmit = (values) => {


}

const HandleAddItem = (values, setFieldValue, item) => {

    const newitem = {
        Dias: item.Dias,
        Data: item.Data,
        Percentual: Number(item.Percentual),
        Valor: Number(item.Valor),
        PercentualDigitado: Number(item.Percentual),
        ValorDigitado: Number(item.Valor)
    }

    const newvencimentos = values.Vencimentos ? values.Vencimentos.concat(
        newitem
    ) : [].concat(
        newitem
    );

    newvencimentos.sort((a, b) => (a.Dias > b.Dias) ? 1 : -1);

    setFieldValue("Vencimentos", newvencimentos);
    setFieldValue("itemedicao", null);
}

const HandleUpdateItem = (values, setFieldValue, item) => {
    
    const indexItem = values.Vencimentos.findIndex((value, index) => value.Dias === values.itemedicao.Dias)
    const newvencimento =
    {
        Dias: item.Dias,
        Data: item.Data,
        Percentual: item.Percentual,
        Valor: item.Valor,
        PercentualDigitado: item.Percentual,
        ValorDigitado: item.Valor
    }

    const newvencimentos = values.Vencimentos;
    newvencimentos[indexItem] = newvencimento;

    newvencimentos.sort((a, b) => (a.Dias > b.Dias) ? 1 : -1);


    setFieldValue("Vencimentos", newvencimentos);
    setFieldValue("itemedicao", null);
    setFieldValue("state", "inserindo")

}

const NewVencimentos = (props) => {
    const { valortotal, vencimentos, onVencimentos, vencimentofixo, MaximoDiasVencimento, DataBaseVencimentos, eventoGravar, MinimoDiasVencimento, somenteLeitura, BloqueadoAlterarVencimento } = props;

    const _initial = initialvalues;
    _initial.Valortotal = valortotal;
    _initial.Diferenca = valortotal;
    _initial.Vencimentos = vencimentos;
    _initial.vencimentofixo = vencimentofixo;

    const [erro, setErro] = useState();
    const [alert, setAlert] = useState();

    const constantes = T();

    useEffect(() => {

        erro ?
            setAlert(
                <Alert variant="danger" onClose={() => setErro(null)} dismissible>
                    <Alert.Heading>{constantes.ExistemAlgunsErrosPendentes}</Alert.Heading>
                    <p>
                        {erro}
                    </p>
                </Alert>) :
            setAlert(null);
    }, [erro, setErro])


    return (
        <Formik

            enableReinitialize={true}
            initialValues={_initial}
            onSubmit={(values) => {
                handleSubmit(values);
                onVencimentos && onVencimentos(values.Vencimentos, values.vencimentofixo);
                eventoGravar && eventoGravar();
            }}
        >
            {({ values, errors, isValidating, setFieldValue, handleChange }) => {
                return (
                    <Container>
                        {alert}
                        <h2>{constantes.Vencimentos}</h2>
                        <Stack gap={2}>
                            <Recalculo {...props} />
                            <Form>
                                <label>
                                    <Stack direction="horizontal" gap={2}>
                                        <Field disabled={somenteLeitura} type="checkbox" name="vencimentofixo" />
                                        {constantes.VencimentoFixo}
                                    </Stack>
                                </label>
                            </Form>

                            {!somenteLeitura && !BloqueadoAlterarVencimento &&
                                <LancamentoVencimentos
                                    {...props}
                                    vencimentofixo={values.vencimentofixo}
                                    state={values.state}
                                    itemedicao={values.itemedicao}
                                    lista={values.Vencimentos}
                                    onItemAdd={(item) => HandleAddItem(values, setFieldValue, item)}
                                    onItemUpdate={(item) => HandleUpdateItem(values, setFieldValue, item)}
                                    onCancelItem={() => {
                                        setFieldValue("state", "inserindo")
                                        setFieldValue("itemedicao", null);
                                    }} />}
                            <Form>
                                <Vencimentos {...props} />
                                <Confirmacao {...props} salvarOrcamento={eventoGravar} />
                            </Form>
                        </Stack>
                    </Container>
                )
            }}
        </Formik>

    );

}


export default NewVencimentos;