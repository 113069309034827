import { useContext } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import AtributoComp from "../atributos_comp/atributo.comp.atributo";

const OrcamentoVendaAtributosCtx = () => {

    const {
        somenteLeitura,
        getAtributosIniciais,
        atualizarValoresNaoEncontrados
    } = useContext(OrcamentoVendaContext);

    return (
       <AtributoComp 
            disabled={somenteLeitura} 
            atributoInicial={getAtributosIniciais()} 
            atualizarValoresNaoEncontrados={atualizarValoresNaoEncontrados}
            somenteLeitura={somenteLeitura}/> 
    )

}

export default OrcamentoVendaAtributosCtx;