import SelectControl from "./control-selectcontrol.jsx";
import T from '../../common/traducao.js'

const TipoPessoaControl = (props) => {

    const { PermitirApenasCadastroPessoaJuridica } = props;

    const constantes = T()


    const tiposPesoa = PermitirApenasCadastroPessoaJuridica
        ? [
            {
                Id: 'Jurídica',
                Valor: 'Jurídica',
                Descricao: constantes.Juridica
            }
        ] :
        [
            {
                Id: 'Jurídica',
                Valor: 'Jurídica',
                Descricao: constantes.Juridica
            },
            {
                Id: 'Física',
                Valor: "Física",
                Descricao: constantes.Fisica
            }

        ]

    return (
        <SelectControl  {...props} name={props.name} placeholder={constantes.TipoPessoa}
            itens={tiposPesoa}
        />
    )
}

export default TipoPessoaControl;