import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class MunicipioService extends ServiceBase {

  getAll(filter) {
    //return http.get("/clientes?$top=10");
    return http.get("/municipios" + this.getFilter(filter));
  }

  get(id) {
    return http.get(`/municipios/${id}`);
  }

  getByCodigoFederal(id) {
    return http.get(`/municipios/CodigoFederal/${id}`);
    
    // const filter = {
    //   campo: 'CodigoFederal',
    //   operacao: 'eq',
    //   valor: Number(id),
    //   page: 1
    // }
    // const retorno = this.getAll(filter)
    //   .then((response) => {
    //     return this.get(response.data.value[0].Id)
    //   })
    //   .catch((error) => {
    //   }
  }

  getDistancia(id) {
    return http.get(`/municipios/${id}/DistanciaFilial`);
  }

}

export default new MunicipioService();