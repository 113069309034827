import { ErrorMessage, Field } from "formik";
import { Badge } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const CampoMemo = (props) => {
  const { name, placeholder, type, disabled, hideTitle } = props;
  return (
    <>
      {!hideTitle && (
        <Form.Label className="mb-0">{placeholder}</Form.Label>
      )}

      <Field name={name}>
        {({ field, form }) => (
          <Form.Control
            {...field}
            as="textarea"
            type={type}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
      </Field>
      <ErrorMessage name={name}>
        {(msg) => <Badge bg="danger">{msg}</Badge>}
      </ErrorMessage>
    </>
  );
}


export default CampoMemo;
