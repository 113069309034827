import { useEffect, useState, useReducer } from "react";
import T from "../../common/traducao";
import ProdutoService from "../../services/service.produto";
import {
  Alert,
  Button,
  Col,
  Container,
  Image,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import AtributoItemSelectControl from "./atributo.AtributoItemSelectControl";
import AtributoItemDecimalControl from "./atributo.ItemDecimalControl";
import AtributoItemLogicoControl from "./atributo.ItemLogicoControl";
import { useMediaQuery } from 'react-responsive';
import { FaSitemap } from 'react-icons/fa'

const AtributosItem = (props) => {
  const [produto, setProduto] = useState();
  const [atributos, setAtributos] = useState();
  const [saidaAtributosItem, setSaidaAtributosItem] = useState(props.atributos);
  const [image, setImage] = useState();
  const [error, setError] = useState();

  const constantes = T();

  async function GetProduto() {
    (await props?.id) &&
      ProdutoService.get(props.id)
        .then((response) => {
          setProduto(response.data);
        })
        .catch()
        .finally();
  }

  async function GetAtributos() {
    (await props?.id) &&
      ProdutoService.getAtributos(props.id)
        .then((response) => {
          const valores = response.data.value.map((item, index) => {
            const item_atual = saidaAtributosItem?.find(
              (item_atr, index_atr) => {
                return item_atr.Atributo?.Id === item.Atributo?.Id;
              }
            );
            const novo_item = {
              Id: item_atual ? item_atual.Id : 0,
              Atributo: item.Atributo,
              ValorAtributo: item_atual
                ? item_atual.ValorAtributo
                : { Id: null },
              Valor: item_atual ? item_atual.Valor : item.Atributo.ValorPadrao,
              Valores: item.Valores,
              Grupo: item.Grupo,
            };
            return novo_item;
          });
          setSaidaAtributosItem(valores);
          setAtributos(valores);
        })
        .catch((error) => console.log(error))
        .finally();
  }

  async function GetImage() {
    (await props?.id) &&
      ProdutoService.getImage(props.id)
        .then((response) => {
          setImage(response.request.responseURL);
        })
        .catch()
        .finally();
  }

  useEffect(() => {
    GetProduto();
    GetAtributos();
    GetImage();
  }, []);

  function HandleItemChange(item, index, tipo) {
    setError(null);
    const listaitenssaida = saidaAtributosItem?.filter(
      (itemfind, idx) => itemfind.Atributo?.Id !== item.Atributo?.Id
    );
    setSaidaAtributosItem([...listaitenssaida, item]);
  }

  function ViewError() {
    return error && <Alert variant="danger">{error}</Alert>;
  }

  function GetAtributoItem(item, index, tipo) {


    const item_saida = saidaAtributosItem.find((item_saida, index_saida) => {
      return item_saida.Atributo.Id === item.Atributo.Id;
    });

    if (item.Valores?.length > 0) {
      return (
        <AtributoItemSelectControl
          key={index}
          disabled={props?.somenteLeitura}
          listvalues={item.Valores}
          atributoItemEntrada={item_saida}
          index={index}
          tipo={tipo}
          onItemChange={HandleItemChange}
        />
      );
    } else if (item.Atributo.Tipo === "Decimal") {
      return (
        <AtributoItemDecimalControl
          key={index}
          disabled={props?.somenteLeitura}
          atributoItemEntrada={item_saida}
          index={index}
          tipo={tipo}
          onItemChange={HandleItemChange}
        />
      );
    } else if (item.Atributo.Tipo === "Inteiro") {
      return (
        <AtributoItemDecimalControl
          key={index}
          disabled={props?.somenteLeitura}
          atributoItemEntrada={item_saida}
          index={index}
          tipo={tipo}
          onItemChange={HandleItemChange}
        />
      );
    } else if (item.Atributo.Tipo === "Lógico") {
      return (
        <AtributoItemLogicoControl
          key={index}
          disabled={props?.somenteLeitura}
          atributoItemEntrada={item_saida}
          index={index}
          tipo={tipo}
          onItemChange={HandleItemChange}
        />
      );
    }
  }

  function aplicarFormula() {
    props?.id &&
      ProdutoService.postFormula(props.id, saidaAtributosItem)
        .then((response) => {
          props.setQuantidadeCalculada &&
            props.setQuantidadeCalculada(
              saidaAtributosItem,
              response.data.value
            );
        })
        .catch((error) => {
          const _error =
            error.code === "ERR_NETWORK"
              ? "Server OffLine"
              : error.response &&
                error.response.data &&
                error.response.data.error
                ? error.response.data.error.message
                : error.message;
          setError(_error);
        })
        .finally();
  }
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
  return (
    <Container>
      <ListGroup>

        <ListGroupItem>
          <h3 style={{ color: "#3f3f3f" }}>{constantes.AtributosDoProduto} <FaSitemap style={{ marginBottom: 3 }} /> </h3>
          <Container>
            {atributos &&
              atributos?.map((item, index) => {
                return item.Grupo === "Produto" ? (
                  <div>
                    <h6>{item.Atributo.NomeExibicaoTraduzido}</h6>
                    {GetAtributoItem(item, index, "produto")}
                  </div>
                ) : null;
              })}
          </Container>
        </ListGroupItem>
        <ListGroupItem>
          <Container>
            <Row>
              <Col style={{ width: "75px" }} md={2}>
                <span style={{ fontWeight: "bold", color: "#444444" }}>{constantes.Codigo}</span>
              </Col>
              <Col style={{ width: "75px" }} md={2}>{produto?.Id}</Col>
              <Col style={{ width: "100px" }} md={2}>
                <span style={{ fontWeight: "bold", color: "#444444" }}>
                  {constantes.Referencia}
                </span>
              </Col>
              <Col md={2}>{produto?.Referencia}</Col>
            </Row>
            <Row>
              <Col style={{ width: "75px" }} md={2}>
                <span style={{ fontWeight: "bold", color: "#444444" }}>{constantes.Nome}</span>
              </Col>
              <Col md={10}>{produto?.NomeTraduzido}</Col>
            </Row>
            <Row>
              <Col md={4}>
                <span style={{ fontWeight: "bold", color: "#444444" }}>
                  {constantes.Observacao}
                </span>
              </Col>
              <Col md={10}>{produto?.Observacao}</Col>
            </Row>
          </Container>
        </ListGroupItem>

        <ListGroupItem>
          <Container>
            <h4>{constantes.AtributosParaCalculoDeQuantidade}</h4>
            <Row >
              <Col xs={6} sm={16} md={4} style={{ width: "100%" }}>
                <Col style={{ gap: 30, display: "flex", flexDirection: isMobile ? "column" : "row", width: "100%" }} sm={16} md={15}>
                  <Image style={{ maxWidth: isMobile ? 250 : 350 }} src={image} rounded />
                  <Col xs={16} sm={15} md={15}   >
                    {atributos &&
                      atributos?.map((item, index) => {
                        return item.Grupo !== "Produto" ? (
                          <div key={index} style={{ width: isMobile ? "100%" : 220 }} className="mb-2">
                            <h6>{item.Atributo.NomeExibicaoTraduzido}</h6>
                            {GetAtributoItem(item, index, "formula")}
                          </div>
                        ) : null;
                      })}
                  </Col>
                </Col>
              </Col>
            </Row>
          </Container>
        </ListGroupItem>


      </ListGroup>

      <ViewError />
      {!props?.somenteLeitura && (
        <Button
          type="submit"
          onClick={() => {
            aplicarFormula();
          }}
          className="mt-2"
        >
          {" "}

          {constantes.Salvar}
        </Button>
      )}
    </Container>
  );
};

export default AtributosItem;
