import { Form } from "react-bootstrap";
import { useContext } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import ListComp2 from "../../common/listcomp2";
import { useFormikContext } from "formik";

const ItemOrcamentoVendaCondicoesEspeciaisCtx = (props) => {

    const {
        constantes,
    } = useContext(OrcamentoVendaContext);

    const {
        values,
    } = useFormikContext();

    const columns = [
        {
            field: 'DataHoraInclusao',
            headerName: 'Data Inclusao',
            type: "date",
            format: "dd/MM/yyyyy",
        },
        {
            field: 'UsuarioInclusaoLogin',
            headerName: 'User',
        },

        {
            field: 'PercentualDescontoEspecial',
            headerName: '%',
        },
        {
            field: 'Justificativa',
            headerName: 'Justificativa',
        },
    ];

    return (
        <>
            <Form.Label>
               {constantes.CondicoesEspeciaisAplicadas}
            </Form.Label>
            <ListComp2
                semcontainer
                columns={columns}
                msgAviso={false}
                data={values.ItemOrcamentoVenda?.CondicoesEspeciais}
            />
        </>
    )
}

export default ItemOrcamentoVendaCondicoesEspeciaisCtx;