import React, { useState } from 'react';
import clienteService from "../../services/service.cliente";
import NewSearchBarControlFormik from '../searchs_comp/search-Bar.Control.Formik';
import T from '../../common/traducao';


const CampoSearchListClientesAtivos = (props) => {

    const constantes = T()

    const columns = [
        {
            field: 'Id',
            headerName: constantes.Codigo,
            width: 90,
            key: true
        },
        {
            field: 'Nome',
            headerName: constantes.Nome,
            width: 150,
        },
        {
            field: 'CPFCNPJ',
            headerName: constantes.CPFCNPJ,
            width: 150,
        },
        {
            field: 'Municipio',
            headerName: constantes.Municipio,
            width: 150,
        },
        {
            field: 'UF',
            headerName: constantes.Estado,
            width: 150,
        },
        {
            field: 'Pais',
            headerName: constantes.Pais,
            width: 150,
        },
    ]

    const columnsFilter = [
        {
            field: 'Id',
            headerName: constantes.Codigo,
            tipo: 'number'
        },
        {
            field: 'Nome',
            headerName: constantes.Nome,
            tipo: 'text'
        },
        {
            field: 'CPFCNPJ',
            headerName: constantes.CPFCNPJ,
            tipo: 'text'
        },
        {
            field: 'Municipio',
            headerName: constantes.Municipio,
            tipo: 'text'
        },
    ]

    const [clientes, setClientes] = useState();
    const [pages, setPages] = useState();
    const [registros, setRegistros] = useState();

 //   const [filter, setFilter] = useState();

    function onLoad(loading) {
        props.onLoad && props.onLoad(loading)
    }

    function onError(error) {
        props.onError && props.onError(error)
    }

    function onSearchTitle(filter) {
        onLoad(true);
        clienteService.getAllAtivos(filter)
            .then(response => {
                setClientes(response.data.value);
                setPages(response.data.pages);
                setRegistros(response.data.registros);
//                setFilter(filter);
            })
            .catch(e => {
                onError(e);
            })
            .finally(() => onLoad(false)
            );
    }

    function onGetItem(id) {
        id && onLoad(true);
        id && clienteService.getAtivos(id)
            .then(response => {
                if (response.response?.data.error) {
                    onError(response.response?.data.error);
                } else {
                    response.data && onItemSelected(response.data);
                }
            })
            .catch(e => {
                onError(e);
            })
            .finally(() => {
                onLoad(false);
            });
        !id && onItemSelected(null);
    }

    function onItemSelected(item) {
        props.onItemSelected(item);
    }

    function onItemClear() {
        props.onItemClear();
    }

    return (
        <>
            <NewSearchBarControlFormik
                {...props}
                onSearchTitle={onSearchTitle}
                onGetItem={onGetItem}
                onItemSelected={onItemSelected}
                onItemClear={onItemClear}
                data={clientes}
                pages={pages}
                registros={registros}
                columns={columns}
                columnsFilter={columnsFilter}
                storagename={"Clientes"}
            />
        </>
    )
}

export default CampoSearchListClientesAtivos;
