import { Button, Form, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox } from "@fortawesome/free-solid-svg-icons";
import { useFormikContext } from "formik";
import { useContext, useRef } from "react";
import { ItemOrcamentoVendaContext } from "../../contexts/itemorcamentovenda-context";
import EditControl from "../controls_comp/control-edit";

const QuantidadeCtx = () => {

    const {
        constantes,
        somenteLeitura,
        devePreencherAtributos,
        setModalAtributos,
        setTotalItem,
        precoUnitario
    } = useContext(ItemOrcamentoVendaContext);

    const {
        values,
        setFieldValue,
        resetForm
    } = useFormikContext();

    //const qtdRef = useRef();

    return devePreencherAtributos ?
        (
            <>
                <Form.Label className="mb-0">{constantes.Quantidade}</Form.Label>

                <Stack direction="horizontal" gap={2}>
                    <EditControl
                       // ref={qtdRef}
                        disabled
                        hideTitle
                        type="Number"
                        name="ItemOrcamentoVenda.Quantidade"
                        placeholder={constantes.Quantidade}
                        isValid={false}
                    />
                    <Button
                        type="button"
                        onClick={(e) => {
                            setModalAtributos(true);

                        }}>
                        <FontAwesomeIcon icon={faBox}></FontAwesomeIcon>
                    </Button>
                </Stack>
            </>
        )
        :
        (
            <EditControl
              //  ref={qtdRef}
                disabled={somenteLeitura || devePreencherAtributos}
                type="Number"
                onBlur={(e) => {
                    setTotalItem(Number((Number(e.target.value) * precoUnitario).toFixed(2)));
                }}
                name="ItemOrcamentoVenda.Quantidade"
                placeholder={constantes.Quantidade}
                isValid={false}
            />
        )
}

export default QuantidadeCtx;