import { useEffect, useContext } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { RiGlobalLine } from "react-icons/ri";
import { useFormikContext } from "formik";
import { ClienteContext } from "../../contexts/clientes-context";
import "react-datepicker/dist/react-datepicker.css";
import EditControl from "../controls_comp/control-edit";
import DateControl from "../controls_comp/control-Date";
import IndicadorIeControl from "../controls_comp/control-IndicadorIe";
import EditFoneControl from "../controls_comp/control-edit-fone";
import EditCpfCnpjControl from "../controls_comp/control-edit-cpf-cnpj";
import TipoPessoaControl from "../controls_comp/control-select-tipopessoa";

export default function ClienteTabGeral() {

  const formik = useFormikContext();

  const {
    constantes,
    detalhesClientes,
    somenteLeitura,
    camposDesabilitados,
    camposPrivados,
  } = useContext(ClienteContext);

  const { values, setFieldValue, setValues } = formik;

  useEffect(() => {
    if (detalhesClientes) {
      setValues(detalhesClientes);
    }
  }, [detalhesClientes]);

  return (
    <Box component={Paper}>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        height={50}
        sx={{ backgroundColor: "rgba(59, 134, 124, 0.08)" }}
      >
        <Typography variant="h1" fontSize={22} fontWeight="sembold">
          <RiGlobalLine style={{ marginBottom: "5px", marginRight: "8px" }} />
          {constantes.Geral}
        </Typography>
      </Box>

      <Grid container spacing={2} p={2}>
        <Grid
          spacing={2}
          container
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          alignItems="end"
        >
          {values.Id > 0 && (
            <Grid item xs={12} sm={2} md={2}>
              <EditControl
                disabled={somenteLeitura}
                name="Id"
                placeholder={constantes?.Codigo}
                type="number" />
            </Grid>
          )}
          {values.Situacao && (
            <Grid item xs={12} sm={2} md={2}>
              <EditControl
                disabled={somenteLeitura}
                name="Situacao"
                placeholder={constantes?.Situacao}
                type="number" />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <TipoPessoaControl
            disabled={somenteLeitura}
            name="Entidade.TipoPessoa"
            PermitirApenasCadastroPessoaJuridica={camposPrivados.PermitirApenasCadastroPessoaJuridica}
          />
        </Grid>
        <Grid item xs={12} sm={3.5} md={3}>
          <EditCpfCnpjControl
            tipoPessoa={values.Entidade.TipoPessoa}
            inputProps={{ maxLength: 14 }}
            disabled={somenteLeitura}
            name="Entidade.CpfCnpj"
            placeholder={constantes?.CpfCnpjCliente}
            type="text" />
        </Grid>
        <Grid item xs={12} sm={3.5} md={2}>
          <DateControl
            name="Entidade.DataNascimentoFundacao"
            placeholder={constantes.DataNascFund}
            selected={values?.Entidade?.DataNascimentoFundacao}
            disabled={somenteLeitura}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={10}>
          <EditControl
            inputProps={{ maxLength: 60 }}
            disabled={somenteLeitura}
            name="Entidade.Nome"
            placeholder={constantes?.Nome}
            type="text" />
        </Grid>
        <Grid item xs={12} sm={10} md={10}>
          <EditControl
            disabled={somenteLeitura || camposDesabilitados.ApelidoFantasia}
            name="Entidade.ApelidoFantasia"
            placeholder={constantes?.ApelidoNomeFantasia}
            type="text" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={values.Entidade.TipoPessoa === "Física" ? 10 : 3}
        >
          <EditControl
            disabled={somenteLeitura || camposDesabilitados.Rg}
            name="Entidade.Rg"
            placeholder={constantes?.RG}
            type="text" />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <IndicadorIeControl
            name="Entidade.IndicadorIe"
            disabled={somenteLeitura ||
              values.OrcamentoVenda?.Cliente?.Id > 0
            }
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          {values.Entidade.TipoPessoa === "Jurídica" && (
            <EditControl
              inputProps={{ maxLength: 50 }}
              disabled={somenteLeitura || values.Entidade.IndicadorIe === 2}
              name="Entidade.RgIe"
              placeholder={constantes?.InscrEstadualProdutor}
              type="text" />
          )}
          
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          {values.Entidade.TipoPessoa === "Jurídica" && (
            <EditControl
              inputProps={{ maxLength: 50 }}
              disabled={somenteLeitura || camposDesabilitados.InscricaoMunicipal}
              name="Entidade.InscricaoMunicipal"
              placeholder={constantes?.InscricaoMunicipal}
              type="text" />
          )}
        </Grid>
        <Grid item xs={12} sm={10} md={10}>
          <EditControl
            inputProps={{ maxLength: 50 }}
            disabled={somenteLeitura || camposDesabilitados.IdentidadeEstrageiro}
            name="Entidade.IdentidadeEstrageiro"
            placeholder={constantes?.IdentEstrangeiro}
            type="text" />
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <EditFoneControl
            inputProps={{ maxLength: 12 }}
            disabled={somenteLeitura || camposDesabilitados.FoneResidencial}
            name="Entidade.FoneResidencial"
            mask={"(99)9999-9999"}
            placeholder={constantes?.FoneResidencial}
            type="text" />
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <EditFoneControl
            inputProps={{ maxLength: 12 }}
            disabled={somenteLeitura}
            name="Entidade.FoneComercial"
            mask={"(99)9999-9999"}
            placeholder={constantes?.FoneComercial}
            type="text" />
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <EditFoneControl
            inputProps={{ maxLength: 12 }}
            disabled={somenteLeitura || camposDesabilitados.FoneCelular}
            name="Entidade.FoneCelular"
            mask={"(99)99999-9999"}
            placeholder={constantes?.FoneCelular}
            type="text" />
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <EditFoneControl
            inputProps={{ maxLength: 12 }}
            disabled={somenteLeitura || camposDesabilitados.FoneFax}
            name="Entidade.FoneFax"
            mask={"(99)9999-9999"}
            placeholder={constantes?.FoneFax}
            type="text" />
        </Grid>
      </Grid>
    </Box >
  );
}
