import { forwardRef, useState } from "react";
import ProdutoService from "../../services/service.produto";
import NewSearchBarControlFormik from "../searchs_comp/search-Bar.Control.Formik";
import { GetIdiomaFiltro } from "../../config/axiosConfig/http-common";
import T from "../../common/traducao";


const CamposSearchListProduto = forwardRef((props, ref) => {

    const [produtos, setProdutos] = useState();
    const [pages, setPages] = useState();
    const [filter, setFilter] = useState();

    const { idTabelaPreco  } = props;

    const idioma = GetIdiomaFiltro();

    const constantes = T()
    
    const columns = [
        {
            field: 'Id',
            headerName: constantes.Codigo,
            width: 90,
            key: true
        },
        {
            field: 'Referencia',
            headerName: constantes.Referencia,
            tipo: 'text'
        },
        {
            field: 'Nome',
            headerName: constantes.Nome,
            width: 150,
        },
        {
            field: 'IdUnidadeMedida',
            headerName: constantes.Unid,
            width: 70,
        },
        {
            field: 'PermissaoVenda',
            headerName: constantes.PermVenda,
            tipo: 'text'
        },
    ]

    const columns_com_preco = [
        {
            field: 'Id',
            headerName: constantes.Codigo,
            width: 90,
            key: true
        },
        {
            field: 'Referencia',
            headerName: constantes.Referencia,
            tipo: 'text'
        },
        {
            field: 'Nome',
            headerName: constantes.Nome,
            width: 150,
        },
        {
            field: 'IdUnidadeMedida',
            headerName: constantes.Unid,
            width: 70,
        },
        {
            field: 'PrecoVenda',
            headerName: constantes.preco,
            width: 70,
            align: "right",
            digits: 2
        },
        {
            field: 'PermissaoVenda',
            headerName: constantes.PermVenda,
            tipo: 'text'
        },
    ]

    const columnsFilter = [
        {
            field: 'Id',
            headerName: constantes.Codigo,
            tipo: 'number'
        },
        {
            field: "Nome",
            headerName: constantes.Nome,
            tipo: 'text',
            translateField : idioma ? 'NomeTraduzido' : null
        },
        {
            field: 'Referencia',
            headerName: constantes.Referencia,
            tipo: 'text'
        },
        {
            field: 'PermissaoVenda',
            headerName: constantes.PermVenda,
            tipo: 'text'
        }
    ]

    function onLoad(loading) {
        props.onLoad(loading)
    }

    function onError(error) {
        props.onError(error)
    }

    function onSearchTitle(filter) {
        onLoad(true);
        ProdutoService.getAll(filter, idTabelaPreco)
            .then(response => {
                const produtos = response.data.value.map((item) => {
                    return {
                        ...item,
                        Nome: item.NomeTraduzido ?? item.Nome,
                        IdUnidadeMedida : item.IdUnidadeMedidaTraduzido ?? item.IdUnidadeMedida
                    }
                })
                setProdutos(produtos);
                setPages(response.data.pages);
                setFilter(filter);
            })
            .catch(e => {
                onError(e);
            })
            .finally(() => {
                onLoad(false);
            });

    }

    function onGetItem(id) {
        id && onLoad(true);
        id && ProdutoService.get(id, idTabelaPreco)
            .then(response => {
                if (response.response?.data.error) {
                    onError(response.response?.data.error);
                } else {
                    // const traducao = ProdutoService.getProdutoTraduzido(response.data);
                    // const produto = { ...response.data, Nome: traducao };
                    response.data && onItemSelected(response.data);
                }
            })
            .catch(e => {
                onError(e);
            })
            .finally(() => {
                onLoad(false);
            });
    }

    function onItemSelected(item) {
        props.onItemSelected(item);
    }
    
    return (
        <>
            <NewSearchBarControlFormik
                {...props}
                ref={ref}
                fullscreen
                pages={pages}
                onSearchTitle={onSearchTitle}
                onGetItem={onGetItem}
                onItemSelected={onItemSelected}
                data={produtos}
                columns={idTabelaPreco ? columns_com_preco : columns}
                storagename={"Produtos"}
                columnsFilter={columnsFilter}
            />
        </>
    )
})


export default CamposSearchListProduto;
