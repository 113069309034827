import React, { useContext } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";
import { AssistenciasContext } from "../../contexts/assistencias-context";
import { Button } from "react-bootstrap";

export default function AssistenciaListaAcoes() {
  const {
    constantes,
    somenteLeitura,
    nextStepCadastroAssistencia,
    nextStepEditarAssistencia,
    nextStepDuplicarAssistencia,  
    handleFinalizarAssistencia
  } = useContext(AssistenciasContext);

  const navigate = useNavigate();
  const { values, handleSubmit } = useFormikContext();

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        padding: 2,
        gap: "15px",
        backgroundColor: "white",
        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {values.Assistencia?.Cliente && (
        <>
          {nextStepCadastroAssistencia && (
            <Button
              type="button"
              size="lg"
              variant="primary"
              origin="salvando"
              onClick={(e) => {
                // setFinalizando(false);
                handleSubmit(e);
              }}
              disabled={somenteLeitura}
            >
              {values.Assistencia?.Id
                ? constantes.Atualizar
                : constantes?.Gravar}
            </Button>
          )}

          {nextStepDuplicarAssistencia && (
            <Button
              type="button"
              size="lg"
              variant="primary"
              origin="salvando"
              onClick={(e) => {
                // setFinalizando(false);
                handleSubmit(e);
              }}
              disabled={somenteLeitura}
            >
              {constantes?.Gravar}
            </Button>
          )}
          {!somenteLeitura && nextStepEditarAssistencia && (
            <Button
              type="button"
              size="lg"
              variant="primary"
              origin="salvando"
              onClick={(e) => {
               // navigate("/assistencias");
                handleSubmit(e);
              }}
              disabled={somenteLeitura}
            >
              {constantes?.Atualizar}
            </Button>
          )}
        </>
      )}{" "}
      {!somenteLeitura && values.Assistencia?.Cliente && (
        <Button
          type="button"
          size="lg"
          variant="success"
          origin="finalizando"
          onClick={() => {
            handleFinalizarAssistencia(values.Assistencia)
          }}
          disabled={somenteLeitura}
        >
          {constantes?.Finalizar}
        </Button>
      )}{" "}
      <Button
        type="button"
        size="lg"
        variant="light"
        onClick={() => {
          navigate("/assistencias");
        }}
        //disabled={salvando}
      >
        {constantes?.Cancelar}
      </Button>
    </Box>
  );
}
