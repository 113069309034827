import { createContext, useEffect, useState } from "react";
import { GetIdiomaSelecionado, getUserLogin, isAuthenticated } from "../config/localstorageConfig/localstorage";

export const H2jGlobalContext = createContext({})

const H2jProviderContext = ({children}) => {
    const [loadingGlobal, setLoadingGlobal] = useState(false);
    const [isAuth, setIsAuth] = useState(isAuthenticated());
    const [userLogin, setUserLogin] = useState(getUserLogin());
    const [idiomaAtual, setIdiomaAtual] = useState(GetIdiomaSelecionado());
    const handleLoading = (stateLoading) => {
        setLoadingGlobal(stateLoading)
    }

    const updateAuthState = () => {
        setIsAuth(isAuthenticated());
        setUserLogin(getUserLogin());
        setIdiomaAtual(GetIdiomaSelecionado());
    };

    useEffect(() => {
        updateAuthState()
    }, []);

    return(
        <H2jGlobalContext.Provider value={{
            loadingGlobal,
            isAuth,
            userLogin,
            idiomaAtual,
            updateAuthState,
            setIsAuth,
            setIdiomaAtual,
            setUserLogin,
            handleLoading}}>
            {children}
        </H2jGlobalContext.Provider>

    )
}

export default H2jProviderContext