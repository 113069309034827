import React, { useState } from 'react';
import MunicipioService from '../../services/service.municipio';
import SearchBarControlFormik from './search-Bar.Control.Formik';

const SearchListMunicipios = (props) => {
  const [municipios, setMunicipios] = useState(null);
  const [pages, setPages] = useState(1);

  const columns = [
    {
      field: "Id",
      headerName: "Codigo",
      width: 90,
      key: true,
    },
    {
      field: "Nome",
      headerName: "Nome",
      width: 150,
    },
    {
      field: "NomeEstado",
      headerName: "UF",
      width: 150,
    },
    {
      field: "NomePais",
      headerName: "Pais",
      width: 150,
    },
  ];

  const columnsFilter = [
    {
      field: "Id",
      headerName: "Codigo",
      tipo: "number",
    },
    {
      field: "Nome",
      headerName: "Nome",
      tipo: "text",
    },
    {
      field: "NomeEstado",
      headerName: "UF",
      tipo: "text",
    },
  ];


  const onLoad = (loading) => {
    props.onLoad(loading);
  };

  const onError = (error) => {
    props.onError(error);
  };

  const onSearchTitle = (filter) => {
    onLoad(true);
    MunicipioService.getAll(filter)
      .then((response) => {
        setMunicipios(response.data.value);
        setPages(response.data.pages);
      })
      .catch((e) => {
        onError(e);
      })
      .finally(() => {
        onLoad(false)
      });
  };

  const onGetItem = (id) => {
    id && onLoad(true);
    id &&
      MunicipioService.get(id)
        .then((response) => {
          if (response.response?.data.error) {
            onError(response.response?.data.error);
          } else {
            response.data && onItemSelected(response.data);
          }
        })
        .catch((e) => {
          console.error('municipio erro get', e);
          onError(e);
        })
        .finally(() => {
          onLoad(false);
        });
    !id && onItemSelected(null);
  };

  const onItemSelected = (item) => {
    props.onItemSelected(item);
  };
  const onItemClear = () => {
    props.onItemClear && props.onItemClear();
  }

  return (
    <>
      <SearchBarControlFormik
        {...props}
        pages={pages}
        onSearchTitle={onSearchTitle}
        onGetItem={onGetItem}
        onItemSelected={onItemSelected}
        onItemClear={onItemClear}
        data={municipios}
        columns={columns}
        storagename={'Municipio'}
        columnsFilter={columnsFilter}
      />
    </>
  );
};

export default SearchListMunicipios;
