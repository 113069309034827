import { useState } from "react";
import PedidoService from "../../services/service.pedido";
import NotaService from "../../services/service.nota";
import NewSearchBarControlFormik from "../searchs_comp/search-Bar.Control.Formik";
import T from "../../common/traducao";


const CampoSearchListNotasPedido = (props) => {

    const [notas, setNotas] = useState();
    const [pages, setPages] = useState();
    const [filter, setFilter] = useState();

    const { idPedido} = props;

    
    const constantes = T()

    const columns = [
        {
            field: 'Id',
            headerName: constantes.Codigo,
            width: 90,
            key: true
        },
        {
            field: 'Numero',
            headerName: constantes.Numero,
            width: 90,
            key: true
        },
        {
            field: 'Serie',
            headerName: constantes.Serie,
            tipo: 'text'
        },
        {
            field: 'DataSaidaEntrada',
            headerName: constantes.DataSaidaEntrada,
            width: 90,
            type: 'date',
            format: 'dd/MM/yyyyy'
        },
        {
            field: 'Situacao',
            headerName: constantes.Situacao,
            width: 90
        },
        {
            field: 'ValorNota',
            headerName: constantes.ValorNota,
            width: 90,
            align: "right",
            digits: 2
        }
    ]

    const columnsFilter = [
        {
            field: 'Id',
            headerName: constantes.Codigo,
            tipo: 'number'
        },
        {
            field: 'Numero',
            headerName: constantes.Numero,
            tipo: 'number'
        },
        {
            field: 'Serie',
            headerName: constantes.Serie,
            tipo: 'text'
        },
        {
            field: 'Situacao',
            headerName: constantes.Situacao,
            tipo: 'text'
        },
        {
            field: 'DataEmissao',
            headerName: constantes.DataEmissao,
            tipo: 'date',
            format: 'dd/MM/yyyyy'
        },
        {
            field: 'DataSaidaEntrada',
            headerName: constantes.DataSaidaEntrada,
            tipo: 'date',
            format: 'dd/MM/yyyyy'
        }
    ]

    function onLoad(loading) {
        props.onLoad(loading)
    }

    function onError(error) {
        props.onError(error)
    }

    function onSearchTitle(filter) {
        onLoad(true);
        PedidoService.notas(idPedido, filter)
            .then(response => {
                const notas = response.data.value;
                setNotas(notas);
                setPages(response.data.pages);
                setFilter(filter);
            })
            .catch(e => {
                onError(e);
            })
            .finally(() => {
                onLoad(false);
            });

    }

    function onGetItem(id) {
        id && onLoad(true);
        id && NotaService.get(id)
            .then(response => {
                if (response.response?.data.error) {
                    onError(response.response?.data.error);
                } else {
                    // const traducao = ProdutoService.getProdutoTraduzido(response.data);
                    // const produto = { ...response.data, Nome: traducao };
                    response.data && onItemSelected(response.data);
                }
            })
            .catch(e => {
                onError(e);
            })
            .finally(() => {
                onLoad(false);
            });
    }

    function onItemSelected(item) {
        props.onItemSelected(item);
    }

    return (
        <>
            <NewSearchBarControlFormik
                {...props}
                fullscreen
                pages={pages}
                onSearchTitle={onSearchTitle}
                onGetItem={onGetItem}
                onItemSelected={onItemSelected}
                data={notas}
                columns={columns}
                storagename={"Notas"}
                columnsFilter={columnsFilter}
            />
        </>
    )
}


export default CampoSearchListNotasPedido;
