import { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import ItemFinameOrcamentoVendaNew from "../../item_orcamento_venda/itemorcamentovenda-modalfinameorcamentovendaview";

class NewModalItemFinameOrcamentoVendaControl extends Component {


    constructor(props) {
        super(props);

        this.state = {
            modal: this.props.modal
        }

        this.onAddItem = this.onAddItem.bind(this);
        this.onEditItem = this.onEditItem.bind(this);


    }

    onAddItem = (item) => {
        this.props.onAddItem(item);
    }

    onEditItem = (item) => {
        this.props.onEditItem(item);
    }

    render() {
        const { modal, toggle } = this.props;

        return (
            <>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={modal}
                    onHide={toggle}
                    className={this.props.className}>
                    <ModalHeader toggle={toggle} closeButton></ModalHeader>
                    <ModalBody>
                        <ItemFinameOrcamentoVendaNew {...this.props} onAddItem={this.onAddItem} onEditItem={this.onEditItem}/>
                    </ModalBody>
                </Modal>

            </>
        )
    }
}

export default NewModalItemFinameOrcamentoVendaControl;