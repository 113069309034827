
import { useState, useEffect, useContext } from "react";
import {
  Box,
  Drawer,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import T from "../../common/traducao";
import Brasil from "../../assets/brasil.png";
import Espanha from "../../assets/espanha.png";
import { Icone } from "../ui_comp/Icons/Icon";
import { BotaoIcone } from "../ui_comp/Buttons/BotaoIcone";
import { Image} from "react-bootstrap";
import { GetIdiomaSelecionado, SetIdiomaSelecionado,getAcessoSimulado } from "../../config/localstorageConfig/localstorage";

import UserSelector from "../ui_comp/Dropdowns/Dropdown.Seletor.Usuario";
import LanguageSelector from "../language_selector_comp";
import { FaCross, FaSignOutAlt, FaUser, FaUserShield } from "react-icons/fa";
import {
  GetConfiguracoes,
  getUserLogin,
  isAuthenticated,
} from "../../config/localstorageConfig/localstorage";
import { H2jGlobalContext } from "../../contexts/H2jGlobal-context";

export const MobileNavigation = () => {
  const [open, setOpen] = useState(false);
  const [abrirMenuVendas, setAbrirMenuVendas] = useState(false);
  const [abrirMenuAdmin, setAbrirMenuAdmin] = useState(false);
  const [abrirMenuLanguage,setAbrirMenuLanguage] = useState(false)
  const { isAuth, userLogin, idiomaAtual } = useContext(H2jGlobalContext);

  const configuracoes = GetConfiguracoes();
  const vendedorSimulado = getAcessoSimulado(); 

  function abrirMenu() {
    setOpen(true)
   
  }

  function abrirAdmin() {
    setAbrirMenuAdmin(true);
  }

  function abrirVendas() {
    setAbrirMenuVendas(true)
  }
  function abrirLanguage() {
    setAbrirMenuLanguage(true)
  }
  function fecharMenu() {
    setOpen(false)
    setAbrirMenuVendas(false)
    setAbrirMenuAdmin(false);
    setAbrirMenuLanguage(false)
  }

  function navegarFecharMenu(route) {
    if (route) {
      fecharMenu();
    }
  }



  const constantes = T();


  return userLogin ? (
    <>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
      >
        <BotaoIcone icon="menu" color="#009180" onClick={() => abrirMenu()} />

      
      </Box>
      <Drawer variant="temporary" anchor="top" open={open} onClose={fecharMenu}>
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton sx={{display:"flex"}} href="/home">
            <ListItemIcon>
              <Icone icone="home" color="primary" sx={{ color: "#009180" }}  />
            </ListItemIcon>
            <ListItemText primary={constantes.Inicio} sx={{ color: "#009180" }}  />
          </ListItemButton>

          <ListItemButton sx={{display: "flex"}} href="/clientes">
            <ListItemIcon>
              <Icone icone="people_alt" color="primary" sx={{ color: "#009180" }}  />
            </ListItemIcon>
            <ListItemText primary={constantes.Clientes} sx={{ color: "#009180" }}  />
          </ListItemButton>
          
          <ListItemButton onClick={() => abrirVendas()}>
            
            <ListItemIcon>
              <Icone icone="shopping_cart_outlined" sx={{ color: "#009180" }} />
            </ListItemIcon>
            
            <ListItemText primary={constantes.Vendas} sx={{ color: "#009180" }}  />
            <Icone
              icone="arrow_drop_down_outlined "
              sx={{ color: "#009180" }}
              color="primary"
            />
            
          
          </ListItemButton>

          <Collapse in={abrirMenuVendas} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                href="/orcamentosvenda"
                sx={{ pl: 4 }}
                onClick={() => navegarFecharMenu()}
              >
                <ListItemIcon>
                  <Icone icone="receipt_long" sx={{ color: "#009180" }}  />
                </ListItemIcon>
                <ListItemText primary={constantes.OrcamentoDeVenda} sx={{ color: "#009180" }}  />
              </ListItemButton>
              <ListItemButton
                href="/assistencias"
                sx={{ pl: 4 }}
                onClick={() => navegarFecharMenu()}
              >
                <ListItemIcon>
                  <Icone icone="settings" sx={{ color: "#009180" }}  />
                </ListItemIcon>
                <ListItemText primary={constantes.Assistencias} sx={{ color: "#009180" }}  />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Drawer>
      <Box display={"flex"} padding={0}>
        <UserSelector>
          <ListItemButton padding={0} onClick={abrirAdmin}>
            <ListItemIcon sx={{justifyContent: "center"}}>
              <FaUser color="#009180" />
            </ListItemIcon>
            <ListItemText primary={userLogin} sx={{ color: "#009180" }}    />
            {abrirMenuVendas ? (
              <Icone icone="expand_less" sx={{ color: "#009180" }}   />
            ) : (
              <Icone icone="expand_more" sx={{ color: "#009180" }}  />
            )}
          </ListItemButton>

          <Drawer
            variant="temporary"
            anchor="top"
            open={abrirMenuAdmin}
            onClose={fecharMenu}
          >
            <Collapse in={abrirMenuAdmin} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton href="/usuario" sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <FaUser color="#009180" />
                  </ListItemIcon>
                  <ListItemText primary={userLogin} sx={{ color: "#009180" }}   />
                </ListItemButton>
                {configuracoes?.Usuario?.PerfilAcesso?.PermitirSimulacaoWeb && (
                  <ListItemButton href="/usuario/simularacesso" sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <FaUserShield color="#009180"/>
                    </ListItemIcon>
                    <ListItemText primary={constantes.SimularAcesso} sx={{ color: "#009180" }} />
                  </ListItemButton>
                )}
                <ListItemButton href="/logout" sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <FaSignOutAlt color="#009180"/>
                  </ListItemIcon>
                  <ListItemText primary={constantes.Sair} sx={{ color: "#009180" }}/>
                </ListItemButton>
              </List>
            </Collapse>
          </Drawer>
        </UserSelector>
        <LanguageSelector>
        <ListItemButton onClick={abrirLanguage}>
        <ListItemIcon sx={{ justifyContent: "center" }}>
          {idiomaAtual === 'pt-br' && (
            <Image style={{ width: 30, height: 30 }} src={Brasil} alt="Português" />
          )}
          {idiomaAtual === 'es-es' && (
            <Image style={{ width: 30, height: 30 }} src={Espanha} alt="Espanhol" />
          )}
          {!idiomaAtual && (
            <Image style={{ width: 30, height: 30 }} src={Brasil} alt="Português" />
          )}
        </ListItemIcon>

        {abrirMenuLanguage ? (
          <Icone icone="expand_less" sx={{ color: "#009180" }} />
        ) : (
          <Icone icone="expand_more" sx={{ color: "#009180" }} />
        )}
      </ListItemButton>

      <Drawer variant="temporary" anchor="top" open={abrirMenuLanguage} onClose={fecharMenu}>
        <Collapse in={abrirMenuLanguage} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => {
                SetIdiomaSelecionado("es-es");
                window.location.reload(false);
              }}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <Image style={{ width: 30, height: 30 }} src={Espanha} alt="es-es" />
              </ListItemIcon>
              <ListItemText primary="es-es" sx={{ color: "#009180" }} />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                SetIdiomaSelecionado("pt-br");
                window.location.reload(false);
              }}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <Image style={{ width: 30, height: 30 }} src={Brasil} alt="pt-br" />
              </ListItemIcon>
              <ListItemText primary="pt-br" sx={{ color: "#009180" }} />
            </ListItemButton>
          </List>
        </Collapse>
      </Drawer>

        </LanguageSelector>
      </Box>
    </>
  ) : null;
};

