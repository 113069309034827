import React, { useContext } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  useTheme,
  useMediaQuery,
  Box,
  Button,
  Typography,
} from "@mui/material";
import * as Styled from "./styles";
import Logo from "../../assets/logo.png";
import { Navigation } from "./Navegacao";
import { MobileNavigation } from "./Navegacao.Mobile";
import LanguageSelector from "../language_selector_comp";
import { Badge, Dropdown, Image, Stack } from "react-bootstrap";
import {
  GetServerVersion,
  SetIdiomaSelecionado,
  VERSAO,
} from "../../config/localstorageConfig/localstorage";
import T from "../../common/traducao";
import Brasil from "../../assets/brasil.png";
import Espanha from "../../assets/espanha.png";
import { H2jGlobalContext } from "../../contexts/H2jGlobal-context";

export const Header = () => {
  const theme = useTheme();
  const screenWidth = useMediaQuery(theme.breakpoints.down("sm"));
  const { isAuth, userLogin, idiomaAtual } = useContext(H2jGlobalContext);
  const constantes = T();

  const versao_server = GetServerVersion();

  return (
    <AppBar color="inherit" position="static">
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {screenWidth ? (
            <MobileNavigation />
          ) : (
            <>
              <Box>
                <Button component={Button} href="/home">
                  <Styled.Image src={Logo} alt="Logo" loading="lazy" />
                </Button>
                <h6>
                  <Badge style={{fontSize:8}} bg="secondary">{`Versão ${VERSAO}/${versao_server ?? ""}`}</Badge>
                </h6>
              </Box>

              <Navigation />
              {userLogin ? (
                ""
              ) : (
                <>
                  {isAuth ? (
                    <LanguageSelector>
                      <Dropdown.Toggle
                        variant="outline-light"
                        id="dropdown-basic"
                      >
                        {idiomaAtual === "pt-br" && (
                          <Image
                            style={{
                              width: 30,
                              height: 30,
                            }}
                            src={Brasil}
                            alt="Português"
                          ></Image>
                        )}
                        {idiomaAtual === "es-es" && (
                          <Image
                            style={{
                              width: 30,
                              height: 30,
                            }}
                            src={Espanha}
                            alt="Espanhol"
                          ></Image>
                        )}

                        {!idiomaAtual && (
                          <Image
                            style={{
                              width: 30,
                              height: 30,
                            }}
                            src={Brasil}
                            alt="Português"
                          ></Image>
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        onClick={(e) => {
                          SetIdiomaSelecionado(e.target.text);
                          window.location.reload(false);
                        }}
                      >
                        <Dropdown.Item value={"es-es"}>
                          <Stack direction="horizontal" gap={2}>
                            <Image
                              style={{
                                width: 30,
                                height: 30,
                              }}
                              src={Espanha}
                              alt="es-es"
                            ></Image>
                            <label>{constantes.esEs}</label>
                          </Stack>
                        </Dropdown.Item>
                        <Dropdown.Item value={"pt-br"}>
                          <Stack direction="horizontal" gap={2}>
                            <Image
                              style={{
                                width: 30,
                                height: 30,
                              }}
                              src={Brasil}
                              alt="pt-br"
                            ></Image>
                            <label>{constantes.ptBR}</label>
                          </Stack>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </LanguageSelector>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
