import { ErrorMessage, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { Badge, Form } from "react-bootstrap";
import AtributoService from "../../services/service.atributo";
import { blockInvalidChar } from "../../utils/blockkeys";

const AtributoComp = (props) => {
  const { hideTitle, atributoInicial, somenteLeitura, atualizarValoresNaoEncontrados } = props;

  const [atributos, setAtributos] = useState();

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    validateOnMount } = useFormikContext();


  function AtualizarValores() {
    values.OrcamentoVenda.Atributos.forEach((item, index) => {
      if (item.ValorAtributo?.Id > 0) {
        atualizarValoresNaoEncontrados(index, atributos, setFieldValue);
      }
    })
  }

  useEffect(() => {
    atributos && AtualizarValores();

  }, [atributos])

  async function getAtributosService() {
    if (somenteLeitura) {
      setAtributos(atributoInicial);
    } else {
      await AtributoService.getValoresFilial()
        .then((response) => {
          setAtributos(response.data.value);
        })
        .catch((error) => console.error("atributos", error));
    }
  }

  useEffect(() => {
    getAtributosService();
  }, []);

  function getAtributosView() {
    return (
      atributos &&
      atributos.map((item, i) => {
        let retorno = null;
        if (item.Atributo.Tipo === "Texto" && item.Valores.length > 0) {
          retorno = getValoresAtributoSelect(item, i);
        } else if (item.Atributo.Tipo === "Texto") {
          retorno = getValoresAtributoTexto(item, i);
        } else {
          retorno = getValoresAtributoTexto(item, i);
        }

        return (
          <Form.Group key={i} className="mb-3">
            {
              retorno && !hideTitle && (
                <Form.Label className="mb-0">{item.Atributo.NomeExibicaoTraduzido ?? item.Atributo.NomeExibicao}</Form.Label>
              )}
            {retorno}
          </Form.Group>
        );
      })
    );
  }

  function getValoresAtributoSelect(item, index) {
    const atributoitemindex =
      values.OrcamentoVenda?.Atributos &&
      values.OrcamentoVenda?.Atributos?.findIndex((itemorcamento) => {
        return (
          parseInt(itemorcamento.Atributo?.Id) === parseInt(item.Atributo?.Id)
        );
      });
    const _index = atributoitemindex !== -1 ? atributoitemindex : index;
    const itemname = `OrcamentoVenda.Atributos[${_index}].ValorAtributoId`;

    const sortedValores = [...item.Valores].sort((a, b) => {
      return a.ValorTraduzido.localeCompare(b.ValorTraduzido);
    });

    const itemvaluename = `OrcamentoVenda.Atributos[${_index}].Valor`;


    const itemerror = errors.OrcamentoVenda?.Atributos && errors.OrcamentoVenda?.Atributos[_index]?.Valor;


    return _index >= 0 ? (
      <>
        <Form.Select
          itemType="Number"
          {...props}
          key={_index}
          item={item}
          onKeyDown={blockInvalidChar}
          required={item?.Atributo?.Obrigatorio}
          value={values.OrcamentoVenda?.Atributos[_index]?.ValorAtributoId}
          onChange={(e) => {
            const itemselected = e.target.children[e.target.selectedIndex];
            const itemvalue = itemselected.getAttribute('valorOriginal');
            handleChange(e);
            setFieldValue(
              `OrcamentoVenda.Atributos[${_index}].Atributo.Id`,
              parseInt(item.Atributo.Id)
            );
            setFieldValue(
              `OrcamentoVenda.Atributos[${_index}].Valor`,
              itemvalue
            );
          }}
          name={itemname}
        >
          <option key={-1} value={"0"}>
            {""}
          </option>
          {item &&
            sortedValores.map((valor, i) => {
              return (
                <option
                  key={i}
                  item={valor}
                  valor={valor.Valor}
                  value={valor.Id}
                  valorOriginal={valor.Valor}
                >
                  {valor.ValorTraduzido}
                </option>
              );
            })}
        </Form.Select>

        {validateOnMount && itemerror && <Badge bg="danger">{itemerror}</Badge>}

        {!validateOnMount && <ErrorMessage name={itemvaluename} >
          {(msg) => <Badge bg="danger">{msg}</Badge>
          }
        </ErrorMessage >}
      </>
    ) : null;
  }

  function getValoresAtributoTexto(item, index) {
    const atributoitemindex =
      values.OrcamentoVenda?.Atributos &&
      values.OrcamentoVenda?.Atributos?.findIndex((itemorcamento) => {
        return (
          parseInt(itemorcamento.Atributo.Id) === parseInt(item.Atributo.Id)
        );
      });
    const _index = atributoitemindex !== -1 ? atributoitemindex : index;
    const itemname = `OrcamentoVenda.Atributos[${_index}].Valor`;
    return (
      <Form.Control
        {...props}
        name={itemname}
        key={_index}
        value={values.OrcamentoVenda.Atributos[_index]?.Valor}
        type={"text"}
        onChange={(e) => {
          handleChange(e);
          setFieldValue(
            `OrcamentoVenda.Atributos[${_index}].Atributo.Id`,
            parseInt(item.Atributo.Id)
          );
        }}
      ></Form.Control>
    );
  }

  return getAtributosView();
};

export default AtributoComp;
